import { useQuery } from "@tanstack/react-query";
import { http } from "../../lib/http";

type Request = {
  complaint_type?: string;
  search?: string;
  start_date: string;
  end_date: string;
  progress_type: string;
  department_id?: string;
  page?: string;
  limit?: number;
};

type Response = {
  message: string;
  data: {
    _id: string;
    user_id: string;
    complaint_type: string;
    createdAt: Date;
    dong: string;
    floor: string;
    is_new: boolean;
    is_new_comment: boolean;
    progress_type: string;
    title: string;
  }[];
  total_num: number;
  total_page_num: number;
};

export const useGetComplaintList = (
  { complaint_type, search, start_date, end_date, progress_type, department_id, page = "1", limit = 10 }: Request,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["building/complaint/list", progress_type, complaint_type, department_id, page, limit],
    queryFn: () =>
      http.get<Response, Request>("/building/complaint/list", {
        start_date,
        end_date,
        progress_type,
        complaint_type,
        department_id,
        search,
        page,
        limit,
      }),
    enabled,
  });
};
