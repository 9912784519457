import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  payment_date: number;
};

export const useUpdatePaymentDate = () => {
  return useMutation({
    mutationFn: ({ paymentDate }: { paymentDate: number }) => {
      return http.post<Request>("/building/payment_date/update", {
        payment_date: paymentDate,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building", "context_building"]);
    },
  });
};
