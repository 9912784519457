import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  rentFeeId: string;
};

export const useUpdateRentFeeNotify = () => {
  return useMutation({
    mutationFn: ({ rentFeeId }: Request) => {
      return http.post("/building/rent_fee/notify", { rent_fee_id: rentFeeId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/rent_fee/list"]);
    },
  });
};
