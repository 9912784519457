import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type CreateCompanyRequest = {
  buildingId: string;
  name: string;
  members: {
    name: string;
    phoneNumber: string;
  };
  tenantType: "office" | "store" | "residential" | "etc";
  rooms: string[];
  moveInType?: "own_house" | "monthly_rent" | "etc";
  rentStartDate?: Date;
  rentEndDate?: Date;
  companyRegistrationNumber?: string;
  taxEmail?: string;
  fax?: string;
};

type Response = {
  id: string;
  buildingId: string;
  tenantType: "office" | "store" | "residential" | "etc";
  roomInfo: string[];
  name: string;
  phoneNumber: string;
  members: {
    _id: string;
    name: string;
    phone_number: string;
    permission: 1 | 2 | 3;
  }[];
  ceoName: string;
  email: string;
  tenantRegistrationNumber: string;
  salesOrLeaseContractFiles: {
    id: string;
    name: string;
    directory: string;
  }[];
  businessRegistrationFiles: {
    id: string;
    name: string;
    directory: string;
  }[];
};

export const useCreateCompanyMutation = () => {
  return useMutation({
    mutationFn: (request: CreateCompanyRequest) => {
      return httpV2.post<CreateCompanyRequest, Response>("/companies", request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["/companies"]);
    },
  });
};
