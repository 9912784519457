import { twMerge } from "tailwind-merge";

//처리 상태 컴포넌트 타입 -> 민원과 합쳐서 상태값과 이벤트만 남도록,
export type ITagStatus = {
  id: string;
  title: string;
  // number: number;
};

interface ITag {
  el: ITagStatus;
  onClick: (str: string) => void;
  status: string;
}

interface ITagComponent {
  tagArr: ITagStatus[];
  onClick: (str: string) => void;
  status: string;
}

function ProgressTagItem(props: ITag) {
  return (
    <div
      className={twMerge(
        "mr-2 cursor-pointer rounded-2xl border border-gray-300 px-2.5 py-1 text-sm text-gray-500 sm:px-3",
        props.status === props.el.id && "border-blue-600 bg-blue-600 text-white",
      )}
      id={props.el.id}
      onClick={(e) => props.onClick(e.currentTarget.id)}
    >
      {props.el.title}
    </div>
  );
}

export default function ChangeStatusByTags(prop: ITagComponent) {
  const { tagArr, ...props } = prop;
  // console.log("바뀌는 지 확인 ---->", props.checkedItems);

  return (
    <div className="flex w-full items-center justify-start pb-6 pt-1">
      {tagArr.map((el) => (
        <ProgressTagItem el={el} key={el.title} {...props} />
      ))}
    </div>
  );
}
