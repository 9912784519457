import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  tenant_id: string;
  amount: string | number;
};

export const useUpdatePointDirectly = () => {
  return useMutation({
    mutationFn: ({ tenant_id, amount }: Request) => {
      return http.post("/building/point/recharge", {
        tenant_id,
        amount,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["point/by_tenant"]);
      queryClient.invalidateQueries(["point/list_by_tenant"]);
    },
  });
};
