import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Select,
  RadioGroup,
  Radio,
  useToast,
  UseModalProps,
} from "@chakra-ui/react";
import uniqBy from "lodash/uniqBy";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import SmallInputs from "../../../../components/Input/shortText";
import { IRoomPublic } from "../../../../lib/types/Imodels";
import { getTime } from "../../../../lib/utils/format";
import * as C from "../../../../components/styled/index";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";
import { RoomInput } from "../../setting/_view/styled";
import { allTime, format_room } from "../../../../lib/utils/dataFormat";
import SelectButton from "../../../../components/selectBox/checkedOption/buttonItems";
import PublicCheckedBox from "../checkBox/checkTenant";
import { success, unAuthUser, warning, WarningTime } from "../../../../lib/theme/toast";
import { fetchClient } from "../../../../lib/api/axios";
import { public_create, public_list } from "../../../../lib/api/queries/urls";
import { public_all_list_key, public_list_key } from "../../../../lib/api/queries/keys";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import SingleRow, { WithBox, WrapFlex } from "../../../../components/modal/table/singleRow";
import { AxiosError } from "axios";
import { useRoomType } from "../../../../lib/utils";
import { GlobalContext } from "../../../../App";
import { queryClient } from "../../../..";
import { IMeetingRoom } from "../../commons/types";
import { meeting_room_schema } from "../detailModal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { onChangeNumbers } from "../../commons/func";

const UpdateBox = styled(Flex)`
  padding: 20px 56px 20px 20px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.gray1};
`;

const err_toast = {
  ...warning,
  title: "회의실 등록",
  description: "회의실 등록을 위해 필수 항목을 모두 기입했는지 다시 한 번 확인해주세요.",
};

export default function RegisterModal(props: UseModalProps) {
  const toast = useToast();
  const room_type = useRoomType();
  const { buildInfo } = useContext(GlobalContext);

  const defaultValue = {
    dong: "",
    floor: "",
    name: "",
    people: "",
    method: "",
    price: "",
  };

  const { handleSubmit, control, setValue, reset, resetField, getValues, watch } = useForm<IMeetingRoom>({
    defaultValues: defaultValue, //
    mode: "onChange",
    resolver: yupResolver(meeting_room_schema),
  });

  const { data } = useQuery(
    [public_all_list_key],
    () =>
      fetchClient
        .get<BaseResponse<IRoomPublic[]>>(public_list, {
          params: { is_only_room_public: true },
        })
        .then((res) => res.data.data),
    { enabled: props.isOpen },
  );

  const [retrieve, setRetrieve] = useState("");
  const [tagArr, setTagArr] = useState<string[]>([]);
  const [day, setDay] = useState("");
  const [start, setStarts] = useState("");
  const [end, setEnds] = useState("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [options, setOptions] = useState("");
  const [optionArr, setOptionArr] = useState<string[]>([]);

  const onClickResetState = () => {
    reset({ ...defaultValue, ...{ payment_description: "", guide: "" } });
    setTagArr([]);
    setDay("");
    setStarts("");
    setEnds("");
    setCheckedItems([]);
    setOptions("");
    setOptionArr([]);
  };

  useEffect(() => {
    if (!data) return;
    if (!retrieve) return onClickResetState();

    const retrieved_room = data.filter((el) => el.name === retrieve)[0];
    const result: IMeetingRoom = {
      ...defaultValue,
      ...{
        dong: retrieved_room.dong,
        floor: retrieved_room.floor,
        people: String(retrieved_room.people_num || ""),
        isUserApproved: retrieved_room.is_approval_required,
      },
    };
    reset(result);
    setValue("payment_description", retrieved_room.payment_description || "");
    setValue("guide", retrieved_room.description || "");

    if (retrieved_room.payment_method_and_price) {
      retrieved_room.payment_method_and_price.forEach((el) => {
        setValue("method", el.payment_method);
        setValue("price", el.price ? String(el.price) : "");
      });
    }

    const newArr = [];
    setCheckedItems(retrieved_room.available_rooms);
    setOptionArr(retrieved_room.options);

    if (retrieved_room.start_time_weekday) {
      const midnight =
        getTime(retrieved_room.end_time_weekday) === "00:00" ? "24:00" : getTime(retrieved_room.end_time_weekday);
      newArr.push(`평일 ${getTime(retrieved_room.start_time_weekday)} - ${midnight}`);
    }

    if (retrieved_room.start_time_holiday) {
      const midnight =
        getTime(retrieved_room.end_time_weekday) === "00:00" ? "24:00" : getTime(retrieved_room.end_time_weekday);
      newArr.push(`주말 ${getTime(retrieved_room.start_time_weekday)} - ${midnight}`);
    }
    setTagArr(newArr);
  }, [retrieve]);

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      room_type?.forEach((el) => allCheckedArr.push(el.label));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      if (newArr !== undefined) setCheckedItems(newArr);
    }
    if (checkedItems.length === room_type.length || 0) {
      return (checked = true);
    }
  };

  const onClickDel = (id: number) => {
    const newArr = tagArr.filter((_, index) => index !== id);
    setTagArr(newArr);
  };

  const onDelCondition = (value: string) => {
    const newArr = checkedItems.filter((el) => el !== value);
    setCheckedItems(newArr);
  };

  const onSetStartTime = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = moment(`2001-01-01T${e.target.value}:00`);
    const prev = moment(`2001-01-01T${!end ? "24:00" : end}:00`);

    if (moment.duration(prev.diff(value)).asHours() <= 0)
      return toast({
        ...WarningTime,
        description: "시작시간은 종료시간보다 이후거나 같을 수 없습니다.",
      });

    setStarts(e.target.value);
  };

  const onSetEndTime = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = moment(`2001-01-01T${e.target.value}:00`);
    const prev = moment(`2001-01-01T${!start ? "00:00" : start}:00`);
    // const holi = moment(`2001-01-01T${selectAll.start_time_holiday}:00`);

    if (moment.duration(prev.diff(value)).asHours() >= 0)
      return toast({
        ...WarningTime,
        description: "종료시간은 시작시간보다 이전이거나 같을 수 없습니다.",
      });

    setEnds(e.target.value);
  };

  const onClickAdd = () => {
    if (day === "") {
      return toast({
        ...warning,
        title: "알림",
        description: "평일 혹은 주말을 먼저 선택해주세요.",
        duration: 3000,
      });
    }

    if (!start || !end)
      return toast({
        ...warning,
        title: "알림",
        description: "시간을 정확히 선택해주세요.",
        duration: 3000,
      });

    if (day === "평일") {
      const newArr = tagArr.filter((el) => !el.includes("평일"));
      setTagArr([...newArr, `평일 ${start} - ${end}`]);
    } else {
      const newArr = tagArr.filter((el) => !el.includes("주말"));
      setTagArr([...newArr, `주말 ${start} - ${end}`]);
    }
  };

  const onChangeOption = () => {
    const newArr = [...optionArr];
    if (options === "")
      return toast({
        ...warning,
        title: "알림",
        description: "시설 옵션을 입력 후 추가해주세요.",
      });
    if (newArr.includes(options))
      return toast({
        ...warning,
        title: "알림",
        description: "이미 포함된 시설 옵션입니다.",
      });
    newArr.push(options);
    setOptions("");
    setOptionArr(newArr);
  };

  const OptionDel = (idx: number) => {
    const newArr = optionArr.filter((_, index) => index !== idx);
    setOptionArr(newArr);
  };

  const mutation = useMutation((data: IMeetingRoom) => {
    const holiday = tagArr.filter((el) => el.includes("주말"));

    return fetchClient.post<BaseResponse<IRoomPublic>>(public_create, {
      room_public_type: "회의실",
      dong: data.dong,
      floor: data.floor,
      name: data.name,
      people_num: Number(data.people),
      options: optionArr.length === 0 ? [] : optionArr,
      available_rooms: room_type.length === 1 ? ["office"] : checkedItems,
      start_time_weekday: `2000-01-01T${tagArr.filter((el) => el.includes("평일"))[0].split(" ")[1]}Z`,
      end_time_weekday: `2000-01-01T${tagArr.filter((el) => el.includes("평일"))[0].split(" ")[3]}Z`,
      start_time_holiday: holiday.length !== 0 ? `2000-01-01T${holiday[0].split(" ")[1]}Z` : "",
      end_time_holiday: holiday.length !== 0 ? `2000-01-01T${holiday[0].split(" ")[3]}Z` : "",
      is_approval_required: data.isUserApproved,
      payment_method_and_price: [
        {
          payment_method: buildInfo?.services.MEETING_ROOM?.options?.USE_POINT ? data.method : "free",
          price: data.method === "free" ? undefined : Number(data.price),
        },
      ], //여기 지금은 직접 배열 만들지만 결제 방식 추가되면 UI + 구조 바꿔야함
      description: data.guide || "",
      payment_description: data.payment_description || "",
    });
  });

  const onClickCreate = async (data: IMeetingRoom) => {
    if (mutation.isLoading) return;

    //공유오피스,사옥은 입주자 조건을 안보여줌
    if (room_type.length > 1 && checkedItems.length === 0) return toast(err_toast);

    if (tagArr.filter((el) => el.includes("평일")).length === 0) {
      return toast({
        ...warning,
        title: "알림",
        description: "시설 등록을 위해 평일 이용 가능 시간을 필수로 지정해주세요.",
        duration: 3000,
      });
    }

    try {
      await mutation.mutateAsync(data);

      queryClient.invalidateQueries([public_list_key]);
      toast({
        ...success,
        title: "회의실 등록",
        description: "해당 회의실 정보가 등록되었습니다.",
      });
      props.onClose();
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 400)
          return toast({
            ...warning,
            title: "회의실 등록",
            description: "같은 이름의 회의실 이미 존재합니다. 확인 후 다시 등록해주세요.",
          });
        if (err.response?.status === 401) return toast({ ...unAuthUser });
      }
    }
  };

  const sortFunc = (a: IRoomPublic, b: IRoomPublic) => {
    const prev = a.name;
    const next = b.name;
    const num = /^[0-9]/;

    if (prev.match(num) && next.match(num)) {
      //비교군 모두 숫자면 큰 수를 뒤에
      return prev > next ? 1 : prev < next ? -1 : 0;
    }
    return prev > next ? 1 : prev < next ? -1 : 0; //기본 정렬
  };

  return (
    <Modal variant="contents" onClose={props.onClose} isOpen={props.isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <form
        onKeyDown={(e) => {
          if (e.code === "Enter") return e.preventDefault();
        }}
        // onSubmit={() => {
        //   console.log(getValues(), formState);
        //   // handleSubmit(onClickCreate);
        // }}
        onSubmit={handleSubmit(onClickCreate, () => toast(err_toast))}
      >
        <ModalContent w="860px" maxW="860px">
          <ModalHeader>신규 회의실 등록</ModalHeader>
          <ModalBody>
            {data?.length !== 0 && (
              <UpdateBox>
                <Text fontWeight={600}>정보 불러오기</Text>
                <Flex>
                  <Select
                    onChange={(e) => setRetrieve(e.target.value)}
                    w="430px"
                    marginRight="8px"
                    variant="fitContent"
                    value={retrieve}
                  >
                    <option value="">회의실 선택</option>
                    {uniqBy(data, "name")
                      ?.sort(sortFunc)
                      .map((el) => (
                        <option key={el.name} value={el.name}>
                          {el.name}
                        </option>
                      ))}
                  </Select>
                  <Button variant="cancel" onClick={() => setRetrieve("")}>
                    초기화
                  </Button>
                </Flex>
              </UpdateBox>
            )}
            <C.IntervalBox>
              <Text variant="title">1. 기본 정보</Text>
              <SingleRow title="위치 *">
                <Flex w="70%">
                  <Controller
                    control={control}
                    name="dong"
                    render={({ field }) => (
                      <Select w="100px" mr="2" variant="fitContent" {...field}>
                        <option value="" disabled>
                          동 선택
                        </option>
                        {buildInfo?.dong_floor?.map((el) => (
                          <option key={el._id} value={el.dong}>
                            {el.dong}동
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                  <Controller
                    control={control}
                    name="floor"
                    render={({ field }) => (
                      <Select w="100px" mr="2" variant="fitContent" {...field}>
                        <option value="" disabled>
                          층 선택
                        </option>
                        {buildInfo?.dong_floor
                          .filter((el) => el.dong === watch("dong"))?.[0]
                          ?.floor.map((el) => (
                            <option key={el} value={el}>
                              {el}층
                            </option>
                          ))}
                      </Select>
                    )}
                  />
                </Flex>
              </SingleRow>
              <SingleRow title="회의실명 *">
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <RoomInput
                      maxLength={30}
                      style={{ width: "70%" }}
                      placeholder="최대 30자까지 입력 가능"
                      // ref={(el) => (InputRefs.current[0] = el)}
                      {...field}
                    />
                  )}
                />
              </SingleRow>
              <SingleRow title="최대 수용 인원 *">
                <Controller
                  control={control}
                  name="people"
                  render={({ field: { onChange, ...field } }) => (
                    <RoomInput
                      maxLength={30}
                      type="number"
                      onChange={(e) => {
                        const value = onChangeNumbers(e);
                        if (value === "false") return;
                        onChange(value);
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                      placeholder="숫자만 입력가능"
                      // ref={(el) => (InputRefs.current[1] = el)}
                      {...field}
                    />
                  )}
                />
                <C.WordBox>명</C.WordBox>
              </SingleRow>
              <SingleRow title="시설 옵션">
                <WrapFlex style={{ padding: 0, alignItems: "flex-start" }}>
                  <Flex>
                    <SmallInputs
                      value={options}
                      onKeyDown={(e) => {
                        if (e.code === "Enter") return onChangeOption();
                      }}
                      onChange={(e) => setOptions(e.target.value)}
                      placeholder="모니터, 칠판 등 시설 옵션 입력"
                    />
                    <Button variant="cancel" onClick={onChangeOption}>
                      추가
                    </Button>
                  </Flex>
                  <div style={{ width: "100%" }}>
                    {optionArr.map((el, index) => (
                      <C.TagArr key={el}>
                        {el}
                        <C.TagCloseIcon onClick={() => OptionDel(index)} />
                      </C.TagArr>
                    ))}
                  </div>
                </WrapFlex>
              </SingleRow>
            </C.IntervalBox>
            <C.IntervalBox>
              <Text variant="title">2. 이용 가능 조건 *</Text>
              {room_type.length > 1 && (
                <SingleRow title="입주사 조건">
                  <WithBox style={{ padding: 0, alignItems: "flex-start" }}>
                    <PublicCheckedBox checkedItems={checkedItems} onCheckedAll={onCheckedAll} default="입주사 전체">
                      {room_type?.map((el) => (
                        <SelectButton
                          key={el.label}
                          checkedItems={checkedItems}
                          onChangeChecked={onChangeChecked}
                          value={el.value}
                          label={el.label}
                        />
                      ))}
                    </PublicCheckedBox>
                    <div>
                      {JSON.stringify(room_type.map((el) => el.label)) === JSON.stringify(checkedItems) ? (
                        <C.TagArr>
                          입주사 전체
                          <C.TagCloseIcon onClick={() => onCheckedAll(false)} />
                        </C.TagArr>
                      ) : (
                        <>
                          {checkedItems?.map((el) => (
                            <C.TagArr key={el}>
                              {format_room(el)}
                              <C.TagCloseIcon onClick={() => onDelCondition(el)} />
                            </C.TagArr>
                          ))}
                        </>
                      )}
                    </div>
                  </WithBox>
                </SingleRow>
              )}
              {/* 추후 추가 예정 <PublicTable title="위치 조건">
                  <Select w={"100px"} marginRight="2" variant="fitContent">
                    동 선택
                  </Select>
                  <Select w={"100px"} marginRight="2" variant="fitContent">
                    층 선택
                  </Select>
                  <DefaultButton>추가</DefaultButton>
                </PublicTable> */}
              <SingleRow title="이용 가능 시간">
                <WithBox style={{ padding: 0, alignItems: "flex-start" }}>
                  <Flex alignItems="center">
                    <Select
                      w="100px"
                      value={day}
                      marginRight="2"
                      variant="fitContent"
                      onChange={(e) => setDay(e.target.value)}
                      isDisabled={tagArr.length === 2 ? true : false}
                    >
                      <option value="">선택</option>
                      <option value="평일">평일</option>
                      <option value="주말">주말</option>
                    </Select>
                    <Select
                      w="100px"
                      isDisabled={tagArr.length === 2 ? true : false}
                      mr="2"
                      variant="fitContent"
                      onChange={onSetStartTime}
                      value={start}
                    >
                      <option value="">오픈시간</option>
                      {allTime.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Select>
                    -
                    <Select
                      w="100px"
                      value={end}
                      isDisabled={tagArr.length === 2 ? true : false}
                      m="0px 8px"
                      variant="fitContent"
                      onChange={onSetEndTime}
                    >
                      <option value="">종료시간</option>
                      {allTime.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Select>
                    <Button variant="cancel" onClick={onClickAdd}>
                      추가
                    </Button>
                  </Flex>
                  <div>
                    {tagArr.map((el, index) => (
                      <C.TagArr key={el}>
                        {el}
                        <C.TagCloseIcon name={String(index)} onClick={() => onClickDel(index)} />
                      </C.TagArr>
                    ))}
                  </div>
                </WithBox>
              </SingleRow>
            </C.IntervalBox>
            <C.IntervalBox>
              <Text variant="title">3. 예약</Text>
              <SingleRow title="예약확정 방식 *">
                <Controller
                  control={control}
                  name="isUserApproved"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      color="#6B7280"
                      onChange={(e) => onChange(JSON.parse(e))}
                      value={String(value)}
                      // onChange={(e) => setApproval(e)}
                      // value={approval}
                    >
                      <Radio mr={9} value="false" variant="default">
                        예약신청과 동시에 확정
                      </Radio>
                      <Radio value="true" variant="default">
                        관리자 확인 후 확정
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </SingleRow>
              <SingleRow title="결제수단 및 금액 *">
                <span style={{ paddingRight: "40px" }}>30분 /</span>
                <Controller
                  control={control}
                  name="method"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      w="160px"
                      mr="1"
                      variant="fitContent"
                      onChange={(e) => (resetField("price"), onChange(e))}
                      value={value}
                    >
                      <option disabled={!buildInfo?.services.MEETING_ROOM?.options?.USE_POINT} value="">
                        결제수단
                      </option>
                      <option disabled={!buildInfo?.services.MEETING_ROOM?.options?.USE_POINT} value="point">
                        포인트
                      </option>
                      <option value="free">무료</option>
                    </Select>
                  )}
                />
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { onChange, value } }) => (
                    <SmallInputs
                      isDisabled={
                        getValues().method === "free" ? true : !buildInfo?.services.MEETING_ROOM?.options?.USE_POINT
                      }
                      _disabled={{ backgroundColor: "#f4f4f5" }}
                      style={{ minWidth: "208px" }}
                      placeholder="결제 금액 입력"
                      onChange={(e) => {
                        const value = onChangeNumbers(e);
                        if (value === "false") return;
                        onChange(value);
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                      type="number"
                      value={value}
                    />
                  )}
                />
              </SingleRow>
              <SingleRow title="예약정책">
                <WrapFlex>
                  <Controller
                    control={control}
                    name="guide"
                    render={({ field }) => (
                      <C.BasicTextArea
                        h="208px"
                        // value={policy}
                        placeholder="회의실 예약 시 숙지해야 할 정책을 작성해주세요."
                        // onChange={(e) => setPolicy(e.target.value)}
                        {...field}
                      />
                    )}
                  />
                </WrapFlex>
              </SingleRow>
              <SingleRow title="결제안내">
                <WrapFlex>
                  <Controller
                    control={control}
                    name="payment_description"
                    render={({ field }) => (
                      <C.BasicTextArea
                        h="208px"
                        // value={policy}
                        placeholder="결제 시 안내 사항을 작성해주세요."
                        // onChange={(e) => setPolicy(e.target.value)}
                        {...field}
                      />
                    )}
                  />
                </WrapFlex>
              </SingleRow>
            </C.IntervalBox>
          </ModalBody>
          <ModalFooter pr="40px">
            <Button
              type="button"
              variant="cancel"
              // onClick={() => {
              //   setRetrieve("");
              //   return props.onClose();
              // }}
              onClick={props.onClose}
            >
              취소
            </Button>
            <Button type="submit" variant="bgBlue">
              저장하기
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
