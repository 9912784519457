import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  date: string;
  rentFeeDetails: {
    tenant_id: string;
    tenant_name: string;
    point?: number;
    price?: number | null;
    dong_ho: {
      _id: string;
      dong: string;
      ho: string;
    }[];
  }[];
};

export const useCreateRentFee = () => {
  return useMutation({
    mutationFn: ({ date, rentFeeDetails }: Request) => {
      return http.post("/building/rent_fee/create", {
        date,
        rent_fee_details: rentFeeDetails,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/rent_fee/list"]);
    },
  });
};
