import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  room_type: string;
  dong: string;
  ho: string;
  floor: string;
  area: number;
  owners: {
    name: string;
    phone_number: string;
  }[];
};

type Response = {
  data: {
    building_id: string;
    owners: {
      name: string;
      phone_number: string;
      _id: string;
    }[];
    owner_name: string;
    owner_phone_number: string;
    room_type: string;
    dong: string;
    ho: string;
    floor: string;
    area: number;
    _id: string;
    createdAt: Date;
    updatedAt: Date;
  };
};

export const useCreateRoom = () => {
  return useMutation({
    mutationFn: ({ room_type, dong, ho, floor, area, owners }: Request) => {
      return http.post<Request, Response>("/building/room/create", {
        room_type,
        dong,
        ho,
        floor,
        area,
        owners,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/room"]);
    },
  });
};
