import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { Select, useToast } from "@chakra-ui/react";
import { warning } from "../../../../lib/theme/toast";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { twMerge } from "tailwind-merge";
import { useUpdateRentFeeStatus } from "../../../../requests/rent-fee/useUpdateRentFeeStatus";
import styled from "@emotion/styled";

const RentalSelect = styled(Select)`
  border: 1px solid #d1d5db;
  border-radius: 0;
  background-color: #ffffff;
  padding: 5px;
  height: auto;
  width: 100%;
  &:focus {
    border: 1px solid #d1d5db;
  }
`;

type RentalItemType = {
  _id?: string;
  tenant_id: string;
  tenant_name: string;
  payment_status?: string;
  price?: number;
  rent_price?: number;
  dong_ho: {
    _id: string;
    dong: string;
    ho: string;
  }[];
};

type PropsType = {
  el: RentalItemType;
  idx: number;
  length?: number;
};

export default function RentalDetailItem({ el, ...props }: PropsType) {
  const toast = useToast();
  const { rentalId } = useParams();
  const [status, setStatus] = useState(el.payment_status);
  const auth = useUserAuth("rent_fee");

  const { mutateAsync: updateRentFeeStatus } = useUpdateRentFeeStatus();

  const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setStatus(value);

    updateRentFeeStatus({
      rentalId: rentalId || "",
      rent_fee_details: [
        {
          _id: el._id || "",
          payment_status: value,
        },
      ],
    })
      .then(() => {
        alert(`관리비 납부 상태를 ${value === "checking" ? "확인 중으" : "납부완료"}로 변경하였습니다.`);
      })
      .catch(() => {
        toast({
          ...warning,
          title: "알림",
          description: "상태변경에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
        });
        setStatus(value === "checking" ? "completed" : "checking");
      });
  };

  return (
    <div className="table border-b border-gray-300">
      <div
        className={twMerge(
          "table-cell w-[4%] break-normal border-r border-r-gray-500 bg-white px-1 py-3 text-center align-middle",
          (props.length || 0) > 999 && "w-[5%]",
        )}
      >
        {props.idx + 1}
      </div>
      <div className="table-cell w-[17%] break-normal border-r border-gray-500 bg-white p-3 align-middle">
        {el.tenant_name}
      </div>
      <div className="table-cell w-1/4 break-normal border-r border-gray-500 bg-white p-3 align-middle">
        {el.dong_ho.map((ele) => (
          <span key={uuidv4()} className="mr-1">
            {ele.dong}동 {ele.ho}호
          </span>
        ))}
      </div>
      <div className="table-cell w-1/5 break-normal border-r border-gray-500 bg-[#eaf3ff] p-3 text-right ">
        {el.price ? Number(el.price).toLocaleString("kr") : ""}
      </div>
      <div className="table-cell w-[15%] break-normal border-r border-gray-500 bg-[#eaf3ff] p-1.5 align-middle">
        {auth === "edit" ? (
          <RentalSelect
            color={status !== "completed" ? "#6b7280" : "#2563eb"}
            onChange={onChangeStatus}
            value={status || ""}
            className="h-auto w-full rounded-none border border-gray-300 bg-white p-[5px] focus:border focus:border-gray-300"
          >
            <option value="" disabled>
              상태 선택
            </option>
            <option value="checking">확인 중</option>
            <option value="completed">납부완료</option>
          </RentalSelect>
        ) : (
          <div className="text-center">{status === "checking" ? "확인 중" : "납부 완료"}</div>
        )}
      </div>
      <div className="w-1/5" />
    </div>
  );
}
