import { useState } from "react";
import Pagination from "../../components/custom/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomBadge from "../../components/CustomBadge";
import Input from "../../components/Input/Input";
import IconSearch from "../../components/icons/IconSearch";

type UserType = "owner" | "master" | "normal";

export default function MemberListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchParamsObject = {
    page: Number(searchParams.get("page")) || 1,
  };
  // const { data, isLoading, isError } = useMembersQuery({});

  // if (isLoading) return <div>Loading...</div>;
  // if (isError) return <div>Error</div>;

  return (
    <div>
      <div className="flex h-[60px] items-center justify-between border-y border-gray-200 bg-gray-50 px-6">
        <div className="flex gap-3"></div>

        <div className="w-[27.75rem]">
          <Input
            themeSize="sm"
            placeholder="이름, 연락처, 입주사명, 호실"
            iconElement={<IconSearch className="h-5 w-5" />}
          />
        </div>
      </div>

      <div className="w-full px-6">
        <table className="w-full table-fixed">
          <thead>
            <tr>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">이름</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">연락처</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">아이디</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">보유권한</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">인증완료일</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">가입상태</th>
            </tr>
          </thead>
          <tbody>
            {/* {data?.data.map((item) => {
              return (
                <tr
                  key={item._id}
                  onClick={() => navigate(`/members/${item._id}/companies`)}
                  className={
                    "cursor-pointer border-y border-gray-200 transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
                  }
                >
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{item.name}</td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{item.phoneNumber}</td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{item.email}</td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    <div className={`flex flex-wrap gap-1 ${!item.status && "opacity-40"}`}>
                      {item.userType.split(",").map((userType, index) => {
                        return <CustomBadge key={`${item._id} ${index}`} type={userType as UserType} />;
                      })}
                    </div>
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.createdAt ? item.createdAt : "-"}
                  </td>
                  <td className={`text-gray-90 px-2 py-3 text-sm leading-normal ${!item.status && "text-red-500"}`}>
                    {item.status ? "가입완료" : "미가입"}
                  </td>
                </tr>
              );
            })} */}
          </tbody>
        </table>
        <div className="mt-3 flex items-center justify-end gap-3">
          {/* <p className="text-base leading-normal text-gray-500">총 {data?.total_num}개의 결과</p>
          <Pagination currentPage={searchParamsObject.page} itemCount={data?.total_num || 0} showPageAmount={10} /> */}
        </div>
      </div>
    </div>
  );
}
