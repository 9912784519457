import moment from "moment";
import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  tenant_id: string;
  date: Date | string;
  search: string;
  room_public_type: string;
  payment_type: string;
};

type Response = {
  data: {
    _id: string;
    amount: number;
    description: string;
    user_name: string;
    phone_number: string;
    tenant_name: string;
    payment_type: "pay" | "refund" | "recharge" | "deduct";
    date: Date;
    room_public_type: string;
  }[];
};

export const useGetPointHistoryList = (
  { tenant_id, date, search, room_public_type, payment_type }: Request,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["point/list_by_tenant", date, room_public_type, payment_type],
    queryFn: () =>
      http.get<Response, Request>("/building/point/list_by_tenant", {
        tenant_id,
        date: moment(date).format("YYYY-MM-01"),
        search,
        room_public_type,
        payment_type,
      }),
    enabled,
    retry: false,
  });
};
