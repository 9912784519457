import { DrawerBody, UseModalProps } from "@chakra-ui/react";
import CommonDrawerModal from "../../Drawer";
import { format_date } from "../../../lib/utils/dataFormat";
import { format_notify_icon, onActionInNotification } from "./func";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "../../../lib/utils/toast";
import { useUpdateNotification } from "../../../requests/notification/useUpdateNotification";

type NotificationType = {
  _id: string;
  building_id: string;
  user_id: string;
  notification_type: string;
  title: string;
  body: string;
  data: string;
  is_checked: boolean;
  createdAt: Date;
  tenant_id: string;
  tenant_name: string;
};

type INotificationBox = {
  data: NotificationType[];
  setDots: Dispatch<SetStateAction<boolean>>;
} & UseModalProps;

export default function NotificationBox(props: INotificationBox) {
  const navigate = useNavigate();

  const [newArr, setNewArr] = useState<NotificationType[]>([]);
  const [oldArr, setOldArr] = useState<NotificationType[]>([]);

  const { mutateAsync: updateNotification } = useUpdateNotification();

  useEffect(() => {
    const newOne: NotificationType[] = [];
    const oldOne: NotificationType[] = [];

    props.data.forEach((el: NotificationType) => {
      el.is_checked ? oldOne.push(el) : newOne.push(el);
    });

    setOldArr(oldOne);
    setNewArr(newOne);
    props.setDots(newOne.length === 0 ? false : true);
  }, [props.data]);

  const onNotifyPage = async (fetch: string, id: string, data: string) => {
    // console.log("passed", sessionStorage.getItem("meeting_info"));
    //1. 빌딩 승인 여부
    //2. 알림 상태
    if (!data) return;

    try {
      await updateNotification({ notificationId: fetch });

      onActionInNotification(navigate, id, data);
      if (newArr.length === 1) {
        props.setDots(false);
      }
      props.onClose();
    } catch (err) {
      toast.warning({
        title: "알림",
        description: "알림과 관련된 데이터를 요청 중입니다. 잠시 후 다시 시도해주세요.",
      });
    }
  };

  // const sortDate = (a: INotification, b: INotification) => {
  //   const prev = a.createdAt || new Date();
  //   const next = b.createdAt || new Date();

  //   return prev > next ? -1 : prev < next ? 1 : 0;
  // };

  return (
    <CommonDrawerModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={true}
      title="알림"
      styled={{ borderBottom: "none", fontWeight: 500 }}
    >
      <DrawerBody style={{ color: "#111827" }}>
        <div className="flex w-full items-center justify-between font-medium">새로운 알림</div>
        {newArr.length === 0 ? (
          <div className="flex h-[6.5rem] w-full items-center justify-center text-gray-500">
            새로운 알림이 없습니다.
          </div>
        ) : (
          <ul style={{ width: "100%" }}>
            {newArr.map((el) => (
              <li
                key={String(el._id)}
                id={String(el._id)}
                className="flex w-full cursor-pointer list-none items-start py-4 font-medium last:pb-12"
                onClick={() =>
                  // url이 따로 내려오지 않기 때문에 우선 유지
                  onNotifyPage(
                    el._id,
                    el.notification_type,
                    el.notification_type === "tenant_master_approval_request" ||
                      el.notification_type === "tenant_master_auto_approval"
                      ? el.tenant_id
                      : el.data,
                  )
                }
              >
                {/* 아이콘이 따로 내려오지 않기 때문에 우선 함수 유지 */}
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-zinc-100 p-2.5 text-[1.75rem] text-gray-300">
                  {format_notify_icon(el.notification_type)}
                </div>
                <div className="ml-5 flex flex-col">
                  <p className="text-xs font-normal leading-normal">{el.title}</p>
                  <div className="text-base font-medium">{el.body}</div>
                  <span className="pt-1 text-xs font-normal leading-normal text-gray-500">
                    {format_date(el.createdAt)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className="flex w-full items-center justify-between font-medium">지난 알림</div>
        {oldArr.length === 0 ? (
          <div className="flex h-[6.5rem] w-full items-center justify-center text-gray-500">지난 알림이 없습니다.</div>
        ) : (
          <ul style={{ width: "100%" }}>
            {oldArr.map((el) => (
              <li
                key={String(el._id)}
                id={String(el._id)}
                className="flex w-full cursor-pointer list-none items-start py-4 font-medium last:pb-12"
                onClick={() =>
                  // url이 따로 내려오지 않기 때문에 우선 함수 유지
                  onNotifyPage(
                    el._id,
                    el.notification_type,
                    el.notification_type === "tenant_master_approval_request" ||
                      el.notification_type === "tenant_master_auto_approval"
                      ? el.tenant_id
                      : el.data,
                  )
                }
              >
                {/* 아이콘이 따로 내려오지 않기 때문에 우선 함수 유지 */}
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-zinc-100 p-2.5 text-[1.75rem] text-gray-300">
                  {format_notify_icon(el.notification_type)}
                </div>
                <div className="ml-5 flex flex-col">
                  <p className="text-xs font-normal leading-normal">{el.title}</p>
                  <div className="text-base font-medium">{el.body}</div>
                  <span className="pt-1 text-xs font-normal leading-normal text-gray-500">
                    {format_date(el.createdAt)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </DrawerBody>
    </CommonDrawerModal>
  );
}
