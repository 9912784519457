import { Modal, ModalContent, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../components/styled/index";
import { useNavigate } from "react-router-dom";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useContext, useState } from "react";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { MemoizedItem } from "../_view/RentalItem";
import { warning } from "../../../lib/theme/toast";
import { AxiosError } from "axios";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import NotFound from "../../not-found";
import { GlobalContext } from "../../../App";
import { twMerge } from "tailwind-merge";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { useGetRentRoomList } from "../../../requests/rent-fee/useGetRentRoomList";
import { useCreateRentFee } from "../../../requests/rent-fee/useCreateRentFee";

type RentRoomType = {
  tenant_id: string;
  tenant_name: string;
  point?: number;
  price?: number;
  dong_ho: {
    _id: string;
    dong: string;
    ho: string;
  }[];
};

export default function RentFeeCreatePage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const auth = useUserAuth("rent_fee");
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [month, setMonth] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 등록
  const { isOpen: isBack, onOpen: goBack, onClose: dont } = useDisclosure(); //되돌아가기

  const { data, isLoading } = useGetRentRoomList();
  const { mutateAsync: createRentFee, isLoading: isCreateRentFeeLoading } = useCreateRentFee();

  const registered = (err: AxiosError) => {
    if (err.response?.status === 400)
      return toast({
        ...warning,
        title: "알림",
        description: "해당 년월에 이미 임대료가 등록되어있습니다. 다시 확인 후 등록해주세요.",
      });
    onOpen();
    toast({
      ...warning,
      title: "알림",
      description: "일시적인 오류로 실패했습니다. 잠시 후 다시 시도해주세요.",
    });
  };

  const onChange = useCallback((el: RentRoomType, value: string) => {
    el.price = Number(value || 0);
  }, []);

  const onSaveBillLetter = async () => {
    await createRentFee({
      date: moment(month).format("YYYY-MM-01"),
      rentFeeDetails: data?.data || [],
    })
      .then(() => {
        alert("임대료 납부 고지서를 등록했습니다. 확인을 누르면 임대료 현황 페이지로 이동합니다.");
        navigate("/rent-fee");
      })
      .catch(registered);
    onClose();
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth !== "edit") return <GetAuthorized />;

  return (
    <>
      <div className="translate flex h-full w-full flex-col overflow-y-auto bg-white px-6 py-10">
        <p className="flex border-b-2 border-gray-500 pb-6 text-2xl">
          <FiChevronLeft onClick={goBack} className="cursor-pointer text-[32px]" />
          임대료 납부고지서 생성
        </p>
        <ConfirmModal
          close="확인"
          title="되돌아가기"
          onClose={dont}
          isOpen={isBack}
          onClickCancle={dont}
          onClickSave={() => navigate(-1)}
          blockScrollOnMount={false}
        >
          <p>지금까지 작성하신 내용은 저장되지 않습니다.</p>
          <p>이전 페이지로 이동할까요?</p>
        </ConfirmModal>
        <ul className="px-6 py-5">
          <li>납부 요청 보내기까지 완료해야 입주자에게 납부 고지가 됩니다.</li>
          <li>
            입주사 관리 - 입주사 상세 페이지에 저장된 임대료로 표시되며, 고지서 내에서 임대료를 수정한 경우에는 해당
            월에만 일시적으로 적용됩니다.
          </li>
        </ul>
        <div className="border-t border-gray-300 font-medium text-gray-500">
          <div className="flex items-center border-b border-gray-300 p-2 pl-4">
            <div className="min-w-[104px] font-medium text-gray-900">부과년월</div>
            <C.DateWithIconBox style={{ width: "180px" }}>
              <DatePicker
                locale={ko}
                selected={month}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                onChange={(date: Date) => setMonth(date)}
                customInput={
                  <div className="relative flex w-full cursor-pointer items-center justify-between border border-gray-400 bg-white px-3 py-2 text-gray-500">
                    <p>{moment(month).format("YYYY년 MM월")}</p>
                    <FiChevronDown className="ml-1 text-xl" />
                  </div>
                }
              />
            </C.DateWithIconBox>
          </div>
        </div>
        <div className="flex flex-col items-start p-4">
          <p className="font-medium">임대료</p>
        </div>
        <div className="contents">
          <div className="table w-full border-b border-t border-gray-500 bg-gray-100 text-center">
            <div
              className={twMerge(
                "table-cell w-[4%] border-r border-gray-500 align-bottom",
                (data?.data?.length || 0) > 999 && "w-[5%]",
              )}
            >
              <div className="flex items-end justify-end p-1">
                <div className="borber border-b-[24px] border-l-[24px] border-b-gray-300 border-l-transparent" />
              </div>
            </div>
            <div className="table-cell w-1/5 border-r border-gray-500 bg-gray-100 p-3">입주사명</div>
            <div className="table-cell w-1/4 border-r border-gray-500 bg-gray-100 p-3">호실</div>
            <div className="table-cell w-1/5 border-r border-gray-500 bg-[#eaf3ff] p-3">임대료</div>
            <div className="table-cell w-[30%] bg-white" />
          </div>

          {data?.data?.map((el, idx) => (
            <MemoizedItem el={el} idx={idx} key={uuidv4()} length={data?.data?.length} onChange={onChange} />
          ))}
        </div>
      </div>

      <div className="sticky bottom-0 z-50 mt-2 flex w-full items-center justify-end rounded-2xl bg-white p-6">
        <button
          onClick={onOpen}
          className="border border-blue-600 bg-blue-600 px-3 py-2 text-base font-medium text-white"
        >
          저장하기
        </button>
        {isCreateRentFeeLoading ? (
          <Modal variant="contents" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent position="relative">
              <C.CustomSpin thickness="4px" speed="0.65s" size="xl" />
            </ModalContent>
          </Modal>
        ) : (
          <ConfirmModal
            close="저장하기"
            isOpen={isOpen}
            onClose={onClose}
            onClickCancle={onClose}
            onClickSave={onSaveBillLetter}
            title="임대료 납부고지서 저장하기"
          >
            <p>임대 항목을 모두 확인하셨나요?</p>
            <p>임대료 등록 후 납부 요청 보내기까지 완료하셔야 입주자에게 납부 고지가 됩니다.</p>
          </ConfirmModal>
        )}
      </div>
    </>
  );
}
