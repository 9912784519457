import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  management_fee_id: string;
  upload_type: string;
  payment_status: string;
  dong: string;
  search_ho: string;
  page: number;
  limit: number;
};

type Response = {
  data: {
    date: Date;
    updated_date: Date;
    user_name: string;
    is_open: boolean;
    selected_columns: string[];
    selected_columns_with_calculation_method?: {
      key: string;
      value: string;
      _id?: string; // 받아오는 데이터 객체의 id값
      use?: string; // 바뀔수 있음. 사용량 저장값
      calculation_method?: "by_room" | "by_area" | "by_use" | "direct_input_with_save" | "direct_input_with_no_save";
    }[];
    management_fee_details: {
      tenant_id: string;
      tenant_name: string;
      _id?: string;
      payment_status?: string;
      //여기 밑은 관리비 (IRoomFeeObj)
      room_id: string;
      area?: number; //아직 없는 호실이 있음
      tenant_registration_number?: string;
      dong: string;
      ho: string;
      items: {
        key: string;
        value: string;
        _id?: string; // 받아오는 데이터 객체의 id값
        use?: string; // 바뀔수 있음. 사용량 저장값
        calculation_method?: "by_room" | "by_area" | "by_use" | "direct_input_with_save" | "direct_input_with_no_save";
      }[];
    }[];
  };
  total_num: number;
  total_page_num: number;
};

export const useGetMaintenanceFeeDetailList = (
  { management_fee_id, upload_type, payment_status, dong, search_ho, page, limit }: Request,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["building/management_fee_detail/list", page],
    queryFn: () =>
      http.get<Response, Request>("/building/management_fee_detail/list", {
        management_fee_id,
        upload_type,
        payment_status,
        dong,
        search_ho,
        page,
        limit,
      }),
    enabled,
    keepPreviousData: true,
  });
};
