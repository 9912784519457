import { useQuery } from "@tanstack/react-query";
import { httpV2 } from "../../../lib/httpV2";

type Request = {
  buildingId: string;
  owner?: string;
  master?: string;
  normal?: string;
  searchText?: string;
  page?: number;
  limit?: number;
  filterStatus: ("approved" | "rejected" | "waiting")[];
};

type CertificationRequestType = {
  _id: string;
  type: string;
  details: {
    status: string;
    action_date: Date;
    _id: string;
  };
  createdAt: Date;
  buildingId: string;
  name: string;
  email: string;
  phoneNumber: string;
  buildingName: string;
};

type OwnerType = {
  type: "owner";
  ownerRoomInfo: {
    _id: string;
    dong: string;
    ho: string;
  };
} & CertificationRequestType;

type CompanyType = {
  type: "company";
  companyRoomInfos: Array<{
    _id: string;
    dong: string;
    ho: string;
  }>;
  authType: "master" | "normal";
  companyId: string;
  companyName: string;
  companyType: "office" | "store" | "residential" | "etc";
} & CertificationRequestType;

type Response = {
  totalCount: number;
  certificationCount: number;
  certificationRequests: Array<OwnerType | CompanyType>;
};

export const useGetCertificationRequestList = (
  { buildingId, owner, master, normal, searchText, page = 1, limit = 10, filterStatus }: Request,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["/certifications-request", buildingId, owner, master, normal, searchText, page, limit, filterStatus],
    queryFn: async () =>
      httpV2.get<Response>("/certifications-request", {
        buildingId,
        owner,
        master,
        normal,
        searchText,
        page,
        limit,
        filterStatus,
      }),
    enabled,
  });
};
