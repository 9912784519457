import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  point_per_month?: number;
  tenants_and_points: {
    tenant_id: string;
    amount: number;
  }[];
};

export const useUpdatePointMonthly = () => {
  return useMutation({
    mutationFn: ({ point_per_month = 0, tenants_and_points }: Request) => {
      return http.post("/building/point/set/auto_recharge", {
        point_per_month,
        tenants_and_points,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["tenant/rooms/point"]);
    },
  });
};
