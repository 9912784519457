import { Checkbox, Field, Label } from "@headlessui/react";
import IconCheck from "./icons/IconCheck";
import { twMerge } from "tailwind-merge";

type Props = {
  label?: string;
  enabled: boolean;
  disabled?: boolean;
  setEnabled?: (enabled: boolean) => void;
  gapSize?: "sm" | "md" | "lg";
  size: "sm" | "md" | "lg";
  labelSize?: "sm" | "md" | "lg";
  customLabel?: JSX.Element;
};

export default function CustomCheckBox({
  size,
  label,
  gapSize = "md",
  labelSize,
  customLabel,
  enabled,
  disabled = false,
  setEnabled,
}: Props) {
  const checkBoxSizeClass = {
    sm: "h-4 w-4",
    md: "h-5 w-5",
    lg: "h-6 w-6",
  }[size];

  const gapSizeClass = {
    sm: "gap-1",
    md: "gap-2",
    lg: "gap-4",
  }[gapSize];

  const labelSizeClass = {
    sm: "text-xs font-medium leading-4",
    md: "text-sm",
    lg: "text-base font-medium leading-5",
  }[labelSize || size];

  return (
    <Field className={twMerge("flex cursor-pointer items-center gap-2", gapSizeClass)}>
      <Checkbox
        disabled={disabled}
        checked={enabled}
        onChange={setEnabled}
        className={twMerge(
          "box-border flex items-center justify-center rounded border bg-white ui-checked:border-0 ui-checked:bg-blue-600",
          checkBoxSizeClass,
        )}
      >
        <IconCheck className="opacity-0 ui-checked:opacity-100" />
      </Checkbox>
      {label && <Label className={labelSizeClass}>{label}</Label>}
      {customLabel && <Label className={labelSizeClass}>{customLabel}</Label>}
    </Field>
  );
}
