import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  rentalId: string;
  rent_fee_details: [
    {
      _id: string;
      payment_status: string;
    },
  ];
};

export const useUpdateRentFeeStatus = () => {
  return useMutation({
    mutationFn: ({ rentalId, rent_fee_details }: Request) => {
      return http.post("/building/rent_fee/update_type", { rentalId, rent_fee_details });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["rent_fee"]);
    },
  });
};
