import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  date: string;
  enabled: boolean;
};

type Response = {
  data: {
    _id: string;
    date: Date;
    sum_of_rent_fee?: number;
    user_name: string;
    updated_date: Date;
    is_open: boolean;
  }[];
};

export const useGetRentFeeList = ({ date, enabled }: Request) => {
  return useQuery({
    queryKey: ["building/rent_fee/list", date],
    queryFn: async () => http.get<Response>("/building/rent_fee/list", { date }),
    enabled,
  });
};
