import { PropsWithChildren, useState, Dispatch, SetStateAction, useEffect, useContext } from "react";
import LayoutHeader from "./header";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
import LayoutFooter from "./footer";
import { GlobalContext } from "../../App";
import { twMerge } from "tailwind-merge";
import { useGetNotifications } from "../../requests/notification/useGetNotifications";

export interface INotifications {
  dots: boolean;
  setDots: Dispatch<SetStateAction<boolean>>;
  list?: {
    _id: string;
    building_id: string;
    user_id: string;
    notification_type: string;
    title: string;
    body: string;
    data: string;
    is_checked: boolean;
    createdAt: Date;
    tenant_id: string;
    tenant_name: string;
  }[];
}

export interface IHeader extends INotifications {
  isMenu: boolean;
  setIsMenu: Dispatch<SetStateAction<boolean>>;
}

export default function Layout({ children }: PropsWithChildren) {
  const location = useLocation();
  const { buildInfo } = useContext(GlobalContext);
  const isMobile =
    window.navigator.userAgent.includes("Mobi") ||
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("Android");
  const [isNeedFooter, setIsNeedFooter] = useState(isMobile);

  const [dots, setDots] = useState(false);

  const { data } = useGetNotifications({
    buildingId: buildInfo?._id.toString(),
    enabled: buildInfo?._id !== undefined,
  });

  useEffect(() => {
    if (!data?.data) return;
    data?.data?.find((el) => !el.is_checked) !== undefined && setDots(true);
  }, [data]);

  useEffect(() => {
    if (!isMobile) return setIsNeedFooter(isMobile);

    const path = !(
      location.pathname.includes("new") ||
      location.pathname.includes("create") ||
      location.pathname.includes("detail") ||
      location.pathname.includes("edit")
    );

    setIsNeedFooter(path);
  }, [location.pathname]);

  //header modal
  const [isMenu, setIsMenu] = useState(false);
  const onCloseModal = () => {
    if (isMenu) setIsMenu(false);
  };

  const notifications: INotifications = {
    dots,
    setDots,
    list: data?.data,
  };

  const headerProps: IHeader = {
    isMenu,
    setIsMenu,
    ...notifications,
  };

  return (
    <div className="h-full w-full max-md:fixed max-md:overflow-hidden">
      <LayoutHeader {...headerProps} />
      <div
        onClick={onCloseModal}
        className={twMerge(
          `flex w-full max-lg:h-[calc(100vh-72px)] max-md:h-[calc(100vh-140px)]`,
          isNeedFooter ? "h-[calc(100vh-120px)]" : "h-[calc(100vh-64px)]",
        )}
      >
        <SideBar />
        <div className="relative flex-1 max-lg:w-[calc(100%-13rem)] max-md:w-full max-md:p-1">
          <div className="h-full overflow-y-scroll">{children}</div>
          <div className="max-md:h-[90px] max-md:w-full max-md:bg-inherit" />
        </div>
      </div>
      {isNeedFooter && <LayoutFooter {...notifications} />}
    </div>
  );
}
