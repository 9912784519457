import moment from "moment";
import { useDisclosure, Text, Flex } from "@chakra-ui/react";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { useContext, useState, MouseEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../App";
import RespCancelModal from "../../../components/modal/responsive/cancel";
import FullPageSpinner from "../../../components/Spinner";
import { ImageArr, InfoBoxText, ShowImages } from "../../../components/styled";
import { colors } from "../../../components/styled/common";
import {
  ContourWrapper,
  HeaderArrowBox,
  DetailHeaderTitle,
  DetailTitleBox,
  WriterInfo,
  DetailBodyBox,
  FlexibleTextArea,
  FlexibleWrapper,
} from "../../../components/styled/webApp";
import StandardMenuSheet from "../../../components/Menu";
import { toast } from "../../../lib/utils/toast";
import { useGetResourceList } from "../../../requests/resource/useGetResourceList";
import { useDeleteResource } from "../../../requests/resource/useDeleteResource";

export default function ResourceDetailPage() {
  const navigate = useNavigate();
  const { faqId } = useParams();

  const { data: resourceListData } = useGetResourceList({ category: "" });
  const data = resourceListData?.data?.find((v) => v._id === faqId);

  const { user } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false); //faq 수정, 삭제
  const { isOpen: isDelete, onOpen, onClose } = useDisclosure(); // 삭제 확인
  const [height, setHeight] = useState<number>();
  const faqBody = document.querySelector("#faq_body");

  useEffect(() => {
    if (!data) return;
    if (data && faqBody === null) return setHeight((data.answer.split("\n").length + 1) * 24 * 1.5);
    setHeight(faqBody?.scrollHeight);
  }, [data, faqBody]);

  const onClickEditFAQ = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setIsOpen(false);

    navigate(`/resources/${faqId}/edit`);
  };

  const onClickDeleteFAQ = () => {
    onOpen();
  };

  const { mutateAsync: deleteResource, isLoading: isDeleteResourceLoading } = useDeleteResource();

  if (!data) return <FullPageSpinner />;
  return (
    <FlexibleWrapper onClick={() => isOpen && setIsOpen(false)}>
      <ContourWrapper location="only">
        <HeaderArrowBox>
          <FiChevronLeft
            onClick={() => {
              navigate("/resources");
            }}
          />
          {user?.user_type === "master" && (
            <StandardMenuSheet
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onEditAction={onClickEditFAQ}
              onDeleteAction={onClickDeleteFAQ}
            />
          )}
          {isDelete && (
            <RespCancelModal
              title="시설안내 삭제"
              isOpen={isDelete}
              next="삭제"
              onClose={onClose}
              onAction={() =>
                !isDeleteResourceLoading &&
                deleteResource({ faq_id: faqId || "" })
                  .then(() => {
                    toast.success({
                      title: "시설안내 삭제",
                      description: "시설안내가 삭제되었습니다.",
                    });
                    navigate("/resources");
                  })
                  .catch(() =>
                    toast.warning({
                      title: "시설안내 삭제",
                      description: "일시적으로 시설안내 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.",
                    }),
                  )
              }
            >
              <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
              <Text>정말로 삭제하시겠습니까?</Text>
            </RespCancelModal>
          )}
        </HeaderArrowBox>
        <DetailHeaderTitle>
          <span style={{ color: colors.mainBlue }}>{data.category}</span>
          <DetailTitleBox>{data.question}</DetailTitleBox>
        </DetailHeaderTitle>
        <WriterInfo>
          <Flex>
            <Text>업데이트일시</Text>
            <InfoBoxText>{moment.utc(data.updatedAt).format("YYYY-MM-DD HH:mm")}</InfoBoxText>
          </Flex>
        </WriterInfo>
        <DetailBodyBox>
          <FlexibleTextArea
            readOnly
            id="faq_body"
            value={data.answer}
            h={height || 96} //(엔터키 기준 자른 배열 길이 * 폰트크기 * line-height) + 여유라인 1줄
          />
          {data.images.length > 0 && (
            <ImageArr>
              {data.images.map((el, idx) => (
                <div key={idx}>
                  <ShowImages
                    src={el}
                    // onLoad={() => setIsLoading(false)}
                    alt={`관련 이미지 ${idx + 1}`}
                    onClick={() => window.open(el, "_blank")}
                  />
                </div>
              ))}
            </ImageArr>
          )}
        </DetailBodyBox>
      </ContourWrapper>
    </FlexibleWrapper>
  );
}
