import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomBadge from "../../../components/CustomBadge";
import Input from "../../../components/Input/Input";
import Pagination from "../../../components/custom/Pagination";
import IconSearch from "../../../components/icons/IconSearch";
import { useCallback, useContext, useRef, useState } from "react";
import { CustomMultipleListBox } from "../../../components/CustomListBox";
import { useDebounce } from "../../../hooks/operators/use-debounce";
import Icon from "../../../types/solar-icon";
import { GlobalContext } from "../../../App";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import formatDate from "../../../utils/formatDate";
import { getSearchRoomText } from "../../../utils/getSearchRoom";
import { useGetCertificationRequestList } from "../../../requests/member/request/useGetCertificationRequestList";

const Status = [
  { name: "소유자", value: "owner" },
  { name: "입주자 (최고관리자)", value: "master" },
  { name: "입주자", value: "normal" },
];

export default function MemberHistoryListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { buildInfo } = useContext(GlobalContext);

  const params = new URLSearchParams(search);

  const searchParamsObject = {
    buildingId: buildInfo?._id.toString() || "",
    owner: searchParams.get("owner") || "",
    master: searchParams.get("master") || "",
    normal: searchParams.get("normal") || "",
    searchText: searchParams.get("searchText") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 10,
  };

  const { data } = useGetCertificationRequestList(
    { ...searchParamsObject, filterStatus: ["approved", "rejected"] },
    !!buildInfo?._id,
  );

  const createQueryString = useCallback(
    (key: string, value: string, defaultValue = "") => {
      params.set(key, value);
      if (value === defaultValue) params.delete(key);
      params.delete("page");
      return params.toString();
    },
    [search],
  );

  const createQueryArray = useCallback(
    (value: string[]) => {
      const deleteParamsArr = ["page", "owner", "master", "normal"];
      deleteParamsArr.map((v) => params.delete(v));
      value
        .filter((k) => k)
        .map((v) => {
          params.set(v, "true");
        });
      return params.toString();
    },
    [search],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = useDebounce(
    (text) => {
      navigate(pathname + "?" + createQueryString("searchText", text, ""));
    },
    300,
    [search],
  );

  return (
    <div className="mb-[60px] w-full">
      <div className="flex h-[60px] items-center justify-between border-b border-gray-200 bg-gray-50 px-6">
        <div className="flex w-[10.5rem] gap-3">
          <CustomMultipleListBox
            data={Status}
            getValue={() =>
              [
                searchParamsObject.owner && "owner",
                searchParamsObject.master && "master",
                searchParamsObject.normal && "normal",
              ].filter((v) => v)
            }
            onChange={(value) => navigate(pathname + "?" + createQueryArray(value))}
            label="신청권한"
          />
        </div>

        <div className="w-[27.75rem]">
          <Input
            ref={searchInputRef}
            themeSize="sm"
            placeholder="이름, 연락처, 입주사명, 호실"
            defaultValue={searchParamsObject.searchText}
            iconElement={<IconSearch className="h-5 w-5" />}
            onChange={(e) => handleTextChange(e.target.value)}
            showDeleteButton={true}
            onDelete={() => {
              if (searchInputRef.current) searchInputRef.current.value = "";
              navigate(pathname + "?" + createQueryString("searchText", ""));
            }}
          />
        </div>
      </div>

      <div className="w-full px-6">
        <table className="w-full table-fixed">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="w-1/5 px-2 py-3 text-left leading-normal">이름</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">연락처</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">신청권한</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">입주사</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">동/호</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">처리일시</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">승인/반려</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.certificationRequests.map((item) => {
              return (
                <tr
                  key={item._id}
                  className={
                    "border-y border-gray-200"
                    // transition-all duration-75 cursor-pointer hover:bg-blue-50 hover:opacity-75
                  }
                >
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{item.name || "이름 없는 구성원"}</td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {formatPhoneNumber(item.phoneNumber)}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    <div className="flex flex-wrap">
                      <CustomBadge type={item.type === "owner" ? "owner" : item.authType} />
                    </div>
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {"companyName" in item ? item.companyName : "-"}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.type === "owner"
                      ? `${item.ownerRoomInfo.dong}동 ${item.ownerRoomInfo.ho}호`
                      : getSearchRoomText(item.companyRoomInfos, searchParamsObject.searchText) +
                        (item.companyRoomInfos.length > 1 ? ` 외 ${item.companyRoomInfos.length - 1}` : "")}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {formatDate(item.details.action_date)}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-700">
                    {item.details.status === "approved" ? (
                      <span className="font-semibold text-green-500">승인</span>
                    ) : (
                      <span className="font-semibold text-red-400">반려</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {data?.data.certificationRequests.length === 0 ? (
          <div className="flex h-[25rem] flex-col items-center justify-center gap-4 text-gray-700">
            <Icon.DangerCircle size={40} />
            {searchParamsObject.searchText === "" &&
            searchParamsObject.owner === "" &&
            searchParamsObject.master === "" &&
            searchParamsObject.normal === "" ? (
              <span className="text-xl font-medium leading-normal">현재 인증 승인을 신청한 건이 없습니다.</span>
            ) : (
              <>
                <span className="text-xl font-medium leading-normal">검색 결과가 없습니다.</span>
                <span className="font-medium leading-normal text-gray-400">
                  다른 검색어를 시도해 보거나 검색 필터를 삭제하세요.
                </span>
              </>
            )}
          </div>
        ) : (
          <div className="mt-3 flex items-center justify-end gap-3">
            <p className="text-base leading-normal text-gray-500">총 {data?.data.totalCount || 0}개의 결과</p>
            <Pagination
              currentPage={searchParamsObject.page}
              itemCount={data?.data.totalCount || 0}
              showPageAmount={10}
            />
          </div>
        )}
      </div>
    </div>
  );
}
