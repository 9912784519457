import { useContext } from "react";
import { GlobalContext } from "../../../../App";

export const showPermission = () => {
  const permissionArr: {
    key: string;
    value: string;
  }[] = [];
  const { buildInfo } = useContext(GlobalContext);

  if (!buildInfo) return permissionArr;

  permissionArr.push({ key: "notice", value: "read" });
  permissionArr.push({ key: "tenant", value: "read" });
  if (buildInfo.services.COMPLAINT?.enabled) {
    permissionArr.push({ key: "complaint", value: "read" });
  }
  if (buildInfo.services.SURVEY?.enabled) {
    permissionArr.push({ key: "survey", value: "read" });
  }
  if (buildInfo.services.MEETING_ROOM?.enabled) {
    permissionArr.push({ key: "room_public", value: "read" });
  }
  if (buildInfo.services.REGULAR_PARKING?.enabled) {
    permissionArr.push({ key: "car", value: "read" });
  }
  if (buildInfo.services.VOTES?.enabled) {
    permissionArr.push({ key: "vote", value: "read" });
  }
  if (buildInfo.services.HEAT_AND_COOL?.enabled) {
    permissionArr.push({ key: "air_conditioning", value: "read" });
  }
  if (buildInfo.services.MAINTENANCE_FEE?.enabled) {
    permissionArr.push({ key: "management_fee", value: "read" });
  }
  if (buildInfo.services.RENT_FEE?.enabled) {
    permissionArr.push({ key: "rent_fee", value: "read" });
  }

  return permissionArr;
};
