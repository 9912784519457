import styled from "@emotion/styled";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { Flex, UseModalProps } from "@chakra-ui/react";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { company_search } from "../../../lib/api/queries/urls";
import { ITenant } from "../../../lib/types/Imodels";
import TenantItem from "./TenantItem";
import { colors } from "../../styled/common";
import ModalSaveWithContents from "../responsive/content";
import GrayColorSelect from "../../select";
import GrayRadiusInput from "../../Input/responsive";
import { breakPoints } from "../../styled/media";
import { toast } from "../../../lib/utils/toast";

export interface ISelectTenant {
  _id?: ITenant;
  name: string;
}
interface ISearchTenant extends UseModalProps {
  case?: string;
  setSelected: Dispatch<SetStateAction<ISelectTenant | undefined>> | undefined;
}

const ComplanyList = styled.div<{ l: boolean }>`
  height: 17rem;
  border: 1px solid ${colors.gray2};
  width: 100%;
  margin: 16px 0 8px 0;
  ${({ l }) => l && `overflow-y: scroll`};
  color: #6b7280;
  .selected {
    background-color: #eff6ff;
    font-weight: 500;
    color: ${colors.mainBlue};
  }
  @media ${breakPoints.phones} {
    height: 15rem;
  }
`;

const ListNone = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default function SearchInTenantList(props: ISearchTenant) {
  const [data, setData] = useState<ITenant[]>();
  const [clicked, setClicked] = useState<ISelectTenant>();
  const [inputs, setInputs] = useState({ id: "search_name", value: "" });
  // const [isLoading, setIsLoading] = useState(false)

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputs.id === "search_ho") {
      const valid = /^[0-9]+$/;
      const empty = /^$/;
      if (!valid.test(e.target.value) && !empty.test(e.target.value)) return;
      if (e.target.value.length > 10) return alert("최대 10자리 이하의 숫자로 입력해주세요.");
    } else {
      if (e.target.value.length > 30) return alert("최대 30자 이하로 입력해주세요.");
    }

    setInputs({ id: inputs.id, value: e.target.value });
  };

  const onLoadCompany = () => {
    if (inputs.value === "")
      return toast.warning({
        title: "알림",
        description: "검색어를 먼저 입력해주세요.",
      });

    // setClicked(undefined);
    fetchClient
      .get<BaseResponse<ITenant[]>>(company_search, {
        params: { [inputs.id]: inputs.value },
      })
      .then((res) => setData(res.data.data));
  };

  const onSelectTenant = () => {
    props.onClose();
    props.setSelected && props.setSelected(clicked);
  };

  return (
    <ModalSaveWithContents
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="입주사 검색"
      next={`${props.case ? props.case : "입주사"} 선택하기`}
      before="취소"
      onAction={onSelectTenant}
      scrollBehavior="inside"
      _isDisabled={!clicked}
    >
      <>
        <Flex>
          <GrayColorSelect
            value={inputs.id}
            _style={{ textAlign: "left" }}
            _onChange={(e) => setInputs({ id: e.target.value, value: "" })}
          >
            <option value="search_name">입주사명</option>
            <option value="search_ho">호실번호</option>
          </GrayColorSelect>
          <GrayRadiusInput
            _style={{ width: "calc(100% - 7.5rem)" }}
            value={inputs.value || ""}
            _onChange={onChangeSearch}
            placeholder={
              inputs.id === "search_ho"
                ? "검색하실 호실번호를 숫자로 입력해주세요."
                : "검색하실 입주사명을 입력해주세요."
            }
            onKeyDown={(e) => e.key === "Enter" && onLoadCompany()}
          />
          {/* <Box w="144px">
            <Select
              
              variant="default"
            >
              <option value="search_ho">호실번호</option>
              <option value="search_name">입주사명</option>
            </Select>
          </Box> */}
          {/* <ContentInputs
            ml="2"
            w="320px"
            value={inputs.value || ""}
            onChange={onChangeSearch}
            placeholder={
              inputs.id === "search_ho"
                ? "검색하실 호실번호를 숫자로 입력해주세요."
                : "검색하실 입주사명을 입력해주세요."
            }
            onKeyDown={(e) => e.key === "Enter" && onLoadCompany()}
          /> */}
        </Flex>
        <ComplanyList l={data && data.length !== 0 ? true : false}>
          {data === undefined ? (
            <ListNone>검색어를 입력해주세요.</ListNone>
          ) : (
            <>
              {data.length === 0 ? (
                <ListNone>검색결과가 없습니다.{<br />}호실 또는 입주사명을 다시 검색해주세요.</ListNone>
              ) : (
                <>
                  {data.map((el, idx) => (
                    <TenantItem key={String(el._id)} el={el} idx={idx} clicked={clicked} setClicked={setClicked} />
                  ))}
                </>
              )}
            </>
          )}
        </ComplanyList>
        {/* <ModalFooter mt="0">
          <Button variant="cancel" onClick={props.onClose}>
            취소
          </Button>
          <SelectButton
            variant="bgBlue"
            s={clicked?.name}
            isDisabled={!clicked ? true : false}
            onClick={onSelectTenant}
          >
            {props.case ? props.case : "입주사"} 선택하기
          </SelectButton>
        </ModalFooter> */}
      </>
    </ModalSaveWithContents>
  );
}
