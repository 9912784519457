import { Link, useLocation } from "react-router-dom";
import Icon from "../../types/solar-icon";
import { twMerge } from "tailwind-merge";
import { useBuildingInfoQuery } from "../../requests/building";
import { useServices } from "../../hooks/use-servies";
import FullPageSpinner from "../Spinner";
import { Alarm } from "iconsax-react";

export default function SideBar() {
  const currentPath = useLocation().pathname.split("/")[1];
  const { data, isLoading } = useBuildingInfoQuery();

  const services = useServices();

  if (isLoading)
    return (
      <div className="absolute left-0 top-0 z-50 h-full w-full bg-white">
        <FullPageSpinner />
      </div>
    );

  return (
    <aside className="min-w-[15rem] overflow-auto border-r border-gray-200 bg-gray-50 max-md:hidden">
      <div className="flex items-center gap-2 border-b border-gray-200 bg-white px-5 py-3">
        <Icon.Buildings2 />
        <h1 className="text-base font-bold text-gray-800">{data?.data.name}</h1>
      </div>
      <div className="px-5 py-2">
        {services.map((service) => (
          <Link
            key={service.id}
            to={service.pathname}
            className={twMerge(
              "flex items-center justify-between rounded-md p-3 text-sm font-medium leading-4 text-gray-500",
              "transition-all duration-75 hover:bg-gray-100 hover:text-blue-600",
              currentPath === service.href && "bg-gray-100 font-bold text-blue-600",
            )}
          >
            <div className="flex items-center gap-2">
              {service.iconElement}
              <p>{service.name}</p>
            </div>
            {service.isAvailable ? null : (
              <span className="rounded-lg bg-purple-100 px-1 py-0.5 text-[8px] font-medium leading-none text-purple-400">
                도입필요
              </span>
            )}
          </Link>
        ))}
        {data?.data.building_type === "knowledge_industry_center" && (
          <a
            href="https://k-kica.org/notice/152"
            target="_blank"
            className={twMerge(
              "flex items-center justify-between rounded-md p-3 text-sm font-medium leading-4 text-gray-500",
              "transition-all duration-75 hover:bg-gray-100 hover:text-blue-600",
            )}
            rel="noreferrer"
          >
            <div className="flex items-center gap-2">
              <Alarm size={20} className="text-blue-600" variant="Bold" />
              <p className="text-blue-600">지산포 오픈!</p>
            </div>
          </a>
        )}
      </div>
    </aside>
  );
}
