import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  room_type: string;
  dong: string;
  search_name: string;
  page: number;
  limit?: number;
  is_all?: boolean;
};

type Response = {
  data: {
    _id: string;
    tenant_type: "office" | "store" | "residential" | "etc";
    dong_ho: string;
    dong: string;
    ho: string;
    name: string;
    phone_number: string;
    point: number;
    is_activated: boolean;
  }[];
  total_num: number;
  total_page_num: number;
};

export const useGetPointList = (
  { room_type, dong, search_name, page, limit = 10, is_all = true }: Request,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["point/list", page, room_type, dong],
    queryFn: () =>
      http.get<Response, Request>("/building/tenant/list", {
        room_type,
        dong,
        search_name,
        page,
        limit,
        is_all,
      }),
    enabled,
  });
};
