import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  UseModalProps,
  useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { queryClient } from "../../..";
import ContentInputs from "../../../components/Input/middleContents";
import SingleRow, { WrapFlex } from "../../../components/modal/table/singleRow";
import * as C from "../../../components/styled";
import { fetchClient } from "../../../lib/api/axios";
import { manage_fee_key, manage_fee_list_key } from "../../../lib/api/queries/keys";
import { create_manage_fee, update_manage_fee } from "../../../lib/api/queries/urls";
import { warning } from "../../../lib/theme/toast";
import { IManagement, IRentalList } from "../../../lib/types/Imodels";
import { Month } from "../../../lib/utils/dataFormat";
import { lastYear, nextYear, Year } from "../../../lib/utils/format";
import { ISearchCost } from "../commons/types";
import { useUploadFile } from "../../../lib/api/queries/commons";
import { useParams } from "react-router-dom";
import { colors } from "../../../components/styled/common";
import { useCreateMaintenanceFee } from "../../../requests/maintenance-fee/useCreateMaintenanceFee";
import { useUpdateMaintenanceFee } from "../../../requests/maintenance-fee/useUpdateMaintenanceFee";

const LoadText = styled.div`
  background-color: ${colors.gray1};
  width: 100%;
  margin-top: 8px;
  font-weight: 500;
  padding: 12px;
  font-size: 15px;
  color: ${colors.gray4};
`;

const InfoBox = styled.div`
  max-width: 528px;
  border-bottom: 1px solid ${colors.gray2};
  padding-bottom: 24px;
`;

type RequestObj = {
  desired_date: string;
  management_fee_files: string[];
  file_original_name: string;
  management_fee_id?: string;
};

interface ICSVFiles extends UseModalProps {
  isEdit: boolean;
  el?: IManagement;
  data?: {
    date: Date;
    updated_date: Date;
    user_name: string;
    is_open: boolean;
    selected_columns: string[];
    selected_columns_with_calculation_method?: {
      key: string;
      value: string;
      _id?: string; // 받아오는 데이터 객체의 id값
      use?: string; // 바뀔수 있음. 사용량 저장값
      calculation_method?: "by_room" | "by_area" | "by_use" | "direct_input_with_save" | "direct_input_with_no_save";
    }[];
    management_fee_details: {
      tenant_id: string;
      tenant_name: string;
      _id?: string;
      payment_status?: string;
      //여기 밑은 관리비 (IRoomFeeObj)
      room_id: string;
      area?: number; //아직 없는 호실이 있음
      tenant_registration_number?: string;
      dong: string;
      ho: string;
      items: {
        key: string;
        value: string;
        _id?: string; // 받아오는 데이터 객체의 id값
        use?: string; // 바뀔수 있음. 사용량 저장값
        calculation_method?: "by_room" | "by_area" | "by_use" | "direct_input_with_save" | "direct_input_with_no_save";
      }[];
    }[];
  };
}

export default function UploadCSVModal(props: ICSVFiles) {
  const toast = useToast();
  const { billId } = useParams();
  const [date, setDate] = useState<ISearchCost>({ year: "", month: "" });
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File | null>(null);

  const { mutateAsync: createMaintenanceFee, isLoading: isLoadingCreateMaintenanceFee } = useCreateMaintenanceFee();
  const { mutateAsync: updateMaintenanceFee, isLoading: isLoadingUpdateMaintenanceFee } = useUpdateMaintenanceFee();

  const isLoading = isLoadingCreateMaintenanceFee || isLoadingUpdateMaintenanceFee;

  useEffect(() => {
    if (!props.data) return;
    setDate({
      year: String(moment(props.data.date).year()),
      month: String(moment(props.data.date).month() + 1).padStart(2, "0"),
    });
  }, [props.isEdit, props.data]);

  const onClearState = () => {
    props.onClose();
    setFiles(null);
    setDate({ year: "", month: "" });
  };

  const onChageFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;
    const keywords = ["csv", "xls"];

    const regex = new RegExp(`\\.(${keywords.join("|")})$`, "i");
    const includesAny = regex.test(file.name);

    if (!includesAny)
      return toast({
        ...warning,
        title: "파일 형식 제한",
        description:
          "관리비 파일은 csv 형식만 가능합니다. 파일 형식 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
      });
    setFiles(file);
    e.target.value = "";
  };

  const onAdminUpload = () => {
    if (isLoading) return;
    if (files === null)
      return toast({
        ...warning,
        title: `관리비 ${props.isEdit ? "변경" : "등록"} 오류`,
        description: `관리비 ${props.isEdit ? "변경" : "등록"} 위해 파일을 먼저 선택해주세요.`,
      });

    if (!props.isEdit && (!date.year || !date.month))
      return toast({
        ...warning,
        title: `관리비 등록 오류`,
        description: `관리비 등록 위해 부과년월을 먼저 선택해주세요.`,
      });

    const newObj: RequestObj = {
      desired_date: `${date.year}-${date.month}-01T00:00:00Z`,
      management_fee_files: [],
      file_original_name: files.name,
    };

    useUploadFile([files]).then((res) => {
      newObj.management_fee_files = res.data.data;

      props.isEdit
        ? updateMaintenanceFee({
            desired_date: `${date.year}-${date.month}-01T00:00:00Z`,
            management_fee_files: res.data.data || [],
            file_original_name: files.name,
            management_fee_id: billId || "",
          })
            .then(() => {
              onClearState();
              alert(
                `관리비 파일이 변경되었습니다. 납부요청 보내기까지 완료하셔야 입주자에게 관리비 납부 알림이 전송됩니다.`,
              );
            })
            .catch((err) => {
              if (err.response?.status === 402) {
                toast({
                  ...warning,
                  title: "관리비 등록 오류",
                  description:
                    "관리비 세부 항목이 일치하지 않습니다. ERP 내에서 관리비 항목을 편집했을 경우 오피스너 담당자에게 연락주세요.",
                });
              }
            })
        : createMaintenanceFee({
            desired_date: `${date.year}-${date.month}-01T00:00:00Z`,
            management_fee_files: res.data.data || [],
            file_original_name: files.name,
          })
            .then(() => {
              onClearState();
              alert(
                `관리비 파일이 등록되었습니다. 납부요청 보내기까지 완료하셔야 입주자에게 관리비 납부 알림이 전송됩니다.`,
              );
            })
            .catch((err) => {
              if (err.response?.status === 400) {
                toast({
                  ...warning,
                  title: "관리비 등록 오류",
                  description: "해당 년도, 월은 이미 관리비 파일이 등록되었습니다.",
                });
              } else if (err.response?.status === 402) {
                toast({
                  ...warning,
                  title: "관리비 등록 오류",
                  description:
                    "관리비 세부 항목이 일치하지 않습니다. ERP 내에서 관리비 항목을 편집했을 경우 오피스너 담당자에게 연락주세요.",
                });
              }
            });
    });
  };

  return (
    <Modal variant="contents" isOpen={props.isOpen} onClose={onClearState}>
      <ModalOverlay />
      <C.TableContent position="relative">
        {isLoading ? (
          <C.CustomSpin thickness="4px" speed="0.65s" size="xl" />
        ) : (
          <>
            <ModalHeader>관리비 {props.isEdit ? "재업로드" : "업로드"}</ModalHeader>
            <ModalBody p="24px 28px 16px 24px">
              <InfoBox>
                관리비 파일 {props.isEdit ? "변경" : "등록"} 후 납부 요청 보내기까지 완료하셔야 입주자에게 납부 고지가
                됩니다.
              </InfoBox>
              <SingleRow title="부과년월">
                <WrapFlex style={{ padding: "4px 0" }}>
                  {props.isEdit ? (
                    <ContentInputs
                      style={{ border: "1px solid #fff", padding: "8px 0" }}
                      placeholder="메모 사항을 입력해주세요"
                      value={props.data?.date ? moment.utc(props.data?.date).format("YYYY년 MM월") : ""}
                      isReadOnly
                    />
                  ) : (
                    <Flex>
                      <Select
                        mr={2}
                        id="year"
                        variant="default"
                        value={date.year}
                        onChange={(e) => setDate({ ...date, [e.target.id]: e.target.value })}
                      >
                        <option value="">부과년도</option>
                        <option value={lastYear}>{lastYear}년</option>
                        <option value={Year}>{Year}년</option>
                        <option value={nextYear}>{nextYear}년</option>
                      </Select>
                      <Select
                        id="month"
                        variant="default"
                        value={date.month}
                        onChange={(e) => setDate({ ...date, [e.target.id]: e.target.value })}
                      >
                        <option value="">부과월</option>
                        {Month.map((el) => (
                          <option key={uuidv4()} value={el}>
                            {el}월
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  )}
                </WrapFlex>
              </SingleRow>
              {/* <SingleRow title="메모사항">
                <WrapFlex style={{ padding: "4px 0" }}>
                  <ContentInputs
                    value={desc || ""}
                    style={{ marginRight: "0" }}
                    placeholder="메모 사항을 입력해주세요"
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </WrapFlex>
              </SingleRow> */}
              <SingleRow title="파일">
                <WrapFlex style={{ padding: "4px 0" }}>
                  <Flex alignItems="center" w="100%">
                    <ContentInputs
                      isReadOnly
                      // placeholder={
                      //   props.isEdit
                      //     ? props.el?.file_original_name
                      //     : "선택된 파일 없음"
                      // }
                      placeholder="업로드 할 파일을 선택해주세요."
                      value={files?.name || ""}
                      style={{
                        color: props.isEdit && files === null ? "#D1D5DB" : "#6b7280",
                      }}
                    />
                    <Button variant="basic" onClick={() => fileRef.current?.click()}>
                      파일 선택
                    </Button>
                    <input
                      type="file"
                      ref={fileRef}
                      multiple={false}
                      onChange={onChageFiles}
                      style={{ display: "none" }}
                    />
                  </Flex>
                  <LoadText>관리비 엑셀 데이터를 업로드할 수 있습니다.</LoadText>
                </WrapFlex>
              </SingleRow>
            </ModalBody>
            <ModalFooter style={{ paddingTop: 0 }}>
              <Button onClick={onClearState} variant="cancel">
                취소
              </Button>
              <Button onClick={onAdminUpload} variant="bgBlue">
                업로드하기
              </Button>
            </ModalFooter>
          </>
        )}
      </C.TableContent>
    </Modal>
  );
}
