import { useQuery } from "@tanstack/react-query";
import { http } from "../../lib/http";

type Response = {
  data: {
    tenant_master_approval_request: number;
    tenant_master_auto_approval: number;
    all_complaint_to_do: number;
    new_complaint: number;
    new_comment_complaint: number;
    pending_resident_card: number;
    car_request: number;
    room_public_reservation: {
      room_public_type: string;
      cnt: number;
    }[];
    new_temperature: number;
    certification: {
      owner: number;
      master: number;
      member: number;
    };
  };
};

export const useGetDashboard = (enabled?: boolean) => {
  return useQuery({
    queryKey: ["building/dashboard"],
    queryFn: () => http.post<null, Response>("/building/dashboard"),
    enabled,
  });
};
