import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  account_ids: string[];
};

export const useDeleteBankAccount = () => {
  return useMutation({
    mutationFn: ({ accountIds }: { accountIds: string[] }) => {
      return http.post<Request>("/building/account/delete", {
        account_ids: accountIds,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/account/list"]);
    },
  });
};
