import { Button, useDisclosure, UseDisclosureProps, useToast } from "@chakra-ui/react";
import CommonDrawerModal from "../../../../components/Drawer";
import styled from "@emotion/styled";
import { Text, DrawerBody, Flex } from "@chakra-ui/react";
import { DetailsInfo, InputWithTextBox } from "../../../../components/styled/webApp";
import { colors } from "../../../../components/styled/common";
import ResponsiveConfirm from "../../../../components/modal/responsive/confirm";
import RespCancelModal from "../../../../components/modal/responsive/cancel";
import { warning } from "../../../../lib/theme/toast";
import { ChangeEvent, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { queryClient } from "../../../..";
import { tenant_point_history_key, tenant_point_key } from "../../../../lib/api/queries/keys";
import { tenant_point_recharge } from "../../../../lib/api/queries/urls";
import { useUpdatePointDirectly } from "../../../../requests/point/useUpdatePointDirectly";

const UpdatePointBox = styled.div`
  width: 100%;
  padding: 1rem;
  border: 1px solid ${colors.gray2};
  h6 {
    font-weight: 500;
    color: ${colors.gray5};
  }
`;

const PointInputBox = styled(InputWithTextBox)`
  margin-right: 0;
  margin: 0.75rem 0;
  input {
    width: 100%;
    text-align: right;
    margin-right: 4px;
    ::placeholder {
      color: ${colors.gray2};
    }
  }
`;

interface IChangedPoint extends UseDisclosureProps {
  name: string;
  point: number;
  id: string;
}

export default function ChangedPointDirectly(props: IChangedPoint) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //충전
  const { isOpen: isDedu, onOpen: onDedu, onClose: offDedu } = useDisclosure(); //차감
  const [amount, setAmount] = useState<string>();

  const onCloseDrawer = () => {
    props.onClose && props.onClose();
    return;
  };

  const { mutateAsync: updatePointDirectly, isLoading } = useUpdatePointDirectly();

  const onChangePoints = (e: ChangeEvent<HTMLInputElement>) => {
    const test = /^([-]?[0-9]\d*)$/;
    const empty = /^$/;
    if (e.target.value === "-" || e.target.value === "0-") return setAmount("-");
    if (!test.test(e.target.value) && !empty.test(e.target.value)) return;
    setAmount(String(Number(e.target.value)));
  };

  const onCheckBeforCharge = () => {
    const point = Number(amount);
    if (amount === undefined || !point)
      return toast({
        ...warning,
        title: "알림",
        description: "충전 포인트를 정확히 입력해주세요.",
      });
    if (Math.sign(Number(amount)) === -1)
      return toast({
        ...warning,
        title: "알림",
        description: "충전 포인트를 정확히 입력해주세요. 포인트를 차감하시려면 차감 버튼을 눌러주세요.",
      });
    onOpen();
  };

  const onCheckBeforDeduct = () => {
    const point = Number(amount);
    if (amount === undefined || !point)
      return toast({
        ...warning,
        title: "알림",
        description: "차감 포인트를 정확히 입력해주세요.",
      });
    if (!props.point)
      return toast({
        ...warning,
        title: "알림",
        description: "현재 입주사의 포인트가 없습니다. 포인트를 충전하시려면 충전 버튼을 눌러주세요.",
      });
    if (Math.sign(props.point - (amount.includes("-") ? point * -1 : point)) === -1)
      return toast({
        ...warning,
        title: "알림",
        description: "현재 입주사의 포인트보다 차감 포인트가 더 큽니다. 차감 포인트를 정확히 입력해주세요.",
      });
    onDedu();
  };

  return (
    <CommonDrawerModal title="수동 충전/차감" isOpen={props.isOpen || false} onClose={onCloseDrawer}>
      <DrawerBody>
        <DetailsInfo>
          <h6>충전대상</h6>
          <div>{props.name}</div>
        </DetailsInfo>
        <DetailsInfo style={{ borderBottom: "none" }}>
          <h6>보유포인트</h6>
          <div>{props.point}P</div>
        </DetailsInfo>
        <UpdatePointBox>
          <h6>수동 충전/차감</h6>
          <PointInputBox>
            금액
            <input
              placeholder="1000"
              // onChange={(e) => onLimitNum(e, setAmount)}
              value={amount === undefined ? "" : amount}
              onChange={onChangePoints}
            />
            P
          </PointInputBox>
          <Flex>
            <Button w="100%" variant="m_primary" mr="0.5rem" onClick={onCheckBeforCharge}>
              + 충전
            </Button>
            <Button w="100%" variant="m_light_danger" onClick={onCheckBeforDeduct}>
              - 차감
            </Button>
          </Flex>
        </UpdatePointBox>
        {isOpen && (
          <ResponsiveConfirm
            title="포인트 충전"
            isOpen={isOpen}
            before="취소"
            next="충전하기"
            onClose={onClose}
            onAction={() => {
              if (isLoading) return;
              updatePointDirectly({
                tenant_id: props.id,
                amount: amount || "0",
              }).then(() => {
                onClose();
                onCloseDrawer();
              });
            }}
          >
            <Text>
              {props.name}에 {amount}P를 충전할까요?
            </Text>
          </ResponsiveConfirm>
        )}
        {isDedu && (
          <RespCancelModal
            title="포인트 차감"
            isOpen={isDedu}
            next="차감하기"
            onClose={offDedu}
            onAction={() => {
              if (isLoading) return;
              updatePointDirectly({
                tenant_id: props.id,
                amount: (Math.sign(Number(amount)) === -1 ? Number(amount) : Number(amount) * -1) || "0",
              }).then(() => {
                offDedu();
                onCloseDrawer();
              });
            }}
          >
            <Text>
              {props.name}에 {amount}P를 차감할까요?
            </Text>
          </RespCancelModal>
        )}
      </DrawerBody>
    </CommonDrawerModal>
  );
}
