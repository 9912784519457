import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type Request = {
  companyId: string;
  memberId: string;
};
export const useInviteCompanyMember = () => {
  return useMutation({
    mutationFn: ({ companyId, memberId }: Request) => {
      return httpV2.post(`/companies/${companyId}/members/${memberId}/invite`);
    },
    onSuccess: (_, { companyId }) => {
      queryClient.invalidateQueries([`/companies/${companyId}`]);
    },
  });
};
