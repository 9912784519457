import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  date: string;
};

type Response = {
  data: {
    _id: string;
    date: Date;
    user_name: string;
    updated_date: Date;
    is_open: boolean;
    sum_of_management_fee?: number;
  }[];
};

export const useGetMaintenanceFeeList = ({ date }: { date: Date }, enabled?: boolean) => {
  return useQuery({
    queryKey: ["building/management_fee/list", date],
    queryFn: () =>
      http.get<Response, Request>("/building/management_fee/list", {
        date: `${date.getFullYear()}-01-01`,
      }),
    enabled,
  });
};
