import { useMutation } from "@tanstack/react-query";
import { http } from "../../lib/http";
import { queryClient } from "../..";

type Request = {
  category: string;
  question: string;
  answer: string;
  images: string[];
};

export const useCreateResource = () => {
  return useMutation({
    mutationFn: ({ category, question, answer, images }: Request) => {
      return http.post("/building/faq/create", {
        category,
        question,
        answer,
        images,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["faq/list"]);
    },
  });
};
