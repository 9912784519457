import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";

type Request = {
  buildingId: string;
  permission: number[];
};

type Response = {
  id: string;
  result: boolean;
}[];

export const useInviteCompanyAllMembers = () => {
  return useMutation({
    mutationFn: ({ buildingId, permission }: Request) => {
      return httpV2.post<Request, Response>(`/companies/members/invite`, { buildingId, permission });
    },
  });
};
