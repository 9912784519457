import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Response = {
  data: {
    tenant_id: string;
    tenant_name: string;
    price?: number;
    point?: number;
    dong_ho: {
      _id: string;
      dong: string;
      ho: string;
    }[];
  }[];
};

export const useGetRentRoomList = () => {
  return useQuery({
    queryKey: ["tenant/rooms"],
    queryFn: () => http.get<Response>("/building/tenant/rooms"),
  });
};
