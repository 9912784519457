import { useMutation } from "@tanstack/react-query";
import { http } from "../../lib/http";
import { queryClient } from "../..";

type Request = {
  faq_id: string;
  category: string;
  question: string;
  answer: string;
  images: string[];
};

export const useUpdateResource = () => {
  return useMutation({
    mutationFn: ({ faq_id, category, question, answer, images }: Request) => {
      return http.post("/building/faq/update", {
        faq_id,
        category,
        question,
        answer,
        images,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["faq/list"]);
    },
  });
};
