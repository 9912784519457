import moment from "moment";
import { format_process } from "../commons/format";
import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Response = {
  _id: string;
  complaint_type: string;
  progress_type: string;
  dong: string;
  floor: string;
  title: string;
  body?: string;
  is_new?: boolean;
  is_new_comment?: boolean;
  companyInfo?: {
    name: string;
    members: {
      _id: string;
      user_id?: string;
      permission?: 1 | 2 | 3;
      name?: string;
      phone_number?: string;
    }[];
    id: string;
    tenantType: "office" | "store" | "residential" | "etc";
  }[];
  createdAt: Date;
};

export default function RequestListItem(props: { el: Response }) {
  const navigate = useNavigate();
  const [isAbsolute, setIsAbsolute] = useState(false); //true => 반응형
  const textWidth = (props.el.title || "").length * 16;

  const onClickDetailComplaint = () => {
    if (!props.el) return;

    navigate(`/complaints/${props.el._id}`);
  };

  const handleWidth = debounce(() => {
    const smaller = document.querySelector("body")?.clientWidth;
    const titleWidth = document.getElementById("title")?.clientWidth || 405;

    if (textWidth >= titleWidth) {
      setIsAbsolute(true);
    } else {
      if (smaller) return setIsAbsolute(false);
      //text < title
      if (textWidth + 48 >= titleWidth) return setIsAbsolute(true);
      setIsAbsolute(false);
    }
    // textWidth + 48 >= titleWidth ? setIsAbsolute(true) : setIsAbsolute(false);
  }, 800);

  useEffect(() => {
    handleWidth();
    window.addEventListener("resize", () => handleWidth());
    return () => {
      window.removeEventListener("resize", () => handleWidth());
    };
  }, []);

  return (
    <div
      onClick={onClickDetailComplaint}
      className="flex w-full cursor-pointer items-center justify-between border-b border-gray-400 px-4 py-3 transition-all hover:bg-blue-50"
    >
      <div className="hidden w-[10%] pr-2 md:block">{props.el.complaint_type}</div>
      <div id="title" className="flex w-3/4 items-center pr-2 md:w-1/5">
        <span className="truncate">{props.el.body?.slice(0, 50)}</span>
        <span className={twMerge("flex", isAbsolute && "hidden")}>
          {props.el.is_new && (
            <span className="ml-2 mr-1 flex h-6 bg-[#FB4667] px-2">
              <p className="text-xs font-semibold leading-6 text-white">새민원</p>
            </span>
          )}
          {props.el.is_new_comment && (
            <span className="ml-2 mr-1 flex h-6 bg-[#00A8FF] px-2">
              <p className="text-xs font-semibold leading-6 text-white">새답글</p>
            </span>
          )}
        </span>
      </div>
      <div className="hidden w-[15%] pr-2 md:block">
        {props.el.dong ? `${props.el.dong}동 ` : ""}
        {props.el.floor ? `${props.el.floor}층` : ""}
      </div>
      <div className="hidden w-[15%] truncate pr-2 md:block">
        {(props.el.companyInfo?.length ?? 0) > 1
          ? `${props.el.companyInfo?.[0]?.name} 외 ${(props.el.companyInfo?.length ?? 0) - 1}`
          : props.el.companyInfo?.[0]?.name ?? "-"}
      </div>
      <div className="hidden w-[15%] pr-2 md:block">{moment.utc(props.el.createdAt).format("YYYY-MM-DD HH:mm")}</div>
      <div className="min-w-[10%] pr-2" style={{ textAlign: "center" }}>
        {format_process(props.el.progress_type)}
      </div>
    </div>
  );
}
