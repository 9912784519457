import Icon from "../../../types/iconsax";
import Input from "../../../components/Input/Input";
import CustomModal from "../../../components/CustomModal";
import CustomRadioGroup from "../../../components/CustomRadioGroup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateCompanyMemberMutation } from "../../../requests/company/useCreateCompanyMemberMutation";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import CustomCheckBox from "../../../components/CustomCheckBox";
import { useInviteCompanyMember } from "../../../requests/company/useInviteCompanyMember";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  target: "master" | "member";
  companyId: string;
  companyName: string;
};

type FormData = {
  name: string;
  phoneNumber: string;
};

export default function CompanyAddMemberModal({ isOpen, onClose, target, companyId, companyName }: Props) {
  const [permission, setPermission] = useState<1 | 2 | 3>(target === "master" ? 3 : 1);
  const [isInviteMember, setIsInviteMember] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
  });

  const { mutateAsync: createCompanyMember } = useCreateCompanyMemberMutation();
  const { mutateAsync: inviteCompanyMember } = useInviteCompanyMember();

  const onSubmit = (data: FormData) => {
    toast.promise(
      createCompanyMember({
        companyId,
        ...data,
        permission,
      }),
      {
        success: (res) => {
          onClose();

          if (isInviteMember) {
            toast.promise(
              inviteCompanyMember({
                companyId,
                memberId: res.data.id,
              }),
              {
                loading: "Loading...",
                success: () => <CustomToast iconType="check" title="가입 초대 알림톡이 전송되었습니다." />,
                error: () => (
                  <CustomToast
                    iconType="error"
                    title="가입 초대 알림톡 전송 실패"
                    description="잠시 후 다시 시도해주세요."
                  />
                ),
              },
            );
          }
          return (
            <CustomToast
              iconType="check"
              title={
                target === "master"
                  ? `'${companyName}'의 최고관리자가 등록되었습니다.`
                  : `'${companyName}'의 구성원이 추가되었습니다.`
              }
            />
          );
        },
        error: (err) => {
          onClose();
          if (err.response.status === 409) return <CustomToast iconType="error" title={"이미 등록된 구성원입니다."} />;
          return (
            <CustomToast
              iconType="error"
              title={`${target === "master" ? "최고관리자 등록" : "구성원 추가"} 처리 중 에러가 발생하였습니다.`}
            />
          );
        },
        loading: <CustomToast iconType="loading" title="Loading" />,
      },
    );
  };

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={target === "master" ? "최고관리자 등록" : "구성원 추가"}
      required={true}
      exit={true}
      onSubmit={handleSubmit(onSubmit)}
      primaryButtonText="등록하기"
      primaryButtonDisable={Object.keys(errors).length > 0}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[488px] flex-col gap-4 p-6 pt-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">이름</span>
          </div>
          <Input
            themeSize="md"
            placeholder="이름을 입력해주세요"
            {...register("name")}
            onBlur={(e) => setValue("name", e.target.value.trim())}
          />
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">연락처</span>
            <span className="text-blue-600">*</span>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Input
                themeSize="md"
                placeholder={`'-'없이 연락처를 입력해주세요`}
                {...register("phoneNumber", {
                  required: "연락처는 필수 입력 사항입니다.",
                  pattern: { value: /^[0-9]+$/, message: "연락처는 숫자만 입력 가능합니다." },
                })}
                onBlur={(e) => setValue("phoneNumber", e.target.value.trim())}
                type="number"
              />
              {errors.phoneNumber && (
                <p className="text-xs leading-normal text-red-500">{errors.phoneNumber.message}</p>
              )}
            </div>
            {target === "master" && (
              <div className="flex gap-2 text-gray-500">
                <Icon.InfoCircle size={20} />
                <span className="text-sm font-medium">
                  동일한 연락처로 인증하여 가입한 입주자에게 권한이 제안되며, 입주자가 승인해야 권한이 정상적으로
                  부여됩니다.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">권한</span>
            <span className="text-blue-600">*</span>
          </div>
          <CustomRadioGroup
            data={[
              { name: "최고관리자", value: "3", disabled: target !== "master" },
              { name: "관리멤버", value: "2" },
              { name: "일반멤버", value: "1" },
            ]}
            getValue={permission.toString()}
            setState={(value) => setPermission(Number(value) as 1 | 2 | 3)}
            className="flex gap-10 p-3"
          />
        </div>
        <div className="flex flex-col gap-3">
          <p className="gap-1 font-semibold text-gray-700">가입 초대 알림톡</p>
          <div className="flex items-center rounded-xl border border-gray-100 bg-gray-50 p-3">
            <CustomCheckBox
              size="md"
              enabled={isInviteMember}
              setEnabled={setIsInviteMember}
              label="가입 초대 알림톡(카카오톡)을 자동으로 전송합니다."
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
