import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useState, MouseEvent } from "react";
import { colors } from "../../../components/styled/common";
import * as P from "../../../components/styled/webApp";
import { DateWithIconBox, FixedTitle, LeftIcons } from "../../../components/styled";
import { ColumnActive } from "../commons/styled";
import { SubFrameContainer, HeaderBox } from "../../../components/Main/subframePart";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import styled from "@emotion/styled";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import ChangedPointDirectly from "./_view/updatePointDirectly";
import UpdateMonthlyPoint from "./_view/updateMonthlyPoint";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { tenant_point, tenant_point_history } from "../../../lib/api/queries/urls";
import { tenant_point_history_key, tenant_point_key } from "../../../lib/api/queries/keys";
import { useQueries } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { ITenantPoint, ITenantPointHistory } from "../../../lib/types/Imodels";
import { AxiosError } from "axios";
import { warning } from "../../../lib/theme/toast";
import { format_payment_type } from "../../../lib/utils/dataFormat";
import SkeletonTable from "../../../components/Skeleton/table";
import { useFacilityList } from "../../../lib/api/queries/commons";
import GrayColorSelect from "../../../components/select";
import GrayRadiusWithIcon from "../../../components/Input/search";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { useGetPointDetail } from "../../../requests/point/useGetPointDetail";
import { useGetPointHistoryList } from "../../../requests/point/useGetPointHistoryList";

const PointHeader = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid ${colors.gray2};
`;

const PointContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  font-weight: 500;
  color: ${colors.gray5};
  span {
    margin-left: 1rem;
    color: ${colors.mainBlue};
  }
  :last-of-type {
    border-left: 1px solid ${colors.gray2};
  }
`;

const NoneTenant = styled(PointContent)`
  padding: 1.5rem;
  color: ${colors.red1};
  :last-of-type {
    border-left: none;
  }
`;

export default function PointHistoryDetailPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const [searchParams] = useSearchParams();
  const [date, setDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const { data: public_room } = useFacilityList();
  const room_list = public_room?.filter((el) => el.room_public_type !== "회의실");
  const [publicList, setPublicList] = useState("");
  const [status, setStatus] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure(); //포인트 충전
  const { isOpen: monthly, onOpen: onMonth, onClose: offMonth } = useDisclosure(); //매월 포인트 수정

  const auth = useUserAuth("room_public");
  const isEdit = auth === "edit";

  const onChangeMonth = (e: MouseEvent<SVGElement>, str: string) => {
    e.stopPropagation();
    setDate(
      new Date(
        moment(date)
          .add(str === "prev" ? -1 : 1, "month")
          .format("YYYY-MM-01"),
      ),
    );
  };

  const { data } = useGetPointDetail({ tenant_id: tenantId || "" }, !!tenantId);
  const { data: list, refetch } = useGetPointHistoryList(
    {
      tenant_id: tenantId || "",
      date: date,
      search,
      room_public_type: publicList,
      payment_type: status,
    },
    !!tenantId,
  );

  return (
    <SubFrameContainer>
      <HeaderBox style={{ justifyContent: "flex-start" }}>
        <LeftIcons onClick={() => navigate(-1)} />
        <p>{searchParams.get("tenant")}</p>
      </HeaderBox>
      <PointHeader>
        {JSON.parse(searchParams.get("isActive") ?? "false") ? (
          <>
            <PointContent>
              <div>
                현재 보유 <span>{data?.data.point || 0}P</span>
              </div>
              {isEdit && (
                <Button variant="bgBlue" onClick={onOpen}>
                  충전/차감
                </Button>
              )}
            </PointContent>
            {isOpen && (
              <ChangedPointDirectly
                id={tenantId || ""}
                point={data?.data.point || 0}
                name={searchParams.get("tenant") || ""}
                isOpen={isOpen}
                onClose={onClose}
              />
            )}
            <PointContent>
              <div>
                매월 1일 자동지급 <span>{data?.data.point_recharge || 0}P</span>
              </div>
              {isEdit && (
                <P.UpdateIconBox style={{ fontSize: "0.875rem" }} onClick={onMonth}>
                  수정하기
                  <FiChevronRight />
                </P.UpdateIconBox>
              )}
            </PointContent>
            {monthly && <UpdateMonthlyPoint isOpen={monthly} onClose={offMonth} id={tenantId || ""} />}
          </>
        ) : (
          <NoneTenant>현재 퇴실한 입주사입니다.</NoneTenant>
        )}
      </PointHeader>
      <P.MainContainer>
        <P.FliterBox style={{ display: "flex" }}>
          <DateWithIconBox>
            <DatePicker
              locale={ko}
              selected={date}
              dateFormat="yyyy. MM."
              showMonthYearPicker
              onChange={(date: Date) => setDate(date)}
              customInput={
                <P.MouseShadowBox>
                  <FiChevronLeft onClick={(e) => onChangeMonth(e, "prev")} />
                  <input
                    readOnly={true}
                    style={{ textAlign: "center", fontWeight: 500 }}
                    value={date ? moment(date).format("YYYY. MM.") : ""}
                  />
                  <FiChevronRight onClick={(e) => onChangeMonth(e, "next")} />
                </P.MouseShadowBox>
              }
            />
          </DateWithIconBox>
          <GrayRadiusWithIcon
            _style={{ minWidth: "17.5rem" }}
            value={search}
            placeholder="이용자명, 전화번호로 검색가능"
            _onChange={(e) => setSearch(e.target.value)}
            _onKeyUp={(e) => e.key === "Enter" && refetch()}
            _onClick={() => refetch()}
          />
        </P.FliterBox>
        <P.FliterBox style={{ display: "flex" }}>
          <GrayColorSelect
            value={publicList}
            _style={{ textAlign: "left" }}
            _onChange={(e) => setPublicList(e.target.value)}
          >
            <option value="">공용시설</option>
            {room_list &&
              room_list.map((el) => (
                <option key={el.room_public_type} value={el.room_public_type}>
                  {el.room_public_type}
                </option>
              ))}
          </GrayColorSelect>
          <GrayColorSelect value={status} _style={{ textAlign: "left" }} _onChange={(e) => setStatus(e.target.value)}>
            <option value="">상태</option>
            <option value="recharge">충전</option>
            <option value="deduct">차감</option>
            <option value="pay">결제완료</option>
            <option value="refund">환불완료</option>
          </GrayColorSelect>
        </P.FliterBox>
        <P.TableWrapper>
          <P.TableHeaderWrapper>
            <div>이용자 / 소속</div>
            <div style={{ minWidth: "10%" }}>금액</div>
            <div style={{ minWidth: "10%" }}>상태</div>
            <div>결제일시</div>
            <div style={{ width: "50%" }}>결제내용</div>
          </P.TableHeaderWrapper>
          {!list ? (
            <SkeletonTable />
          ) : (
            <>
              {list.data.map((el) => (
                <P.CommonTableWrap key={String(el._id)} style={{ cursor: "default" }}>
                  <FixedTitle>
                    {el.user_name}
                    {el.tenant_name === "" ? "" : ` / ${el.tenant_name}`}
                  </FixedTitle>
                  <ColumnActive style={{ minWidth: "10%" }} isActive={Math.sign(el.amount) !== -1}>
                    {Math.sign(el.amount) === -1 ? `- ${el.amount * -1}P` : `+ ${el.amount}P`}
                  </ColumnActive>
                  <div style={{ minWidth: "10%" }}>{format_payment_type(el.payment_type)}</div>
                  <div>{moment.utc(el.date).format("YYYY-MM-DD HH:mm")}</div>
                  <div style={{ width: "50%" }}>{el.description}</div>
                </P.CommonTableWrap>
              ))}
            </>
          )}
        </P.TableWrapper>
      </P.MainContainer>
    </SubFrameContainer>
  );
}
