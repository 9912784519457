import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  desired_date: string;
  management_fee_files: string[];
  file_original_name: string;
  management_fee_id: string;
};

export const useUpdateMaintenanceFee = () => {
  return useMutation({
    mutationFn: ({ desired_date, management_fee_files, file_original_name, management_fee_id }: Request) => {
      return http.post("/building/management_fee/update_batch", {
        desired_date,
        management_fee_files,
        file_original_name,
        management_fee_id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/management_fee_detail/list"]);
    },
  });
};
