import { Checkbox, Modal, ModalContent, ModalOverlay, Select, useDisclosure, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { success, warning } from "../../../lib/theme/toast";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import useAccessBank from "../../maintenance-fee/bank/access";
import { useAccountList, useBankAccount } from "../../maintenance-fee/commons/queries";
import CancleModal from "../../../components/modal/CancelModal";
import MoreContentInputs from "../../../components/Input/contents";
import { v4 as uuidv4 } from "uuid";
import { GlobalContext } from "../../../App";
import { useUpdatePaymentDate } from "../../../requests/bank/useUpdatePaymentDate";
import { Payment_date } from "../../../lib/utils/dataFormat";
import { useCreateBankAccount } from "../../../requests/bank/useCreateBankAccount";
import { useDeleteBankAccount } from "../../../requests/bank/useDeleteBankAccount";

export default function RentFeeBankPage() {
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure(); //계좌 등록 모달
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure(); //계좌 삭제 모달
  const { isOpen: isDateModalOpen, onOpen: onDateModalOpen, onClose: onDateModalClose } = useDisclosure(); //납부일 등록 모달
  const [bankId, setBankId] = useState("");
  const [account, setAccount] = useState("");
  const [owner, setOwner] = useState("");
  const [checkedArr, setCheckedArr] = useState<string[]>([]);
  const toast = useToast();

  //데이터 관련
  const { data } = useAccountList();
  const { data: bank } = useBankAccount(isCreateModalOpen);
  const { buildInfo } = useContext(GlobalContext);
  const [date, setDate] = useState<number>();

  const { mutateAsync: createBankAccount } = useCreateBankAccount();
  const { mutateAsync: deleteBankAccount } = useDeleteBankAccount();
  const { mutateAsync: updatePaymentDate } = useUpdatePaymentDate();

  const auth = useAccessBank(["management_fee", "rent_fee"]);
  const isEdit = auth === "edit";

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      data?.forEach((el) => allCheckedArr.push(String(el._id)));
      setCheckedArr(allCheckedArr);
    } else {
      setCheckedArr([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedArr([...checkedArr, id]);
    } else {
      const newArr = checkedArr.filter((checked) => checked !== id);
      setCheckedArr(newArr);
    }
    if (checkedArr.length === data?.length) {
      return (checked = true);
    }
  };

  const onReset = () => {
    setBankId("");
    setAccount("");
    setOwner("");
    onCreateModalClose();
  };

  useEffect(() => {
    if (isDateModalOpen) {
      //기존 등록된 납부일 보여주어야함
      if (buildInfo?.payment_date === undefined) return;
      setDate(buildInfo.payment_date);
    } else {
      setDate(undefined);
    }
  }, [isDateModalOpen]);

  if (auth === undefined) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <div className="flex min-h-full w-full flex-col bg-white p-0 md:px-6">
      <p className="mt-5">
        임대료를 수납하는 은행의 계좌 관리 페이지입니다. 입주사는 앱을 통해 아래 계좌를 확인할 수 있습니다.
      </p>
      {isEdit && (
        <div className="flex gap-2 pt-5">
          <button className="bg-blue-600 px-3 py-2 text-white" onClick={onCreateModalOpen}>
            납부계좌 등록
          </button>
          <button className="border border-blue-600 bg-white px-3 py-2 text-blue-600" onClick={onDateModalOpen}>
            납부일 등록
          </button>
        </div>
      )}
      <div className="pt-6">
        <div className="flex items-center justify-between pb-2">
          <p className="pb-2 text-base">
            총 <span className="font-medium text-blue-600">{data?.length || 0}건</span>의 등록내역이 조회되었습니다.
          </p>
          {isEdit && (
            <>
              <button className="border border-red-500 bg-white px-3 py-2 text-red-500" onClick={onDeleteModalOpen}>
                삭제
              </button>
              <CancleModal
                onClose={onDeleteModalClose}
                isOpen={isDeleteModalOpen}
                onClickCancel={onDeleteModalClose}
                onClickSave={async () => {
                  const isDeleteBankAccountSuccess = await deleteBankAccount({ accountIds: checkedArr });

                  isDeleteBankAccountSuccess &&
                    toast({
                      ...success,
                      title: "납부 계좌 삭제 완료",
                      description: "납부 계좌가 삭제되었습니다.",
                    });
                  setCheckedArr([]);

                  onDeleteModalClose();
                }}
              >
                <p>체크하신 계좌 정보를 삭제할까요?</p>
                <p>삭제된 데이터는 복구할 수 없습니다.</p>
              </CancleModal>
            </>
          )}
        </div>
        <div className="flex flex-col border-t border-gray-500 text-base">
          <div className="flex min-h-12 w-full items-center border-b border-gray-500 bg-gray-100 px-2.5 text-center">
            <div className="flex w-[5%] items-center justify-center">
              <Checkbox
                variant="default"
                isChecked={checkedArr.length === data?.length ? true : false}
                onChange={(e) => onCheckedAll(e.target.checked)}
              />
            </div>
            <div className="w-1/4">NO</div>
            <div className="w-1/4">은행</div>
            <div className="w-1/4">계좌번호</div>
            <div className="w-1/4">예금주명</div>
          </div>
          {data?.map((el, idx) => (
            <>
              <div className="flex min-h-12 w-full items-center border-b border-gray-600 bg-white px-2.5 text-center">
                <div className="flex w-[5%] items-center justify-center">
                  <Checkbox
                    variant="default"
                    isChecked={checkedArr.includes(String(el._id)) ? true : false}
                    onChange={(e) => onChangeChecked(e.currentTarget.checked, String(el._id))}
                  />
                </div>
                <div className="w-1/4">{idx + 1}</div>
                <div className="w-1/4">{el?.bank_id?.name}</div>
                <div className="w-1/4">{el?.account_number}</div>
                <div className="w-1/4">{el?.account_name}</div>
              </div>
            </>
          ))}
        </div>
      </div>
      <Modal variant="contents" isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
        <ModalOverlay />
        <ModalContent className="min-w-fit">
          <header className="border-b border-gray-400 px-6 py-5 text-2xl">납부계좌 등록</header>
          <div className="px-7">
            <table>
              <tbody>
                <tr className="border-b border-gray-300">
                  <td className="min-w-40 px-0 py-2 font-medium">은행</td>
                  <td className="px-0 py-2">
                    <Select w="180px" value={bankId} variant="fitContent" onChange={(e) => setBankId(e.target.value)}>
                      <option value="">은행 선택</option>
                      {bank?.map((el) => (
                        <option key={String(el._id)} value={String(el._id)}>
                          {el.name}
                        </option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="min-w-40 px-0 py-2 font-medium">계좌번호</td>
                  <td className="px-0 py-2">
                    <MoreContentInputs
                      placeholder="'-'없이 숫자만 입력"
                      style={{ width: "368px", marginRight: "0" }}
                      onChange={(e) => setAccount(e.target.value)}
                    />
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <td className="min-w-40 px-0 py-2 font-medium">예금주명</td>
                  <td className="px-0 py-2">
                    <MoreContentInputs
                      style={{ marginRight: "0" }}
                      placeholder="예금주명을 확인하여 입력해주세요"
                      marginRight={0}
                      onChange={(e) => setOwner(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <footer className="flex justify-end gap-2 px-6 pb-9 pt-5">
            <button onClick={onReset} className="border border-gray-400 px-3 py-2 text-gray-500">
              취소
            </button>
            <button
              onClick={async () => {
                if (!bankId || !account || !owner)
                  return toast({
                    ...warning,
                    title: "계좌 등록 오류",
                    description: "계좌 등록을 위해 빈 칸을 모두 입력해주세요.",
                  });

                const isCreateBankAccountSuccess = await createBankAccount({
                  bankId,
                  accountNumber: account,
                  accountName: owner,
                });

                isCreateBankAccountSuccess &&
                  toast({
                    ...success,
                    title: "납부 계좌 등록 완료",
                    description: "납부 계좌가 등록되었습니다.",
                  });
                onReset();
              }}
              className="border border-blue-600 bg-blue-600 px-3 py-2 text-white"
            >
              저장하기
            </button>
          </footer>
        </ModalContent>
      </Modal>
      <Modal variant="contents" isOpen={isDateModalOpen} onClose={onDateModalClose}>
        <ModalOverlay />
        <ModalContent className="min-w-fit">
          <header className="w-[584px] border-b border-gray-400 px-6 py-5 text-2xl">납부일 등록</header>
          <div className="px-7 py-2">
            <div className="flex items-center border-b border-gray-300 py-2">
              <div className="w-40 font-medium">납부일</div>
              <Select w="180px" value={date} variant="fitContent" onChange={(e) => setDate(Number(e.target.value))}>
                <option value="">날짜 선택</option>
                {Payment_date.map((el) => (
                  <option key={uuidv4()} value={el}>
                    {el}일
                  </option>
                ))}
                <option value={32}>말일</option>
              </Select>
            </div>
          </div>

          <footer className="mt-[132px] flex justify-end gap-2 px-6 pb-9 pt-5">
            <button onClick={onDateModalClose} className="border border-gray-400 px-3 py-2 text-gray-500">
              취소
            </button>
            <button
              onClick={async () => {
                if (date === buildInfo?.payment_date)
                  return toast({
                    ...warning,
                    title: "알림",
                    description: "관리비 납부일이 현재 설정된 날짜와 동일합니다.",
                  });
                // return toast(
                //   <CustomToast
                //     iconType="warning"
                //     title="알림"
                //     description="관리비 납부일이 현재 설정된 날짜와 동일합니다."
                //   />,
                // );

                if (date === undefined) return;

                const isUpdatePaymentDateSuccess = await updatePaymentDate({ paymentDate: date });

                isUpdatePaymentDateSuccess &&
                  toast({
                    ...success,
                    title: "관리비 납부일 수정 완료",
                    description: "관리비 납부일이 수정되었습니다.",
                  });

                // toast.promise(updatePaymentDate({ paymentDate: date }), {
                //   success: (
                //     <CustomToast iconType="check" title="납부일 수정 완료" description="납부일이 변경되었습니다." />
                //   ),
                //   error: (
                //     <CustomToast iconType="error" title="납부일 수정 오류" description="납부일 수정에 실패했습니다." />
                //   ),
                //   loading: <CustomToast iconType="loading" title="납부일 수정 중" description="잠시만 기다려주세요." />,
                // });

                onDateModalClose();
              }}
              className="border border-blue-600 bg-blue-600 px-3 py-2 text-white"
            >
              저장하기
            </button>
          </footer>
        </ModalContent>
      </Modal>
    </div>
  );
}
