import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";

type Request = {
  fcmToken: string;
};

export const useDeleteFcmToken = () => {
  return useMutation({
    mutationFn: ({ fcmToken }: Request) => {
      return httpV2.del("/users/me/fcm", {
        fcmToken,
      });
    },
  });
};
