import { useMutation } from "@tanstack/react-query";
import { http } from "../../lib/http";
import { queryClient } from "../..";

type Request = {
  management_fee_id: string;
};

export const useUpdateMaintenanceFeeNotify = () => {
  return useMutation({
    mutationFn: ({ management_fee_id }: Request) => {
      return http.post("/building/management_fee/notify", { management_fee_id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/management_fee/list"]);
    },
  });
};
