import { useMutation } from "@tanstack/react-query";
import { http } from "../../lib/http";
import { queryClient } from "../..";

type Request = {
  faq_id: string;
};

export const useDeleteResource = () => {
  return useMutation({
    mutationFn: ({ faq_id }: Request) => {
      return http.post("/building/faq/delete", {
        faq_id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["faq/list"]);
    },
  });
};
