import CustomModal from "../../../components/CustomModal";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { GlobalContext } from "../../../App";
import { useInviteCompanyAllMembers } from "../../../requests/company/useInviteCompanyAllMembers";
import CustomCheckBox from "../../../components/CustomCheckBox";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function CompanyInviteMemberModal({ isOpen, onClose }: Props) {
  const [isInviteMaster, setIsInviteMaster] = useState<boolean>(true);
  const [isInviteMember, setIsInviteMember] = useState<boolean>(false);

  const { buildInfo } = useContext(GlobalContext);
  const { mutateAsync: inviteCompanyAllMembers } = useInviteCompanyAllMembers();

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="일괄 가입 초대 카카오톡 전송"
      exit={true}
      primaryButtonText="전송하기"
      onPrimaryButtonClick={() => {
        const permissionArr = [];
        if (isInviteMaster) permissionArr.push(3);
        if (isInviteMember) permissionArr.push(2, 1);

        toast.promise(
          inviteCompanyAllMembers({ buildingId: buildInfo?._id.toString() ?? "", permission: permissionArr }),
          {
            loading: "loading...",
            success: (res) => {
              // 적용
              const failCnt = res.data.filter((v) => !v.result).length;
              if (failCnt > 0)
                return (
                  <CustomToast
                    iconType="warning"
                    title={`가입 초대 알림톡 전송 결과 ${res.data.length}개 중 ${res.data.length - failCnt}개 전송 완료 (${failCnt}개 실패)`}
                    descriptionElement={
                      <div className="flex flex-col text-sm">
                        <p>아래와 같은 원인으로 전송실패가 될 수 있어요.</p>
                        <ul className="mb-4 ml-6 list-disc">
                          <li>24시간 이내 발송 기록있는 경우</li>
                          <li>잘못된 전화번호로 등록된 경우</li>
                          <li>그 밖에 알 수 없는 오류</li>
                        </ul>
                        <span>계속해서 실패할 경우 고객센터로 문의해주세요.</span>
                      </div>
                    }
                  />
                );
              return <CustomToast iconType="check" title="가입 초대 알림톡이 전송되었습니다." />;
            },
            error: (
              <CustomToast
                iconType="error"
                title="가입 초대 알림톡 전송 실패"
                description="잠시 후 다시 시도해주세요."
              />
            ),
          },
        );

        onClose();
      }}
      primaryButtonDisable={!(isInviteMaster || isInviteMember)}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex max-w-[488px] flex-col gap-3 px-6 py-4">
        <p className="text-base font-bold text-gray-700">가입 초대 알림톡을 전송 대상을 모두 선택해주세요.</p>

        <div className="flex flex-col gap-2">
          <div className="flex items-center rounded-xl bg-gray-50 p-3">
            <CustomCheckBox
              size="md"
              labelSize="lg"
              enabled={isInviteMaster}
              setEnabled={setIsInviteMaster}
              label="미인증 최고관리자에게 보내기"
            />
          </div>
          <div className="flex items-center rounded-xl bg-gray-50 p-3">
            <CustomCheckBox
              size="md"
              labelSize="lg"
              enabled={isInviteMember}
              setEnabled={setIsInviteMember}
              label="미인증 관리멤버, 일반멤버에게 보내기"
            />
          </div>
        </div>
        <ul className="list-disc pl-6 font-normal text-red-500">
          <li>이미 24시간 이내에 가입 초대 알림톡을 받은 분들은 이번 일괄 전송 대상에서 제외됩니다.</li>
          <li>전송된 문자는 취소가 불가능합니다.</li>
        </ul>
      </div>
    </CustomModal>
  );
}
