import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/custom/Pagination";
import { useCompaniesQuery } from "../../requests/company";
import CustomComboBox from "../../components/CustomComboBox";
import Input from "../../components/Input/Input";
import Button from "../../components/button/Button";
import IconSolar from "../../types/solar-icon";
import { twMerge } from "tailwind-merge";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { useDisclosure } from "@chakra-ui/react";
import { useCallback, useContext, useRef, useState } from "react";
import { GlobalContext } from "../../App";
import { useBuildingFloor } from "../../lib/api/queries/commons";
import CustomListBox from "../../components/CustomListBox";
import { useDebounce } from "../../hooks/operators/use-debounce";
import AllTenantRegisterModal from "../company/components/Modal/AllTenantModal";
import FullPageSpinner from "../../components/Spinner";
import { getSearchRoomText } from "../../utils/getSearchRoom";
import useUserAuth from "../../components/hooks/useUserAuth";
import Icon from "../../types/iconsax";
import { useOverlay } from "@toss/use-overlay";
import CompanyInviteMemberModal from "../../views/company/modal/CompanyInviteMemberModal";
import CustomModal from "../../components/CustomModal";
import toast from "react-hot-toast";
import { useInviteCompanyAllMembers } from "../../requests/company/useInviteCompanyAllMembers";
import CustomToast from "../../components/CustomToast";

export default function CompanyListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { buildInfo } = useContext(GlobalContext);

  const params = new URLSearchParams(search);
  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const searchParamsObject = {
    buildingId: buildInfo?._id.toString() || "",
    searchText: searchParams.get("searchText") || "",
    tenantType: searchParams.get("tenantType") as "office" | "store" | "residential" | "etc",
    dong: searchParams.get("dong") || "",
    floor: searchParams.get("floor") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 10,
  };

  // 전체 조회
  const { data, isLoading } = useCompaniesQuery(searchParamsObject, !!buildInfo?._id);
  const { mutateAsync: inviteCompanyAllMembers } = useInviteCompanyAllMembers();

  const modifiedData = {
    ...data,
    data: data?.data.companies.map((company) => ({
      ...company,
      masterMember: company.members.find((v) => v.permission === 3),
    })),
  };

  const floorInfo = useBuildingFloor();

  // useRoomType은 owners 소유자가 존재해서 만든 함수
  const getRoomType = () => {
    if (buildInfo?.building_type === "knowledge_industry_center")
      return [
        { name: "사무", value: "office" },
        { name: "상가", value: "store" },
        { name: "기숙사", value: "residential" },
        { name: "기타", value: "etc" },
      ];
    if (buildInfo?.building_type === "rental_building")
      return [
        { name: "사무", value: "office" },
        { name: "상가", value: "store" },
      ];
    return [{ name: "사무", value: "office" }];
  };

  const dongs = [
    {
      name: "동",
      value: "",
    },
    ...(buildInfo?.dong_floor?.map((item) => ({
      name: item.dong + "동",
      value: item.dong,
    })) ?? []),
  ];

  const floors = [
    {
      name: "층",
      value: "",
    },
    ...(searchParamsObject.dong === ""
      ? floorInfo.data?.map((item) => ({
          name: item + "층",
          value: item,
        })) ?? []
      : buildInfo?.dong_floor
          .filter((item) => item.dong === searchParamsObject.dong)[0]
          ?.floor.map((floorItem) => ({
            name: floorItem + "층",
            value: floorItem,
          })) ?? []),
  ];

  const roomTypes = [
    {
      name: "호실용도",
      value: "",
    },
    ...getRoomType(),
  ];

  const createQueryString = useCallback(
    (key: string, value: string, deleteKey?: string[]) => {
      value !== "" ? params.set(key, value) : params.delete(key);
      deleteKey && deleteKey.forEach((item) => params.delete(item));
      params.delete("page");
      return params.toString();
    },
    [search],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = useDebounce(
    (text) => {
      navigate(pathname + "?" + createQueryString("searchText", text));
    },
    300,
    [search],
  );

  // 이전 입주사 일괄등록을 위한 임시 데이터
  const { isOpen: isAll, onOpen: AllOpen, onClose: AllClose } = useDisclosure();

  const onOpenCreateAllCompany = () => {
    AllOpen();
  };

  const [page, setPage] = useState(1);

  const overlay = useOverlay();

  // useEffect(() => {
  //   params.delete("page");
  //   navigate(pathname + "?" + params.toString());
  // }, [page]);

  return (
    <div className="mb-[60px] w-full">
      <div className="flex h-[60px] items-center justify-between gap-6 border-y border-gray-200 bg-gray-50 px-6">
        <div className="flex w-[33rem] gap-3">
          <CustomComboBox
            data={dongs}
            getValue={() => searchParamsObject.dong || dongs[0].value}
            onChange={(data) =>
              data !== null && navigate(pathname + "?" + createQueryString("dong", data.value, ["floor"]))
            }
          />
          {/* api에서 층 데이터를 받지 않아 기능 동작 x */}
          <CustomComboBox
            data={floors}
            getValue={() => searchParamsObject.floor || floors[0].value}
            onChange={(data) => data !== null && navigate(pathname + "?" + createQueryString("floor", data.value))}
          />
          <CustomListBox
            data={roomTypes}
            getValue={() => searchParamsObject.tenantType || roomTypes[0].value}
            onChange={(value) => navigate(pathname + "?" + createQueryString("tenantType", value))}
          />
        </div>
        <div className="flex flex-1 items-center justify-end gap-6">
          <div className="flex max-w-[27.75rem] flex-1">
            <Input
              ref={searchInputRef}
              themeSize="sm"
              placeholder="입주사명, 호실, 최고관리자 전화번호"
              defaultValue={searchParamsObject.searchText}
              iconElement={<Icon.SearchNormal1 size={20} color="#D1D5DB" />}
              onChange={(e) => handleTextChange(e.target.value)}
              maxLength={20}
              showDeleteButton={true}
              onDelete={() => {
                if (searchInputRef.current) searchInputRef.current.value = "";
                navigate(pathname + "?" + createQueryString("searchText", ""));
              }}
            />
          </div>
          {isEdit && (
            <div className="flex items-center gap-3">
              <Button
                designSchema="secondaryBlue"
                iconElement={<Icon.Export size={16} />}
                buttonText="입주사 일괄등록"
                size="sm"
                className="h-9"
                // onClick={onUploadOwnerFile}
                onClick={onOpenCreateAllCompany}
              />
              <Button
                designSchema="primary"
                iconElement={<Icon.Add size={16} />}
                buttonText="신규 입주사 등록"
                size="sm"
                onClick={() => navigate("/companies/new")}
              ></Button>
            </div>
          )}
        </div>
      </div>

      {/* 이전 입주사 일괄등록 모달 */}
      <AllTenantRegisterModal setPage={setPage} isOpen={isAll} onClose={AllClose} onOpen={AllOpen} />

      <div className="w-full px-6">
        <table className="w-full table-fixed">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="w-1/5 px-2 py-3 text-left leading-normal">입주사명</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">동 호</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">최고관리자 정보 (인증상태)</th>
            </tr>
          </thead>
          <tbody>
            {(modifiedData.data ?? []).map((item) => {
              return (
                <tr
                  key={item.id}
                  // onClick={() => handleClicked(item.id)}
                  onClick={() => navigate(`/companies/${item.id}/info`)}
                  className={
                    "cursor-pointer border-y border-gray-200 transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
                  }
                >
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.name} ({getRoomType().find((v) => v.value === item.tenantType)?.name})
                  </td>
                  {/* Response의 dong_ho 값이 있는지 확인 */}
                  <td
                    className={twMerge(
                      "px-2 py-3 text-sm leading-normal text-gray-900",
                      !item.roomInfos[0] && "text-gray-400",
                    )}
                  >
                    {item.roomInfos.length > 0
                      ? getSearchRoomText(item.roomInfos, searchParamsObject.searchText) +
                        (item.roomInfos.length > 1 ? " 외 " + (item.roomInfos.length - 1) : "")
                      : "미등록"}
                  </td>
                  {/* Response의 name 값이 있는지 확인 */}
                  <td
                    className={twMerge(
                      "px-2 py-3 text-sm leading-normal text-gray-900",
                      !item.masterMember?.name?.trim() && !item.masterMember?.phoneNumber?.trim() && "text-gray-400",
                    )}
                  >
                    {item.masterMember?.name?.trim() || item.masterMember?.phoneNumber?.trim() ? (
                      <div className="flex items-center gap-2">
                        <span className={twMerge("flex text-green-500", !item.masterMember?.userId && "text-gray-300")}>
                          <Icon.ShieldTick variant="Bold" size={20} />
                        </span>
                        <span>
                          {item.masterMember?.name?.trim() ? item.masterMember?.name?.trim() : "이름없음"} (
                          {item.masterMember?.phoneNumber?.trim()
                            ? formatPhoneNumber(item.masterMember?.phoneNumber?.trim())
                            : "전화번호없음"}
                          )
                        </span>
                      </div>
                    ) : (
                      "미등록"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoading ? (
          <FullPageSpinner />
        ) : (
          <>
            {data?.data.companies.length === 0 ? (
              <div className="flex h-[25rem] flex-col items-center justify-center gap-4 text-gray-700">
                <IconSolar.DangerCircle size={40} />
                {searchParamsObject.searchText === "" &&
                searchParamsObject.dong === "" &&
                searchParamsObject.floor === "" &&
                !searchParamsObject.tenantType ? (
                  <>
                    <span className="text-xl font-medium leading-normal">현재 등록된 입주사가 없습니다.</span>
                    <span className="font-medium leading-normal text-gray-400">입주사를 먼저 등록해주세요.</span>
                  </>
                ) : (
                  <>
                    <span className="text-xl font-medium leading-normal">검색 결과가 없습니다.</span>
                    <span className="font-medium leading-normal text-gray-400">
                      다른 검색어를 시도해 보거나 검색 필터를 삭제하세요.
                    </span>
                  </>
                )}
              </div>
            ) : (
              <div className="mt-3 flex items-center justify-between">
                <Button
                  designSchema="neutral"
                  iconElement={<Icon.Send2 size={16} color="#6B7280" />}
                  buttonText="가입요청보내기"
                  size="sm"
                  onClick={() => {
                    overlay.open(({ isOpen, close }) => <CompanyInviteMemberModal isOpen={isOpen} onClose={close} />);
                  }}
                />
                <div className="flex items-center gap-3">
                  <p className="text-base leading-normal text-gray-500">총 {data?.data.totalCount || 0}개의 결과</p>
                  <Pagination
                    currentPage={searchParamsObject.page}
                    itemCount={data?.data.totalCount || 0}
                    showPageAmount={10}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
