import { http } from "../../lib/http";
import { useQuery } from "@tanstack/react-query";

type Request = {
  tenant_id: string;
};

type Response = {
  data: {
    point: number;
    point_recharge: number;
  };
};

export const useGetPointDetail = ({ tenant_id }: Request, enabled?: boolean) => {
  return useQuery({
    queryKey: ["point/by_tenant"],
    queryFn: () =>
      http.get<Response, Request>("/building/point/by_tenant", {
        tenant_id,
      }),
    enabled,
    retry: false,
  });
};
