import { useContext, useState } from "react";
import * as P from "../../components/styled/webApp";
import { GlobalContext } from "../../App";
import { useTenantType } from "../facility/commons/func";
import SkeletonTable from "../../components/Skeleton/table";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import GrayColorSelect from "../../components/select";
import GrayRadiusWithIcon from "../../components/Input/search";
import QuestionMarkHover from "../../components/hover/question";
import GrayShadowSelect from "../../components/select/shadow";
import { usePagination } from "@ajna/pagination";
import PaginationUI from "../../components/pagination";
import { createSearchParams, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { format_tenant_type } from "../../lib/utils/dataFormat";
import { useGetPointList } from "../../requests/point/useGetPointList";

const PointListTable = styled(P.TableHeaderWrapper)<{ length: number }>`
  > div {
    width: ${({ length }) => (length > 1 ? "20%" : "25%")};
  }
`;

export default function PointHistoryPage() {
  const { buildInfo } = useContext(GlobalContext);
  const tenant_type = useTenantType(buildInfo);
  const navigate = useNavigate();

  const [status, setStatus] = useState("");
  const [dong, setDong] = useState("");
  const [search, setSearch] = useState({ id: "search_name", value: "" });

  // const [total, setTotal] = useState<number>(0);
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const { data, refetch } = useGetPointList({
    room_type: status || "",
    dong: dong,
    search_name: search.value,
    page: currentPage,
  });

  const onClickRefetch = () => {
    currentPage === 1 ? refetch() : setCurrentPage(1);
  };

  return (
    <P.MainContainer style={{ paddingBottom: "1.75rem" }}>
      <P.FliterBox>
        <Flex>
          <GrayShadowSelect value={search.id} _onChange={(e) => setSearch({ id: e.target.value, value: "" })}>
            <option value="search_name">입주사명</option>
            <option value="search_ho">호실번호</option>
          </GrayShadowSelect>
          <GrayRadiusWithIcon
            _style={{ minWidth: "17.5rem" }}
            value={search.value}
            placeholder={`검색할 ${search.id === "search_name" ? "입주사명을" : "호실번호를"}입력하세요.`}
            _onChange={(e) => setSearch({ id: search.id, value: e.target.value })}
            _onKeyUp={(e) => e.key === "Enter" && onClickRefetch()}
            _onClick={() => onClickRefetch()}
          />
        </Flex>
        <Flex pt="1rem">
          {tenant_type.length > 1 && (
            <GrayColorSelect
              value={status}
              _style={{ textAlign: "left" }}
              _onChange={(e) => {
                if (currentPage !== 1) setCurrentPage(1);
                setStatus(e.target.value);
              }}
            >
              <option value="" disabled>
                입주유형
              </option>
              {tenant_type.map((el) => (
                <option key={el.id} value={el.label}>
                  {el.value}
                </option>
              ))}
            </GrayColorSelect>
          )}
          <GrayColorSelect
            value={dong}
            _style={{ textAlign: "left" }}
            _onChange={(e) => {
              if (currentPage !== 1) setCurrentPage(1);
              setDong(e.target.value);
            }}
          >
            <option value="" disabled>
              동
            </option>
            {buildInfo?.dong_floor.map((el) => (
              <option key={el._id} value={el.dong}>
                {el.dong}동
              </option>
            ))}
          </GrayColorSelect>
        </Flex>
      </P.FliterBox>
      <P.TableWrapper>
        <PointListTable length={tenant_type.length}>
          <div>입주사명</div>
          {tenant_type.length > 1 && <div>입주유형</div>}
          <div>동/호수</div>
          <div>잔여포인트</div>
          <Flex alignItems="center">
            입주상태
            <QuestionMarkHover
              isHide={true}
              styled={{ width: "30rem", right: "-7rem" }}
              after="25%"
              description="서비스 가입상태와 별개로 등록된 입주사 현황을 기준으로
                표시합니다."
            />
          </Flex>
        </PointListTable>
        {!data ? (
          <SkeletonTable />
        ) : (
          <>
            {data.data
              .sort((a) => (a.is_activated ? -1 : 1))
              .map((el) => (
                <div
                  key={String(el._id)}
                  className="flex w-full cursor-pointer border-b border-gray-300 bg-white px-4 py-3 text-left transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
                  onClick={() => {
                    navigate({
                      pathname: `/points/${el._id}`,
                      search: `?${createSearchParams({
                        tenant: el.name,
                        isActive: JSON.stringify(el.is_activated),
                      })}`,
                    });
                  }}
                >
                  <div
                    className={twMerge(
                      "relative inline-block w-1/4 overflow-hidden text-ellipsis whitespace-nowrap border-b-0 text-left align-middle",
                      (tenant_type.length ?? 0) > 1 && "w-1/5",
                    )}
                  >
                    {el.name}
                  </div>
                  {tenant_type.length > 1 && <div className="w-1/5">{format_tenant_type(el.tenant_type)}</div>}
                  <div className={twMerge("w-1/4", (tenant_type.length ?? 0) > 1 && "w-1/5")}>{el.dong_ho}</div>
                  <div className={twMerge("w-1/4", (tenant_type.length ?? 0) > 1 && "w-1/5")}>{el.point || 0}P</div>
                  <div className={twMerge("text-[#FB4667]", el.is_activated && "text-blue-600")}>
                    {el.is_activated ? "입주중" : "퇴실"}
                  </div>
                </div>
              ))}
          </>
        )}
      </P.TableWrapper>
      <PaginationUI
        page={currentPage}
        setPage={setCurrentPage}
        onChangeFunc={(page) => setCurrentPage(page)}
        allPage={data?.total_page_num || 1}
      />
    </P.MainContainer>
  );
}
