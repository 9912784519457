import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../App";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { usePagination } from "@ajna/pagination";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import { useDeleteLetter } from "../commons/queries";
import NotFound from "../../not-found";
import { useGetMaintenanceFeeDetailList } from "../../../requests/maintenance-fee/useGetMaintenanceFeeDetailList";

import { Button, Flex, Select, Text, useDisclosure } from "@chakra-ui/react";
import ContentInputs from "../../../components/Input/middleContents";
import * as C from "../../../components/styled/index";
import * as R from "../commons/styled";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import CancelModal from "../../../components/modal/CancelModal";
import UploadCSVModal from "../component/UploadModal";
import PaginationUI from "../../../components/pagination";
import MaintenanceItem from "./_view/MaintenanceItem";

export default function MaintenanceFeeDetailPage() {
  const { buildInfo } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { billId } = useParams();
  const hasFunc = buildInfo?.services.MAINTENANCE_FEE?.enabled;
  const auth = useUserAuth("management_fee");
  const { mutation } = useDeleteLetter(navigate);

  const [dong, setDong] = useState("");
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState({ id: "search_ho", value: "" });
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const { data, isLoading, refetch } = useGetMaintenanceFeeDetailList(
    {
      limit: 10,
      page: currentPage,
      management_fee_id: billId || "",
      upload_type: buildInfo?.management_fee_upload_type || "",
      payment_status: status,
      dong,
      search_ho: inputs.value,
    },
    !!buildInfo && (auth === "read" || auth === "edit"),
  );

  const onRefetch = () => {
    if (currentPage !== 1) return setCurrentPage(1);
    refetch();
  };

  const onDeleteBill = () => {
    if (mutation.isLoading || !billId) return;
    mutation.mutate(billId);
  };

  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 삭제
  const { isOpen: isFile, onOpen: onFile, onClose: offFile } = useDisclosure(); //납부 고지서 파일 수정

  const isDirect = buildInfo?.management_fee_upload_type === "direct";

  if (hasFunc === undefined || !auth || isLoading) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <>
      <div className="flex min-h-full w-full flex-col px-6 py-10">
        <Text variant="static">
          <C.LeftIcons onClick={() => navigate("/maintenance-fee")} />
          관리비 납부고지서
        </Text>
        <C.DetailViewHeader>
          <C.DetailTitleBox>
            <Text>{moment(data?.data.date).format("YY년 MM월")} 납부고지서</Text>
          </C.DetailTitleBox>
        </C.DetailViewHeader>
        <div className="flex px-4 py-5 text-gray-400">
          <p>
            최종 편집자
            <C.InfoBoxText> {data?.data.user_name}</C.InfoBoxText>
          </p>
          <p>
            고지상태
            <C.InfoBoxText>{data?.data.is_open ? " 고지완료" : " 업로드완료"}</C.InfoBoxText>
          </p>
          <p>
            최근 업데이트
            <span className="ml-4 text-gray-500">
              {data?.data.updated_date ? moment.utc(data?.data.updated_date).format(" YYYY-MM-DD HH:mm") : ""}
            </span>
          </p>
        </div>
        <div className="flex flex-col items-start py-4 font-medium text-gray-400">
          <div className="flex items-end">
            {isDirect && (
              <C.SelectBox>
                <Text variant="default">납부상태</Text>
                <Select value={status} variant="default" onChange={(e) => setStatus(e.target.value)}>
                  <option value="">전체</option>
                  <option value="checking">확인 중</option>
                  <option value="completed">납부 완료</option>
                </Select>
              </C.SelectBox>
            )}
            <C.SelectBox>
              <Text variant="default">동</Text>
              <Select value={dong} variant="default" onChange={(e) => setDong(e.target.value)}>
                <option value="">전체</option>
                {buildInfo?.dong_floor.map((el) => (
                  <option key={uuidv4()} value={el.dong}>
                    {el.dong}동
                  </option>
                ))}
              </Select>
            </C.SelectBox>
            <C.SelectBox>
              <Text variant="default">검색</Text>
              <Select
                variant="default"
                value={inputs?.id || ""}
                onChange={(e) =>
                  setInputs &&
                  setInputs({
                    ...inputs,
                    id: e.target.value,
                    value: "",
                  })
                }
              >
                {buildInfo?.management_fee_upload_type === "direct" && <option value="search_tenant">입주사명</option>}
                <option value="search_ho">호실번호</option>
              </Select>
            </C.SelectBox>
            <C.SelectBox style={{ width: "fit-content" }}>
              <ContentInputs
                w="320px"
                onChange={(e) =>
                  setInputs &&
                  setInputs({
                    ...inputs,
                    id: inputs?.id || "search_tenant",
                    value: e.target.value,
                  })
                }
                value={inputs?.value || ""}
                placeholder={`${
                  inputs?.id === "search_tenant"
                    ? "검색하실 입주사명을 입력해주세요"
                    : "검색하실 호실번호를 입력해주세요"
                }`}
                onKeyDown={(e) => e.key === "Enter" && onRefetch()}
              />
              <Button variant="basic" onClick={onRefetch}>
                검색
              </Button>
            </C.SelectBox>
          </div>
        </div>
        <C.TableTopBox>
          <span>
            총 <C.TableCount>{data?.total_num}</C.TableCount>건
          </span>
        </C.TableTopBox>

        <div>
          <R.BillTable style={{ borderTop: "1px solid #6b7280" }}>
            <div>
              <Flex>
                <R.BillStickyBox w={isDirect} style={{ borderBottomColor: "#6b7280" }}>
                  <R.TrangleSquare w={isDirect ? "5%" : "10%"}>
                    <span />
                  </R.TrangleSquare>
                  <R.HeaderSquare w={isDirect ? "20%" : "45%"}>호실</R.HeaderSquare>
                  {isDirect && (
                    <>
                      <R.HeaderSquare w="20%">입주사명</R.HeaderSquare>
                      <R.HeaderSquare w="20%">사업자 등록번호</R.HeaderSquare>
                    </>
                  )}
                  <R.CostSquare w={isDirect ? "20%" : "45%"}>총 납부금액</R.CostSquare>
                  {isDirect && (
                    <R.CostSquare w="15%" style={{ borderLeft: "none" }}>
                      납부상태
                    </R.CostSquare>
                  )}
                </R.BillStickyBox>
                <R.LetterDetails style={{ borderBottom: "1px solid #6b7280" }}>
                  {isDirect ? (
                    <>
                      {data?.data.selected_columns_with_calculation_method?.map((el) => (
                        <R.SkySquare key={el.key}>{el.key}</R.SkySquare>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* 파일 등록으로 보여주는 방식에서는 칼럼에 납기내, 납기후 포함된 상태. */}
                      {data?.data.selected_columns
                        ?.filter((el) => el !== "납기내" && el !== "납기후")
                        .map((el) => <R.SkySquare key={el}>{el}</R.SkySquare>)}
                    </>
                  )}
                </R.LetterDetails>
              </Flex>
            </div>
            {/* data 타입때문에 주석 */}
            {data?.data.management_fee_details.map((el, idx) => (
              <MaintenanceItem key={String(el._id)} el={el} idx={idx + 1} isDirect={isDirect} />
            ))}
          </R.BillTable>
        </div>

        <PaginationUI page={currentPage || 1} allPage={data?.total_page_num || 1} setPage={setCurrentPage} />
      </div>
      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onClickCancel={onClose}
        onClickSave={() => {
          onDeleteBill();
          onClose();
        }}
      >
        <Text>납부고지서를 삭제할까요?</Text>
        <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
      </CancelModal>
      {auth === "edit" && (
        <R.ActionBox>
          <Button variant="delete" m="0" onClick={onOpen}>
            삭제
          </Button>
          {/* data 타입때문에 주석 */}
          <UploadCSVModal isEdit={true} isOpen={isFile} onClose={offFile} data={data?.data} />
          <Button
            m="0 12px"
            variant="blueLine"
            onClick={() => {
              //관리비인지 임대료인지
              if (!isDirect) return onFile(); //직접 올리는지 파일인지
              navigate(`/maintenance-fee/${billId}/edit`);
            }}
          >
            수정
          </Button>
        </R.ActionBox>
      )}
    </>
  );
}
