import { useParams } from "react-router-dom";
import useUserAuth from "../../../../components/hooks/useUserAuth";

import { Button, Modal, ModalContent, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../../components/styled/index";
import * as R from "../../../maintenance-fee/commons/styled";
import { useNavigate } from "react-router-dom";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useContext, useEffect, useState } from "react";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CancleModal from "../../../../components/modal/CancelModal";
import { MemoizedItem } from "../../_view/RentalItem";
import { warning } from "../../../../lib/theme/toast";
import { AxiosError } from "axios";
import FullPageSpinner from "../../../../components/Spinner";
import GetAuthorized from "../../../../components/Authrized";
import NotFound from "../../../not-found";
import { GlobalContext } from "../../../../App";
import { useGetRentFeeDetail } from "../../../../requests/rent-fee/useGetRentFeeDetail";
import { useUpdateRentFee } from "../../../../requests/rent-fee/useUpdateRentFee";
import { twMerge } from "tailwind-merge";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { useDeleteRentFee } from "../../../../requests/rent-fee/useDeleteRentFee";

type RentFeeDetail = {
  tenant_id: string;
  tenant_name: string;
  price?: number;
  point?: number;
  dong_ho: {
    _id: string;
    dong: string;
    ho: string;
  }[];
};

export default function RentFeeEditPage() {
  const { rentalId } = useParams();
  const auth = useUserAuth("rent_fee");
  const toast = useToast();
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [month, setMonth] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 등록
  const { isOpen: isBack, onOpen: goBack, onClose: dont } = useDisclosure(); //되돌아가기
  const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure(); // 삭제

  const { data, isLoading } = useGetRentFeeDetail({ rent_fee_id: rentalId ?? "" }, auth === "edit");
  const { mutateAsync: updateRentFee, isLoading: isUpdateRentFeeLoading } = useUpdateRentFee();
  const { mutateAsync: deleteRentFee } = useDeleteRentFee();

  const registered = (err: AxiosError) => {
    if (err.response?.status === 400)
      return toast({
        ...warning,
        title: "알림",
        description: "해당 년월에 이미 임대료가 등록되어있습니다. 다시 확인 후 등록해주세요.",
      });
    onOpen();
    toast({
      ...warning,
      title: "알림",
      description: "일시적인 오류로 실패했습니다. 잠시 후 다시 시도해주세요.",
    });
  };

  useEffect(() => {
    data?.data?.date && setMonth(new Date(data?.data?.date));
  }, [data?.data]);

  const onChange = useCallback((el: RentFeeDetail, value: string) => {
    el.price = Number(value || 0);
  }, []);

  if (isLoading) return <FullPageSpinner />;

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth !== "edit") return <GetAuthorized />;
  return (
    <>
      <div className="translate flex h-full w-full flex-col overflow-y-auto bg-white px-6 py-10">
        <p className="flex border-b-2 border-gray-500 pb-6 text-2xl">
          <FiChevronLeft onClick={goBack} className="cursor-pointer text-[32px]" />
          임대료 납부고지서 수정
        </p>
        <ConfirmModal
          close="확인"
          title="되돌아가기"
          onClose={dont}
          isOpen={isBack}
          onClickCancle={dont}
          onClickSave={() => navigate(-1)}
          blockScrollOnMount={false}
        >
          <p>지금까지 작성하신 내용은 저장되지 않습니다.</p>
          <p>이전 페이지로 이동할까요?</p>
        </ConfirmModal>
        <ul className="px-6 py-5">
          <li>납부 요청 보내기까지 완료해야 입주자에게 납부 고지가 됩니다.</li>
          <li>
            입주사 관리 - 입주사 상세 페이지에 저장된 임대료로 표시되며, 고지서 내에서 임대료를 수정한 경우에는 해당
            월에만 일시적으로 적용됩니다.
          </li>
        </ul>
        <div className="border-t border-gray-300 font-medium text-gray-500">
          <div className="flex items-center border-b border-gray-300 p-2 pl-4">
            <div className="min-w-[104px] font-medium text-gray-900">부과년월</div>
            <C.DateWithIconBox style={{ width: "180px" }}>
              <DatePicker
                locale={ko}
                selected={month}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                onChange={(date: Date) => setMonth(date)}
                customInput={
                  <R.MonthInput>
                    <Text>{moment(month).format("YYYY년 MM월")}</Text>
                    <FiChevronDown />
                  </R.MonthInput>
                }
              />
            </C.DateWithIconBox>
          </div>
        </div>
        <div className="flex flex-col items-start p-4">
          <p className="font-medium">임대료</p>
        </div>
        <div style={{ display: "contents" }}>
          <div className="table w-full border-b border-t border-gray-500 bg-gray-100 text-center">
            <div
              className={twMerge(
                "table-cell w-[4%] border-r border-gray-500 align-bottom",
                (data?.data.rent_fee_details.length || 0) > 999 && "w-[5%]",
              )}
            >
              <div className="flex items-end justify-end p-1">
                <div className="borber border-b-[24px] border-l-[24px] border-b-gray-300 border-l-transparent" />
              </div>
            </div>
            <div className="table-cell w-1/5 border-r border-gray-500 bg-gray-100 p-3">입주사명</div>
            <div className="table-cell w-1/4 border-r border-gray-500 bg-gray-100 p-3">호실</div>
            <div className="table-cell w-1/5 border-r border-gray-500 bg-[#eaf3ff] p-3">임대료</div>
            <div className="table-cell w-[30%] bg-white" />
          </div>
          {data?.data.rent_fee_details.map((el, idx) => (
            <MemoizedItem
              el={el}
              idx={idx}
              key={uuidv4()}
              length={data?.data.rent_fee_details.length}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
      <CancleModal
        isOpen={isDel}
        onClose={offDel}
        onClickCancel={offDel}
        onClickSave={() => {
          deleteRentFee({ rentFeeId: rentalId ?? "" })
            .then(() => {
              alert("임대료 납부 고지서를 삭제했습니다. 확인을 누르면 해당 임대료 현황 페이지로 이동합니다.");
              navigate("/rent-fee", { replace: true });
            })
            .catch(() => alert("일시적인 오류로 납부 고지서 삭제에 실패했습니다. 잠시 후 다시 시도해주세요."));
        }}
      >
        <p>임시 저장된 납부고지서를 삭제할까요?</p>
        <p>삭제된 데이터는 복구할 수 없습니다.</p>
      </CancleModal>
      <div className="sticky bottom-0 z-50 mt-2 flex w-full items-center justify-between rounded-2xl bg-white p-6">
        <Button variant="delete" m="0" onClick={onDel}>
          삭제
        </Button>
        <div className="flex items-center gap-2">
          {data?.data.updated_date && (
            <>
              최근 업데이트
              {moment.utc(data.data.updated_date).format(" YYYY-MM-DD HH:mm")}
            </>
          )}
          <button
            onClick={onOpen}
            className="border border-blue-600 bg-blue-600 px-3 py-2 text-base font-medium text-white"
          >
            수정하기
          </button>
        </div>
        {isUpdateRentFeeLoading ? (
          <Modal variant="contents" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent position="relative">
              <C.CustomSpin thickness="4px" speed="0.65s" size="xl" />
            </ModalContent>
          </Modal>
        ) : (
          <ConfirmModal
            close="수정하기"
            isOpen={isOpen}
            onClose={onClose}
            onClickCancle={onClose}
            onClickSave={() => {
              updateRentFee({
                rentFeeId: rentalId || "",
                date: moment(month).format("YYYY-MM-01"),
                rentFeeDetails: data?.data.rent_fee_details || [],
              })
                .then(() => {
                  alert("임대료 납부 고지서를 수정했습니다. 확인을 누르면 해당 임대료 상세 페이지로 이동합니다.");
                  navigate(`/rent-fee/${rentalId}`);
                })
                .catch(registered);
              onClose();
            }}
            title="경고"
          >
            <p>
              이미 납부고지가 된 고지서를 수정하는 경우에는 기존 납부고지서가 모두 삭제되어 입주자 앱에서도 확인이
              불가합니다.
            </p>
            <p>납부 고지서 수정 후 보내기까지 완료해주세요.</p>
          </ConfirmModal>
        )}
      </div>
    </>
  );
}
