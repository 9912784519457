import Icon from "../../../types/iconsax";
import Input from "../../../components/Input/Input";
import CustomModal from "../../../components/CustomModal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomCheckBox from "../../../components/CustomCheckBox";
import { IUser } from "../../../lib/types/Imodels";
import { useCreateRoomOwner } from "../../../requests/room/useCreateRoomOwner";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { useInviteRoomOwner } from "../../../requests/room/useInviteRoomOwner";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  owners?: {
    _id?: string;
    name: string;
    phone_number: string;
    user_id?: IUser;
  }[];
  room_id?: string;
  setOwners?: React.Dispatch<React.SetStateAction<{ name: string; phone_number: string; isInvite: boolean }[]>>;
};

type FormData = {
  name: string;
  phoneNumber: string;
};

export default function RoomAddOwnerModal({ isOpen, onClose, owners, room_id, setOwners }: Props) {
  const [isInviteOwner, setIsInviteOwner] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
  });

  const { mutateAsync: createRoomOwner } = useCreateRoomOwner();
  const { mutateAsync: inviteRoomOwner } = useInviteRoomOwner();

  const onSubmit = (data: FormData) => {
    if (setOwners) {
      setOwners?.((prev) => [...prev, { name: data.name, phone_number: data.phoneNumber, isInvite: isInviteOwner }]);
      onClose();
      return;
    }

    toast.promise(
      createRoomOwner({
        room_id: room_id || "",
        name: data.name,
        phone_number: data.phoneNumber,
      }),
      {
        loading: "Loading...",
        success: (res) => {
          const inviteOwner = res.room.owners.find((v) => v.phone_number === data.phoneNumber);

          if (isInviteOwner)
            toast.promise(
              inviteRoomOwner({
                roomId: room_id || "",
                ownerId: inviteOwner?._id || "",
              }),
              {
                loading: "Loading...",
                success: (
                  <CustomToast
                    iconType="check"
                    title={`'${inviteOwner?.name}'님께 가입 초대 알림톡이 전송되었습니다.`}
                  />
                ),
                error: (
                  <CustomToast
                    iconType="error"
                    title="가입 초대 알림톡 전송 실패"
                    description="잠시 후 다시 시도해주세요."
                  />
                ),
              },
            );

          return <CustomToast iconType="check" title="소유자 등록에 성공했습니다." />;
        },
        error: <CustomToast iconType="error" title="소유자 등록에 실패했습니다." />,
      },
    );

    onClose();
  };

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={"소유자 등록"}
      required={true}
      exit={true}
      onSubmit={handleSubmit(onSubmit)}
      primaryButtonText="등록하기"
      primaryButtonDisable={Object.keys(errors).length > 0}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[488px] flex-col gap-4 p-6 pt-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">이름</span>
            <span className="text-blue-600">*</span>
          </div>
          <div className="flex flex-col gap-1">
            <Input
              themeSize="md"
              placeholder="이름을 입력해주세요"
              {...register("name", {
                required: "이름은 필수 입력 사항입니다.",
              })}
              onBlur={(e) => setValue("name", e.target.value.trim())}
            />
            {errors.name && <p className="text-xs leading-normal text-red-500">{errors.name.message}</p>}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">연락처</span>
            <span className="text-blue-600">*</span>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Input
                themeSize="md"
                placeholder={`'-'없이 연락처를 입력해주세요`}
                {...register("phoneNumber", {
                  required: "연락처는 필수 입력 사항입니다.",
                  pattern: { value: /^[0-9]+$/, message: "연락처는 숫자만 입력 가능합니다." },
                  validate: (value) => {
                    return owners?.some((v) => v.phone_number === value) ? "이미 등록된 소유자입니다." : true;
                  },
                })}
                onBlur={(e) => setValue("phoneNumber", e.target.value.trim())}
                type="number"
              />
              {errors.phoneNumber && (
                <p className="text-xs leading-normal text-red-500">{errors.phoneNumber.message}</p>
              )}
            </div>
            <div className="flex gap-2 text-gray-500">
              <Icon.InfoCircle size={20} />
              <span className="text-sm font-medium">
                동일한 연락처로 인증하여 가입한 입주자에게 권한이 제안되며, 입주자가 승인해야 권한이 정상적으로
                부여됩니다.
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <p className="gap-1 font-semibold text-gray-700">가입 초대 알림톡</p>
          <div className="flex items-center rounded-xl border border-gray-100 bg-gray-50 p-3">
            <CustomCheckBox
              size="md"
              enabled={isInviteOwner}
              setEnabled={setIsInviteOwner}
              label="가입 초대 알림톡(카카오톡)을 자동으로 전송합니다."
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
