import { Link, Outlet, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useContext } from "react";
import { GlobalContext } from "../../App";
import CustomHeading from "../../components/CustomHeading";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";
import { useGetCertificationRequestList } from "../../requests/member/request/useGetCertificationRequestList";

export default function MemberLayout() {
  const { pathname } = useLocation();
  const { buildInfo } = useContext(GlobalContext);
  const { data } = useGetCertificationRequestList(
    {
      buildingId: buildInfo?._id.toString() || "",
      owner: "true",
      master: "true",
      normal: "true",
      searchText: "",
      filterStatus: ["waiting"],
    },
    !!buildInfo?._id,
  );
  const auth = useUserAuth("tenant");

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full">
      <CustomHeading headings="회원관리" sizeTheme="md" />
      <div className="flex h-12 items-center gap-6 border-b border-gray-200 px-6">
        <Link
          to="/members/requests?owner=true&master=true"
          className={twMerge(
            "flex h-full items-center gap-1 border-black transition-all duration-75",
            pathname === "/members/requests" && "border-b-2",
          )}
        >
          <span
            className={twMerge(
              "font-semibold leading-5 text-gray-500",
              pathname === "/members/requests" && "text-gray-900",
            )}
          >
            인증신청
          </span>
          <span
            className={twMerge(
              "rounded-xl bg-gray-100 px-2.5 py-0.5 text-xs font-medium leading-4 text-gray-400",
              pathname === "/members/requests" && "bg-gray-700 text-white",
            )}
          >
            {data?.data.totalCount || 0}
          </span>
        </Link>

        <Link
          to="/members/history?owner=true&master=true"
          className={twMerge(
            "flex h-full items-center gap-1 border-black transition-all duration-75",
            pathname === "/members/history" && "border-b-2",
          )}
        >
          <span
            className={twMerge(
              "font-semibold leading-5 text-gray-500",
              pathname === "/members/history" && "text-gray-900",
            )}
          >
            신청처리기록
          </span>
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
