import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  rentFeeId: string;
  date: string;
  rentFeeDetails: {
    tenant_id: string;
    tenant_name: string;
    point?: number;
    price?: number | null;
    dong_ho: {
      _id: string;
      dong: string;
      ho: string;
    }[];
  }[];
};

export const useUpdateRentFee = () => {
  return useMutation({
    mutationFn: ({ rentFeeId, date, rentFeeDetails }: Request) => {
      return http.post("/building/rent_fee/update", {
        rent_fee_id: rentFeeId,
        date,
        rent_fee_details: rentFeeDetails,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["rent_fee", "building/rent_fee/list"]);
    },
  });
};
