"use client";

import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { auth } from "../lib/firebase";

type Props = {
  children: React.ReactNode;
};

export default function AmplitudeProvider({ children }: Props) {
  useEffect(() => {
    if (process.env.REACT_APP_PHASE !== "production") return;
    if (!process.env.REACT_APP_AMPLITUDE_API_KEY) return;
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      autocapture: true,
    });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      amplitude.setUserId(user ? user.uid : undefined);
    });
    return () => unsubscribe();
  }, []);

  return <>{children}</>;
}
