import { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import { MdMoreHoriz } from "@react-icons/all-files/md/MdMoreHoriz";
import { IUser } from "../../../../lib/types/Imodels";
import OwnerCheckoutModal from "../units/ownerCheckoutModal";
import { useDisclosure } from "@chakra-ui/react";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { change_representative } from "../../../../lib/api/queries/urls";
import { queryClient } from "../../../..";
import { useParams } from "react-router-dom";
import { IRoom } from "../../../../lib/types/Imodels";
import { room_list_or_detail } from "../../../../lib/api/queries/keys";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import GetAuthorized from "../../../../components/Authrized";
import { useInviteRoomOwner } from "../../../../requests/room/useInviteRoomOwner";
import toast from "react-hot-toast";
import CustomToast from "../../../../components/CustomToast";
import Icon from "../../../../types/iconsax";
import dayjs from "dayjs";
import CustomTooltip from "../../../../components/CustomTooltip";

const OwnerItems = styled.div`
  width: 100%;
  height: 66px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  margin-bottom: 20px;
`;

const OwnerNameSpan = styled.span<{ account: boolean }>`
  color: ${(props) => (props.account ? "#111827" : "#9CA3AF")};
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-right: 8px;
`;

const OwnerPhoneSpan = styled.span`
  color: #9ca3af;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

const StatusContainer = styled.div`
  height: 24px;
  display: flex;
  padding: 2px 8px;
  line-height: 16px;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;

  > span {
    color: #6b7280;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    height: 18px;
  }

  > li {
    width: 6px;
    height: 26px;
  }

  > img {
    height: 24px;
  }
`;

const SubDiv = styled.div<{ height: string }>`
  width: 178px;
  height: ${(props) => props.height};
  position: absolute;
  top: 41px;
  right: -17px;
  background-color: #fff;
  display: inline-flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
`;

interface owners {
  name: string;
  phone_number: string;
}

export default function OwnerInfoItem(props: {
  data: {
    _id: string;
    name: string;
    phone_number: string;
    user_id: IUser;
    last_invite_date?: string;
  };
  roomInfo: {
    dong: string | undefined;
    ho: string | undefined;
  };
  representative: boolean;
  setOwners: Dispatch<SetStateAction<owners[]>>;
}) {
  const { room_id } = useParams();
  const representative = props.representative;
  const { isOpen: isOwnerCheckout, onOpen: onOwnerCheckout, onClose: offOwnerCheckout } = useDisclosure();
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const { mutateAsync: inviteRoomOwner } = useInviteRoomOwner();

  if (auth === "no_auth") return <GetAuthorized />;

  const ownerCheckoutClick = () => {
    onOwnerCheckout();
  };

  const changeOwner = () => {
    fetchClient
      .patch<BaseResponse<IRoom>>(change_representative, {
        room_id: room_id,
        _id: props.data._id,
      })
      .then((res) => {
        setSubMenuOpen(false);
        queryClient.invalidateQueries([room_list_or_detail]);
        return res.data.data;
      });
  };

  return (
    <div>
      <OwnerItems>
        <div>
          <OwnerNameSpan account={props.data.user_id !== undefined}>
            {props.data.user_id ? props.data.user_id.name : props.data.name}
          </OwnerNameSpan>
          <OwnerPhoneSpan>
            {props.data.user_id ? props.data.user_id.phone_number : props.data.phone_number}
          </OwnerPhoneSpan>
        </div>
        {props.data.user_id ? (
          <div style={{ display: "flex", gap: "16px" }}>
            {representative && (
              <StatusContainer>
                <span>대표 소유자</span>
              </StatusContainer>
            )}
            <StatusContainer>
              <li style={{ color: "#3CB979" }}></li>
              <span>가입완료</span>
            </StatusContainer>
            {isEdit && (
              <div style={{ position: "relative" }}>
                <MdMoreHoriz
                  style={{ height: "24px", cursor: "pointer" }}
                  onClick={() => setSubMenuOpen(!subMenuOpen)}
                />
                {subMenuOpen && (
                  <SubDiv height={representative ? "47px" : "86px"}>
                    {!representative && (
                      <button
                        className="flex w-full items-center gap-2 p-2 text-gray-500 hover:bg-gray-50"
                        onClick={changeOwner}
                      >
                        <Icon.Crown size={20} />
                        <p>대표소유자로 지정</p>
                      </button>
                    )}
                    <button
                      className="flex w-full items-center gap-2 p-2 text-gray-500 hover:bg-gray-50"
                      onClick={ownerCheckoutClick}
                    >
                      <Icon.Trash size={20} />
                      <p>퇴실</p>
                    </button>
                  </SubDiv>
                )}
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", gap: "16px" }}>
            {representative && (
              <StatusContainer>
                <span>대표 소유자</span>
              </StatusContainer>
            )}
            <StatusContainer>
              <li style={{ color: "#FF3D3D" }}></li>
              <span>미가입</span>
            </StatusContainer>
            {isEdit && (
              <div style={{ position: "relative" }}>
                <MdMoreHoriz
                  style={{ height: "24px", cursor: "pointer" }}
                  onClick={() => setSubMenuOpen(!subMenuOpen)}
                />
                {subMenuOpen && (
                  <SubDiv height={representative ? "86px" : "125px"}>
                    {!representative && (
                      <button
                        className="flex w-full items-center gap-2 p-2 text-gray-500 hover:bg-gray-50"
                        onClick={changeOwner}
                      >
                        <Icon.Crown size={20} />
                        <p>대표소유자로 지정</p>
                      </button>
                    )}

                    {!!props.data.last_invite_date === false ||
                    !(
                      dayjs().format("YYYY/MM/DD HH:mm:ss") <
                      dayjs(props.data.last_invite_date).add(1, "day").format("YYYY/MM/DD HH:mm:ss")
                    ) ? (
                      <button
                        className="flex w-full items-center gap-2 p-2 text-gray-500 hover:bg-gray-50 disabled:opacity-40"
                        onClick={() =>
                          toast.promise(inviteRoomOwner({ roomId: room_id ?? "", ownerId: props.data._id }), {
                            loading: "Loading...",
                            success: () => {
                              return (
                                <CustomToast
                                  iconType="check"
                                  title={`'${props.data.name}'님께 가입 초대 알림톡이 전송되었습니다.`}
                                />
                              );
                            },
                            error: () => {
                              return (
                                <CustomToast
                                  iconType="error"
                                  title="가입 초대 알림톡 전송 실패"
                                  description="잠시 후 다시 시도해주세요."
                                />
                              );
                            },
                          })
                        }
                      >
                        <Icon.Sms size={20} />
                        <p>가입 초대 전송</p>
                      </button>
                    ) : (
                      <CustomTooltip
                        position="right"
                        message={`${dayjs().diff(dayjs(props.data.last_invite_date), "days") < 1 ? "내일" : "오늘"} ${dayjs(props.data.last_invite_date).format("HH시 mm분에 재전송 가능")}`}
                      >
                        <button
                          disabled={true}
                          className="flex w-full items-center gap-2 p-2 text-gray-500 hover:bg-gray-50 disabled:opacity-40"
                        >
                          <Icon.Sms size={20} />
                          <p>가입 초대 전송</p>
                        </button>
                      </CustomTooltip>
                    )}
                    <button
                      className="flex w-full items-center gap-2 p-2 text-gray-500 hover:bg-gray-50"
                      onClick={ownerCheckoutClick}
                    >
                      <Icon.Trash size={20} />
                      <p>퇴실</p>
                    </button>
                  </SubDiv>
                )}
              </div>
            )}
          </div>
        )}
      </OwnerItems>
      {isOwnerCheckout && (
        <OwnerCheckoutModal
          isOpen={true}
          onClose={offOwnerCheckout}
          _id={props.data._id}
          name={props.data.user_id ? props.data.user_id.name : props.data.name}
          setOwners={props.setOwners}
        />
      )}
    </div>
  );
}
