import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";

type Request = {
  buildingId: string;
};

type Response = {
  id: string;
  result: boolean;
}[];

export const useInviteRoomAllOwners = () => {
  return useMutation({
    mutationFn: ({ buildingId }: Request) => {
      return httpV2.post<Request, Response>(`/rooms/owners/invite`, { buildingId });
    },
  });
};
