import * as R from "../../commons/styled";
import { IRentalList } from "../../../../lib/types/Imodels";
import { ChangeEvent, useState } from "react";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { update_management_stauts } from "../../../../lib/api/queries/urls";
import { Flex, useToast } from "@chakra-ui/react";
import { warning } from "../../../../lib/theme/toast";
import { queryClient } from "../../../..";
import { manage_fee_list_key } from "../../../../lib/api/queries/keys";
import useUserAuth from "../../../../components/hooks/useUserAuth";

type PropType = {
  el?: {
    tenant_id: string;
    tenant_name: string;
    _id?: string;
    payment_status?: string;
    //여기 밑은 관리비 (IRoomFeeObj)
    room_id: string;
    area?: number; //아직 없는 호실이 있음
    tenant_registration_number?: string;
    dong: string;
    ho: string;
    items: {
      key: string;
      value: string;
      _id?: string; // 받아오는 데이터 객체의 id값
      use?: string; // 바뀔수 있음. 사용량 저장값
      calculation_method?: "by_room" | "by_area" | "by_use" | "direct_input_with_save" | "direct_input_with_no_save";
    }[];
  };
  idx: number;
  length?: number;
  isDirect: boolean;
};

export default function MaintenanceItem({ el, ...props }: PropType) {
  const toast = useToast();
  const [status, setStatus] = useState(el?.payment_status);
  const auth = useUserAuth("management_fee");

  const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setStatus(value);
    fetchClient
      .post<BaseResponse<IRentalList>>(update_management_stauts, {
        management_fee_details: [
          {
            _id: el?._id,
            payment_status: value,
          },
        ],
      })
      .then(() => {
        queryClient.invalidateQueries([manage_fee_list_key]);
        alert(`관리비 납부 상태를 ${value === "checking" ? "확인 중으" : "납부완료"}로 변경하였습니다.`);
      })
      .catch(() => {
        toast({
          ...warning,
          title: "알림",
          description: "상태변경에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
        });
        setStatus(value === "checking" ? "completed" : "checking");
      });
  };

  return (
    <div>
      <div>
        <Flex>
          <R.BillStickyBox w={props.isDirect}>
            <R.Square isManage={true} w={props.isDirect ? "5%" : "10%"} style={{ padding: "12px 4px" }}>
              {props.idx}
            </R.Square>
            <R.Square isManage={true} w={props.isDirect ? "20%" : "45%"}>
              {el?.dong}동 {el?.ho}호
            </R.Square>
            {props.isDirect && (
              <>
                <R.Square isManage={true} w="20%">
                  {el?.tenant_name || ""}
                </R.Square>
                <R.Square w="20%" style={{ textAlign: "center" }}>
                  {el?.tenant_registration_number || ""}
                </R.Square>
              </>
            )}
            <R.CostSquare style={{ textAlign: "right" }} w={props.isDirect ? "20%" : "45%"}>
              {Math.ceil(Number(el?.items.find((el) => el.key === "납기내")?.value || 0)).toLocaleString("kr")}
            </R.CostSquare>
            {props.isDirect && (
              <R.CostSquare w="15%" p={auth === "edit" && true}>
                {auth === "edit" ? (
                  <R.RentalSelect
                    color={status !== "completed" ? "#6b7280" : "#2563eb"}
                    onChange={onChangeStatus}
                    value={status || ""}
                  >
                    <option value="" disabled>
                      상태 선택
                    </option>
                    <option value="checking">확인 중</option>
                    <option value="completed">납부완료</option>
                  </R.RentalSelect>
                ) : (
                  <div style={{ textAlign: "center" }}>{status === "checking" ? "확인 중" : "납부 완료"}</div>
                )}
              </R.CostSquare>
            )}
          </R.BillStickyBox>
          <R.LetterDetails>
            {el?.items
              .filter((el) => el.key !== "납기내")
              .map((el) => (
                <R.SkySquare key={el.key} style={{ textAlign: "right" }}>
                  {Math.ceil(Number(el.value)).toLocaleString("kr")}
                </R.SkySquare>
              ))}
          </R.LetterDetails>
        </Flex>
      </div>
    </div>
  );
}
