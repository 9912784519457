import * as C from "../../components/styled/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
import FullPageSpinner from "../../components/Spinner";
import PreCheckModal from "./component/precheckModal";
import moment from "moment";
import RentalListItem from "./_view/RentalItem";
import { ChangeButton, TableWrapBox } from "./commons/styled";
import useUserAuth from "../../components/hooks/useUserAuth";
import UploadCSVModal from "./component/UploadModal";
import { useAccountList, useManangementList } from "./commons/queries";
import NotFound from "../not-found";
import { unAuthUser, warning } from "../../lib/theme/toast";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { styled_btn } from "../../lib/theme/common";
import { useGetMaintenanceFeeList } from "../../requests/maintenance-fee/useGetMaintenanceFeeList";
import { twMerge } from "tailwind-merge";
import { useUpdateMaintenanceFeeNotify } from "../../requests/maintenance-fee/useUpdateMaintenanceFeeNotify";

export default function MaintenanceFeeListPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.MAINTENANCE_FEE?.enabled;
  const [year, setYear] = useState(new Date());
  const auth = useUserAuth("management_fee");
  const { data: bank } = useAccountList();
  const { data: tenants } = useManangementList(true);
  const { isOpen, onOpen, onClose } = useDisclosure(); //업로드 전 계좌 등록 모달
  const { isOpen: isFile, onOpen: onFile, onClose: offFile } = useDisclosure(); //파일 업로드

  const { data } = useGetMaintenanceFeeList(
    {
      date: year,
    },
    auth === "edit" || auth === "read",
  );
  const { mutateAsync: updateMaintenanceFeeNotify } = useUpdateMaintenanceFeeNotify();

  const onTransferRoute = () => {
    if (!tenants || tenants.data1.length === 0)
      return toast({
        ...warning,
        title: "알림",
        description:
          "관리비 등록을 위해서 호실을 먼저 등록해주세요. 호실 등록 후에도 동일한 알림이 뜰 경우 오피스너 담당자에게 문의해주세요.",
      });

    const isExist = !bank?.length || !buildInfo?.payment_date;
    if (isExist) return onOpen();
    buildInfo.management_fee_upload_type === "file" //파일 직접 올리기
      ? onFile()
      : navigate("/maintenance-fee/create");
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;

  return (
    <div className="flex min-h-full w-full flex-col bg-white p-0 md:px-6">
      <div className="flex w-full justify-between py-5">
        <C.DateWithIconBox>
          <DatePicker
            locale={ko}
            selected={year}
            dateFormat="MM/yyyy"
            showYearPicker
            onChange={(date: Date) => setYear(date)}
            customInput={
              <div className="relative flex w-fit cursor-pointer items-center gap-1 border border-gray-100 bg-gray-100 px-3 py-2 text-[15px] font-medium text-gray-500">
                <p>{moment.utc(year).format("YYYY년")}</p>
                <FiChevronDown className="h-5 w-5" />
              </div>
            }
          />
        </C.DateWithIconBox>
        {auth === "edit" && (
          <>
            <button
              onClick={onTransferRoute}
              className="border border-blue-600 bg-blue-600 px-3 py-2 text-[15px] text-white"
            >
              관리비 고지서 생성
            </button>
            <UploadCSVModal isOpen={isFile} onClose={offFile} isEdit={false} />
            <PreCheckModal isOpen={isOpen} onClose={onClose} onClick={() => navigate("/maintenance-fee/bank")} />
          </>
        )}
      </div>
      <div className="w-full border-t border-gray-500">
        <div className="flex h-12 w-full items-center justify-around border-b border-gray-500 bg-gray-100 py-2.5 text-center">
          <div className="w-1/5">부과년월</div>
          {buildInfo?.management_fee_upload_type === "direct" && <div className="w-1/5">총 부과금액</div>}
          <div className="w-1/5">최종편집자</div>
          <div className="w-1/5">업데이트일시</div>
          <div className="w-1/5">고지 상태</div>
        </div>
        {data?.data.map((el) => (
          <>
            <div
              className="flex w-full cursor-pointer items-center justify-center border-b border-gray-400 py-2.5 text-center transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
              onClick={() => navigate(`/maintenance-fee/${el._id}`)}
            >
              <div className="flex w-full justify-around">
                <div className="flex w-1/5 items-center justify-center">{moment(el?.date).format("YYYY년 MM월")}</div>
                {buildInfo?.management_fee_upload_type === "direct" && (
                  <div className={"flex w-1/5 items-center justify-center"}>
                    {Number(el?.sum_of_management_fee || 0).toLocaleString("kr")}원
                  </div>
                )}
                <div className="flex w-1/5 items-center justify-center">{el?.user_name}</div>
                <div className="flex w-1/5 items-center justify-center">
                  {el?.updated_date ? moment.utc(el.updated_date).format("YYYY-MM-DD HH:mm") : ""}
                </div>
                <div className="flex w-1/5 items-center justify-center">
                  {el?.is_open ? (
                    <div>고지완료</div>
                  ) : (
                    <>
                      {auth !== "edit" ? (
                        <div>등록완료</div>
                      ) : (
                        <button
                          className="rounded-md bg-blue-600 p-2 text-[15px] text-white"
                          onClick={async (e) => {
                            e.stopPropagation();

                            if (auth !== "edit") return toast({ ...unAuthUser });

                            updateMaintenanceFeeNotify({ management_fee_id: el._id || "" })
                              .then(() => {
                                alert("입주자에게 관리비 납부 고지서를 전송했습니다.");
                              })
                              .catch((err) => err.response?.status === 401 && toast({ ...unAuthUser }));
                          }}
                        >
                          보내기
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
