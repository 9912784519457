import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Select,
  RadioGroup,
  Radio,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import SmallInputs from "../../../../components/Input/shortText";
import { getTime } from "../../../../lib/utils/format";
import * as C from "../../../../components/styled/index";
import { fetchClient } from "../../../../lib/api/axios";
import { public_update } from "../../../../lib/api/queries/urls";
import PublicCheckedBox from "../checkBox/checkTenant";
import { allTime, format_room } from "../../../../lib/utils/dataFormat";
import SelectButton from "../../../../components/selectBox/checkedOption/buttonItems";
import CancleModal from "../../../../components/modal/CancelModal";
import { success, warning, WarningTime } from "../../../../lib/theme/toast";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import { GlobalContext } from "../../../../App";
import moment from "moment";
import SingleRow, { WithBox, WrapFlex } from "../../../../components/modal/table/singleRow";
import ContentInputs from "../../../../components/Input/middleContents";
import { useRoomType } from "../../../../lib/utils";
import { RoomInput } from "../../setting/_view/styled";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { queryClient } from "../../../..";
import { public_detail_key, public_list_key } from "../../../../lib/api/queries/keys";
import { useDeleteMeetingRoom } from "../../commons/queries";
import { IMeetingRoom, IMeetingRoomDetails } from "../../commons/types";
import { onChangeNumbers } from "../../commons/func";
import useUserAuth from "../../../../components/hooks/useUserAuth";

export const meeting_room_schema = yup.object().shape({
  dong: yup.string().required(),
  floor: yup.string().required(),
  name: yup.string().required(),
  people: yup.string().matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다."),
  isUserApproved: yup.boolean().required(),
  method: yup.string().required(),
});

export default function EditMeetingRoomModal(props: IMeetingRoomDetails) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //삭제 확인 모달
  const { isOpen: isChange, onOpen: onChange, onClose: offChange } = useDisclosure(); //변경 확인 모달
  const { buildInfo } = useContext(GlobalContext);
  const room_type = useRoomType();

  const auth = useUserAuth("room_public");
  const isEdit = auth === "edit";

  const [options, setOptions] = useState("");
  const [optionArr, setOptionArr] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [day, setDay] = useState("");
  const [start, setStarts] = useState("");
  const [end, setEnds] = useState("");
  const [tagArr, setTagArr] = useState<string[]>([]);

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    formState: { isDirty, isValid },
    getValues,
  } = useForm<IMeetingRoom>({
    defaultValues: {
      dong: props.data.dong,
      floor: props.data.floor,
      name: props.data.name,
      people: String(props.data.people_num || ""),
      isUserApproved: props.data.is_approval_required,
      method: "", //여기 지금은 개별로 작성하지만 결제 방식 추가되면 UI + 구조 바꿔야함
      price: "",
      payment_description: props.data.payment_description || "",
      guide: props.data.description || "",
    },
    mode: "onChange",
    resolver: yupResolver(meeting_room_schema),
  });

  useEffect(() => {
    // console.log("props.data", props.data);
    const newArr = [];
    if (props.data.payment_method_and_price) {
      props.data.payment_method_and_price.forEach((el) => {
        setValue("method", el.payment_method);
        setValue("price", el.price ? String(el.price) : "");
      });
    }
    // console.log("passed", getValues(), props.data.name);
    // setTypes(props.data.room_public_type);
    setCheckedItems(props.data.available_rooms);
    setOptionArr(props.data.options);
    const midnight = getTime(props.data.end_time_weekday) === "00:00" ? "24:00" : getTime(props.data.end_time_weekday);
    newArr.push(`평일 ${getTime(props.data.start_time_weekday)} - ${midnight}`);

    if (props.data.start_time_holiday) {
      const midnight =
        getTime(props.data?.end_time_holiday) === "00:00" ? "24:00" : getTime(props.data?.end_time_holiday);
      newArr.push(`주말 ${getTime(props.data?.start_time_holiday)} - ${midnight}`);
    }

    setTagArr(newArr);
  }, []);

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      room_type?.forEach((el) => allCheckedArr.push(el.label));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      if (newArr !== undefined) setCheckedItems(newArr);
    }
    if (checkedItems.length === room_type.length || 0) {
      return (checked = true);
    }
  };

  const onDelCondition = (value: string) => {
    const newArr = checkedItems.filter((el) => el !== value);
    setCheckedItems(newArr);
  };

  const onClickAdd = () => {
    if (day === "")
      return toast({
        ...warning,
        title: "알림",
        description: "평일 혹은 주말을 먼저 선택해주세요.",
        duration: 3000,
      });

    if (!start || !end)
      return toast({
        ...warning,
        title: "알림",
        description: "시간을 정확히 선택해주세요.",
        duration: 3000,
      });

    if (day === "평일") {
      const newArr = tagArr.filter((el) => !el.includes("평일"));
      setTagArr([...newArr, `평일 ${start} - ${end}`]);
    } else {
      const newArr = tagArr.filter((el) => !el.includes("주말"));
      setTagArr([...newArr, `주말 ${start} - ${end}`]);
    }
  };

  const onClickDel = (id: number) => {
    const newArr = tagArr.filter((_, index) => index !== id);
    setTagArr(newArr);
  };

  const onChangeOption = () => {
    const newArr = [...optionArr];
    if (options === "")
      return toast({
        ...warning,
        title: "알림",
        description: "시설 옵션을 입력 후 추가해주세요.",
      });
    if (newArr.includes(options))
      return toast({
        ...warning,
        title: "알림",
        description: "이미 포함된 시설 옵션입니다.",
      });
    newArr.push(options);
    setOptions("");
    setOptionArr(newArr);
  };

  const OptionDel = (idx: number) => {
    const newArr = optionArr.filter((_, index) => index !== idx);
    setOptionArr(newArr);
  };

  const onSetStartTime = (e: ChangeEvent<HTMLSelectElement>) => {
    const test = moment(`2001-01-01T${e.target.value}:00`);
    const value = moment(`2001-01-01T${!end ? "24:00" : end}:00`);
    // const holi = moment(`2001-01-01T${selectAll.start_time_holiday}:00`);

    if (moment.duration(value.diff(test)).asHours() <= 0)
      return toast({
        ...WarningTime,
        description: "시작시간은 종료시간보다 이후거나 같을 수 없습니다.",
      });

    setStarts(e.target.value);
  };

  const onSetEndTime = (e: ChangeEvent<HTMLSelectElement>) => {
    const test = moment(`2001-01-01T${e.target.value}:00`);
    const week = moment(`2001-01-01T${!start ? "00:00" : start}:00`);
    // const holi = moment(`2001-01-01T${selectAll.start_time_holiday}:00`);

    if (moment.duration(week.diff(test)).asHours() >= 0)
      return toast({
        ...WarningTime,
        description: "종료시간은 시작시간보다 이전이거나 같을 수 없습니다.",
      });

    setEnds(e.target.value);
  };

  const onBeforeUpdate = () => {
    if (tagArr.filter((el) => el.includes("평일")).length === 0) {
      return toast({
        ...warning,
        title: "알림",
        description: "평일은 필수 선택입니다.",
        duration: 3000,
      });
    }

    // 필드를 입력했는ㄷㅔ 유효성 통과 못하면
    if (!checkedItems.length || (isDirty && !isValid))
      return toast({
        ...warning,
        title: "회의실 수정",
        description: "회의실 수정을 위해 필수 항목을 모두 기입했는지 다시 한 번 확인해주세요.",
      });

    // if (!isDirty && !isValid && getValues().method === "")
    //   return toast({
    //     ...warning,
    //     title: "회의실 수정",
    //     description:
    //       "회의실 수정을 위해 필수 항목을 모두 기입했는지 다시 한 번 확인해주세요.",
    //   });

    if (getValues().method === "point" && !getValues().price)
      return toast({
        ...warning,
        title: "회의실 수정",
        description: "회의실 수정을 위해 필수 항목을 모두 기입했는지 다시 한 번 확인해주세요.",
      });

    onChange();
  };

  const onClickUpdate = (data: IMeetingRoom) => {
    const holiday = tagArr.filter((el) => el.includes("주말"));

    fetchClient
      .post(public_update, {
        room_public_id: String(props.data._id),
        room_public_type: "회의실",
        dong: data.dong,
        floor: data.floor,
        name: data.name,
        people_num: Number(data.people),
        options: optionArr,
        available_rooms: room_type.length === 1 ? ["office"] : checkedItems,
        start_time_weekday: `2000-01-01T${tagArr.filter((el) => el.includes("평일"))[0].split(" ")[1]}Z`,
        start_time_holiday: holiday.length !== 0 ? `2000-01-01T${holiday[0].split(" ")[1]}Z` : "",
        end_time_weekday: `2000-01-01T${tagArr.filter((el) => el.includes("평일"))[0].split(" ")[3]}Z`,
        end_time_holiday:
          holiday.length !== 0 ? `2000-01-01T${tagArr.filter((el) => el.includes("주말"))[0].split(" ")[3]}Z` : "",
        is_approval_required: data.isUserApproved,
        payment_method_and_price: [
          {
            payment_method: buildInfo?.services.MEETING_ROOM?.options?.USE_POINT ? data.method : "free",
            price: data.method === "free" ? undefined : Number(data.price),
          },
        ], //여기 지금은 직접 배열 만들지만 결제 방식 추가되면 UI + 구조 바꿔야함
        description: data.guide || "",
        payment_description: data.payment_description || "",
      })
      .then((res) => {
        toast({
          ...success,
          title: "회의실 정보 변경",
          description: `해당 회의실 정보가 변경되었습니다.`,
        });

        offChange();
        queryClient.invalidateQueries([public_detail_key]);
        queryClient.invalidateQueries([public_list_key]);
        return res.data.data;
      })
      .catch((res) => console.error(res));
  };

  const { mutation } = useDeleteMeetingRoom();
  const onClickDelete = async () => {
    if (!props.data._id || mutation.isLoading) return;
    try {
      await mutation.mutateAsync([String(props.data._id)]);

      queryClient.invalidateQueries([public_list_key]);
      onClose(); //삭제 확인 모달 닫기
      props.onClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal variant="contents" onClose={props.onClose} isOpen={props.isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <form
        onKeyDown={(e) => {
          if (e.code === "Enter") return e.preventDefault();
        }}
        onSubmit={handleSubmit(onClickUpdate)}
      >
        <ModalContent maxW="864px">
          <ModalHeader>{props.data?.name} 회의실 상세정보</ModalHeader>
          <ModalBody>
            <div>
              <Text variant="title">1. 기본 정보</Text>
              <SingleRow title="위치">
                <Flex>
                  <Controller
                    control={control}
                    name="dong"
                    render={({ field }) => (
                      <Select w="100px" mr="2" variant="fitContent" {...field}>
                        {buildInfo?.dong_floor.map((el) => (
                          <option key={el._id} value={el.dong}>
                            {el.dong}동
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                  <Controller
                    control={control}
                    name="floor"
                    render={({ field }) => (
                      <Select w="100px" mr="2" variant="fitContent" {...field}>
                        {buildInfo?.dong_floor
                          .filter((ele) => ele.dong === getValues().dong)?.[0]
                          ?.floor.map((el) => (
                            <option key={el} value={el}>
                              {el}층
                            </option>
                          ))}
                      </Select>
                    )}
                  />
                </Flex>
              </SingleRow>
              <SingleRow title="회의실명">
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <ContentInputs
                      onChange={onChange}
                      maxLength={30}
                      placeholder="최대 30자까지 입력 가능"
                      value={value}
                    />
                  )}
                />
              </SingleRow>
              <SingleRow title="최대 수용 인원">
                <Controller
                  control={control}
                  name="people"
                  render={({ field: { onChange, ...field } }) => (
                    <RoomInput
                      onChange={(e) => {
                        const value = onChangeNumbers(e);
                        if (value === "false") return;
                        onChange(value);
                      }}
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                      placeholder="숫자만 입력 가능"
                      {...field}
                    />
                  )}
                />
                <C.WordBox>명</C.WordBox>
              </SingleRow>
              <SingleRow title="시설 옵션">
                <WrapFlex style={{ padding: 0, alignItems: "flex-start" }}>
                  <Flex>
                    <SmallInputs
                      value={options}
                      onChange={(e) => setOptions(e.target.value)}
                      placeholder="모니터, 칠판 등 시설 옵션 입력"
                    />
                    <Button variant="cancel" onClick={onChangeOption}>
                      추가
                    </Button>
                  </Flex>
                  <div>
                    {optionArr.map((el, index) => (
                      <C.TagArr key={el}>
                        {el}
                        <C.TagCloseIcon onClick={() => OptionDel(index)} />
                      </C.TagArr>
                    ))}
                  </div>
                </WrapFlex>
              </SingleRow>
            </div>
            <C.IntervalBox>
              <Text variant="title">2. 이용 가능 조건</Text>
              {room_type.length > 1 && (
                <SingleRow title="입주사 조건">
                  <WithBox style={{ padding: 0, alignItems: "flex-start" }}>
                    <PublicCheckedBox checkedItems={checkedItems} onCheckedAll={onCheckedAll} default="입주사 전체">
                      {room_type?.map((el) => (
                        <SelectButton
                          key={el.id}
                          checkedItems={checkedItems}
                          onChangeChecked={onChangeChecked}
                          value={el.value}
                          label={el.label}
                        />
                      ))}
                    </PublicCheckedBox>
                    <div>
                      {JSON.stringify(room_type.map((el) => el.label)) === JSON.stringify(checkedItems) ? (
                        <C.TagArr>
                          입주사 전체
                          <C.TagCloseIcon onClick={() => onCheckedAll(false)} />
                        </C.TagArr>
                      ) : (
                        <>
                          {checkedItems?.map((el) => (
                            <C.TagArr key={el}>
                              {format_room(el)}
                              <C.TagCloseIcon onClick={() => onDelCondition(el)} />
                            </C.TagArr>
                          ))}
                        </>
                      )}
                    </div>
                  </WithBox>
                </SingleRow>
              )}
              {/* 추후 추가 예정 <PublicTable title="위치 조건">
                  <Select w={"100px"} marginRight="2" variant="fitContent">
                    동 선택
                  </Select>
                  <Select w={"100px"} marginRight="2" variant="fitContent">
                    층 선택
                  </Select>
                  <DefaultButton>추가</DefaultButton>
                </PublicTable> */}
              <SingleRow title="이용 가능 시간">
                <WithBox style={{ padding: 0, alignItems: "flex-start" }}>
                  <Flex alignItems="center">
                    <Select
                      value={day}
                      w="100px"
                      marginRight="2"
                      variant="fitContent"
                      onChange={(e) => setDay(e.target.value)}
                      isDisabled={tagArr.length === 2 ? true : false}
                    >
                      <option value="">선택</option>
                      <option value="평일">평일</option>
                      <option value="주말">주말</option>
                    </Select>
                    <Select
                      w="100px"
                      value={start}
                      marginRight="2"
                      variant="fitContent"
                      onChange={onSetStartTime}
                      isDisabled={tagArr.length === 2 ? true : false}
                    >
                      <option value="">오픈시간</option>
                      {allTime.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Select>
                    -
                    <Select
                      w="100px"
                      value={end}
                      margin="0px 8px"
                      variant="fitContent"
                      onChange={onSetEndTime}
                      isDisabled={tagArr.length === 2 ? true : false}
                    >
                      <option value="">종료시간</option>
                      {allTime.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Select>
                    <Button onClick={onClickAdd} variant="cancel">
                      추가
                    </Button>
                  </Flex>
                  <div>
                    {tagArr.map((el, index) => (
                      <C.TagArr key={el}>
                        {el}
                        <C.TagCloseIcon onClick={() => onClickDel(index)} />
                      </C.TagArr>
                    ))}
                  </div>
                </WithBox>
              </SingleRow>
            </C.IntervalBox>
            <C.IntervalBox>
              <Text variant="title">3. 예약</Text>
              <SingleRow title="예약확정 방식">
                <Controller
                  control={control}
                  name="isUserApproved"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      color="#6B7280"
                      onChange={(e) => onChange(JSON.parse(e))}
                      value={String(value)}
                      // onChange={(e) => setApproval(e)}
                      // value={approval}
                    >
                      <Radio mr={9} value="false" variant="default">
                        예약신청과 동시에 확정
                      </Radio>
                      <Radio value="true" variant="default">
                        관리자 확인 후 확정
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </SingleRow>
              <SingleRow title="결제수단 및 금액">
                <span style={{ paddingRight: "40px" }}>30분 /</span>
                <Controller
                  control={control}
                  name="method"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      w="160px"
                      mr="1"
                      variant="fitContent"
                      onChange={(e) => (resetField("price"), onChange(e))}
                      value={value}
                    >
                      <option disabled={!buildInfo?.services.MEETING_ROOM?.options?.USE_POINT} value="">
                        결제수단
                      </option>
                      <option disabled={!buildInfo?.services.MEETING_ROOM?.options?.USE_POINT} value="point">
                        포인트
                      </option>
                      <option value="free">무료</option>
                    </Select>
                  )}
                />
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { onChange, value } }) => (
                    <SmallInputs
                      isDisabled={
                        getValues().method === "free" ? true : !buildInfo?.services.MEETING_ROOM?.options?.USE_POINT
                      }
                      _disabled={{ backgroundColor: "#f4f4f5" }}
                      style={{ minWidth: "208px" }}
                      placeholder="결제 금액 입력"
                      onChange={(e) => {
                        const value = onChangeNumbers(e);
                        if (value === "false") return;
                        onChange(value);
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                      type="number"
                      value={value}
                    />
                  )}
                />
                {/* <Button variant="cancel">추가</Button> */}
              </SingleRow>
              <SingleRow title="예약정책">
                <WrapFlex>
                  <Controller
                    control={control}
                    name="guide"
                    render={({ field }) => (
                      <C.BasicTextArea
                        h="208px"
                        // value={policy}
                        placeholder="회의실 예약 시 숙지해야 할 정책을 작성해주세요."
                        // onChange={(e) => setPolicy(e.target.value)}
                        {...field}
                      />
                    )}
                  />
                </WrapFlex>
              </SingleRow>
              <SingleRow title="결제안내">
                <WrapFlex>
                  <Controller
                    control={control}
                    name="payment_description"
                    render={({ field }) => (
                      <C.BasicTextArea
                        h="208px"
                        // value={policy}
                        placeholder="결제 시 안내 사항을 작성해주세요."
                        // onChange={(e) => setPolicy(e.target.value)}
                        {...field}
                      />
                    )}
                  />
                </WrapFlex>
              </SingleRow>
            </C.IntervalBox>
          </ModalBody>
          {isEdit ? (
            <ModalFooter pr="40px" justifyContent="space-between">
              <Button ml={0} type="button" onClick={onOpen} variant="delete">
                삭제
              </Button>
              <CancleModal
                isOpen={isOpen}
                onClose={onClose}
                onClickCancel={onClose}
                onClickSave={onClickDelete}
                blockScrollOnMount={false}
              >
                <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
                <Text>정말로 삭제하시겠습니까?</Text>
              </CancleModal>
              <div>
                <Button onClick={props.onClose} type="button" variant="cancel">
                  취소
                </Button>
                <Button onClick={onBeforeUpdate} variant="bgBlue">
                  수정하기
                </Button>
              </div>
            </ModalFooter>
          ) : (
            <ModalFooter pr="40px" justifyContent="flex-end">
              <Button onClick={props.onClose} type="button" variant="cancel">
                확인
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
        <ConfirmModal
          title="시설 정보 변경"
          close="변경하기"
          isOpen={isChange}
          onClose={offChange}
          onClickCancle={offChange}
          // onClickSave={onClickUpdate}
          onClickSave={handleSubmit(onClickUpdate)}
          blockScrollOnMount={false}
        >
          <Text>해당 시설의 정보를 변경하시겠습니까?</Text>
          <Text>변경 후에도 이미 예약 완료된 건은 그대로 유지되어 입주자가 해당 시설을 이용할 수 있습니다.</Text>
        </ConfirmModal>
      </form>
    </Modal>
  );
}
