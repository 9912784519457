import { useQuery } from "@tanstack/react-query";
import { http } from "../../lib/http";

type Response = {
  data: {
    tenant_id: string;
    tenant_name: string;
    point: number;
    dong_ho: {
      dong: string;
      ho: string;
    }[];
  }[];
};

export const useGetPointMonthlyList = () => {
  return useQuery({
    queryKey: ["tenant/rooms/point"],
    queryFn: () => {
      return http.get<Response>("/building/tenant/rooms/point");
    },
  });
};
