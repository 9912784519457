import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  room_id: string;
  name: string;
  phone_number: string;
};

type Response = {
  room: {
    _id: string;
    area: number;
    building_id: string;
    dong: string;
    floor: string;
    ho: string;
    owner_name: string;
    owner_phone_number: string;
    owners: {
      name: string;
      phone_number: string;
      _id: string;
    }[];
    room_type: string;
    tenant_id: string;
    createAt: Date;
    updatedAt: Date;
  };
};

export const useCreateRoomOwner = () => {
  return useMutation({
    mutationFn: ({ room_id, name, phone_number }: Request) => {
      return http.patch<Request, Response>("/building/room_owner/create", {
        room_id,
        name,
        phone_number,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/room"]);
    },
  });
};
