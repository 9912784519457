import { useMutation, useQuery } from "@tanstack/react-query";
import { http } from "../lib/http";
import { queryClient } from "..";
import { useNavigate } from "react-router-dom";
import { validators } from "tailwind-merge";

export const VOTE_STATUS = {
  BEFORE: "진행전",
  IN_PROGRESS: "진행중",
  COMPLETED: "진행완료",
  CANCELED: "중단",
} as const;

export type VoteStatus = (typeof VOTE_STATUS)[keyof typeof VOTE_STATUS];

type VotesResponse = {
  data: {
    _id: string;
    status: VoteStatus;
    title?: string;
    desciprtion?: string;
    senderName: string;
  }[];
  message: string;
  total_num: number;
  total_page_num: number;
};

export const useGetVotesQuery = () => {
  return useQuery({
    queryKey: ["/building/vote"],
    queryFn: () => http.get<VotesResponse>("/building/vote"),
  });
};

export type VoteDetailResponse = {
  data: {
    _id: string;
    status: VoteStatus;
    title?: string;
    description?: string;
    agendas: {
      _id: string;
      title: string;
    }[];
    totalArea: number;
    startAt: string;
    endAt: string;
    signatures: {
      _id: string;
      type: "room_owner";
      documentId: string;
      name: string;
      phone_number: string;
      signatureStatus: boolean;
      room_ids: {
        _id: string;
        building_id: string;
        room_code: string;
        room_type: string;
        dong: string;
        ho: string;
        floor: string;
        createdAt: string;
        updatedAt: string;
        __v: number;
        tenant_id: string;
        area: number;
        owner_name: string;
        owner_phone_number: string;
        owners: {
          _id: string;
          name: string;
          phone_number: string;
        }[];
      }[];
      delegate: {
        _id?: string;
        documentId?: string;
        name?: string;
        phone_number?: string;
        file_url?: string;
        delegate_status: boolean;
      };
      file_url?: string;
    }[];
    result?: {
      field_name: string;
      agree?: {
        counts: number;
        area: number;
      };
      disagree?: {
        counts: number;
        area: number;
      };
      invalid?: {
        counts: number;
        area: number;
      };
    }[];
    senderName: string;
    sender_contact_number: string;
    createdAt: string;
    updatedAt: string;
  };
  message: "complete";
};

export const useGetVoteDetailQuery = (id: string) => {
  return useQuery({
    queryKey: ["/building/vote", id],
    queryFn: () => http.get<VoteDetailResponse>(`/building/vote/${id}`),
  });
};

type VoteCreateResponse = {
  message: string;
  data: {
    building_id: string;
    status: VoteStatus;
    _id: string;
    agendas: [];
    signatures: [];
    createdAt: Date;
    updatedAt: Date;
  };
};

export const useCreateVoteMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["/building/vote"],
    mutationFn: async () => {
      return http.post<unknown, VoteCreateResponse>("/building/vote");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["/building/vote"]);
      navigate(`/votes/${data.data._id}`);
    },
  });
};

type UpdateRequest = {
  voteId: string;
  title: string;
  description: string;
  senderName: string;
  senderContact: string;
  agendas: {
    title: string;
  }[];
};

export const useUpdateVoteMutation = () => {
  return useMutation({
    mutationFn: ({ voteId, ...rest }: UpdateRequest) =>
      http.put<Omit<UpdateRequest, "voteId">>(`/building/vote/${voteId}`, rest),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["/building/vote", variables.voteId]);
    },
  });
};

export const useDeleteVoteMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (voteId: string) => http.del(`/building/vote/${voteId}`),
    onSuccess: (_, voteId) => {
      navigate("/votes");
      queryClient.invalidateQueries(["/building/vote", voteId]);
    },
  });
};

type VoteSendRequest = {
  voteId: string;
  endDate: string;
  voterList: {
    name: string;
    phone_number: string;
    room_ids: string[];
  }[];
};

// 투표 발송
export const useSendVoteMutation = () => {
  return useMutation({
    mutationFn: ({ voteId, ...rest }: VoteSendRequest) => {
      return http.post<Omit<VoteSendRequest, "voteId">, unknown>(`/building/vote/${voteId}/send`, rest);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["/building/vote", variables.voteId]);
    },
  });
};

// 투표 중단
export const useVoteCancelMutation = () => {
  return useMutation({
    mutationFn: (voteId: string) => {
      return http.put<unknown, unknown>(`/building/vote/${voteId}/cancel`);
    },
    onSuccess: (_, voteId) => {
      queryClient.invalidateQueries(["/building/vote", voteId]);
    },
  });
};

// 알림 재전송
export const useVoteRemindMutation = () => {
  return useMutation({
    mutationFn: ({ voteId, signaturesId }: { voteId: string; signaturesId?: string }) => {
      return http.post<unknown>(`/building/vote/${voteId}/remind`, { signaturesId });
    },
  });
};

// 투표 멤버에서 삭제
export const useVoteDeleteSignaturesMutation = () => {
  return useMutation({
    mutationFn: ({ voteId, signaturesId }: { voteId: string; signaturesId: string }) => {
      return http.del(`/building/vote/${voteId}/signatures/${signaturesId}`);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["/building/vote", variables.voteId]);
    },
  });
};

type VoteDelegateRequest = {
  voteId: string;
  signaturesId: string;
  name: string;
  phoneNumber: string;
};
// 수동 위임
export const useVoteDelegateMutation = () => {
  return useMutation({
    mutationFn: ({ voteId, signaturesId, name, phoneNumber }: VoteDelegateRequest) => {
      return http.post<unknown, unknown>(`/building/vote/${voteId}/signatures/${signaturesId}/delegate`, {
        name,
        phoneNumber,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["/building/vote", variables.voteId]);
    },
  });
};

type VoteExtendTimeRequest = {
  voteId: string;
  expireDate: string;
};

export const useVoteExtendTimeMutation = () => {
  return useMutation({
    mutationFn: ({ voteId, ...rest }: VoteExtendTimeRequest) => {
      return http.put<Omit<VoteExtendTimeRequest, "voteId">, unknown>(`/building/vote/${voteId}/extend_date`, rest);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["/building/vote", variables.voteId]);
    },
  });
};

export const useVoteEarlyEndMutation = () => {
  return useMutation({
    mutationFn: (voteId: string) => {
      return http.post<unknown, unknown>(`/building/vote/${voteId}/early_stop`);
    },
    onSuccess: (_, voteId) => {
      queryClient.invalidateQueries(["/building/vote", voteId]);
    },
  });
};

type VoteDownloadProps = {
  fileName: string;
  voteId: string;
  documentId: string;
};

type VoteFileDownloadResponse = {
  data: string;
};

export const useVoteFileDownloadMutation = () => {
  return useMutation({
    mutationFn: async ({ fileName, documentId, voteId }: VoteDownloadProps) => {
      const { data: fileUrl } = await http.get<VoteFileDownloadResponse>(
        `/building/vote/${voteId}/document/${documentId}/file`,
      );
      if (!fileUrl) throw new Error("fileUrl is null");
      const blob = await http.download(fileUrl);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${fileName}.pdf`;
      link.click();
    },
  });
};
