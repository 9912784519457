import CustomModal from "../../../components/CustomModal";
import { useState } from "react";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useUpdateCompanyMutation } from "../../../requests/company/useUpdateCompanyMutation";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import dayjs from "dayjs";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  name: string;
  rentStartDate?: Date | null;
  rentEndDate?: Date | null;
};

export default function CompanyModifyTermModal({
  isOpen,
  onClose,
  companyId,
  name,
  rentStartDate,
  rentEndDate,
}: Props) {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    rentStartDate ? new Date(rentStartDate) : null,
    rentStartDate ? (rentEndDate ? new Date(rentEndDate) : null) : null,
  ]);
  const [startDate, endDate] = dateRange;

  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="회사 정보 수정"
      exit={true}
      primaryButtonText="저장하기"
      onPrimaryButtonClick={() => {
        toast.promise(
          updateCompany({
            companyId,
            rentStartDate: startDate || undefined,
            rentEndDate: endDate || undefined,
          }),
          {
            success: () => {
              onClose();
              return (
                <CustomToast
                  iconType="check"
                  title="입주사 정보 수정"
                  description={`'${name}'의 정보가 변경되었습니다.`}
                />
              );
            },
            error: () => {
              onClose();
              return <CustomToast iconType="error" title="정보 수정 중 에러가 발생하였습니다." />;
            },
            loading: <CustomToast iconType="loading" title="Loading" />,
          },
        );
      }}
      primaryButtonDisable={
        startDate !== null &&
        (rentStartDate ? new Date(rentStartDate).getTime() : null) === startDate?.getTime() &&
        endDate !== null &&
        (rentEndDate ? new Date(rentEndDate).getTime() : null) === endDate?.getTime()
      }
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[30.5rem] flex-col gap-5 p-6 pt-3">
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">입주 기간</span>
            <span className="text-blue-600">*</span>
          </div>
          <CustomDatePicker startDate={startDate} endDate={endDate} setDateRange={setDateRange} />
        </div>
      </div>
    </CustomModal>
  );
}
