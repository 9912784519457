import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type Request = {
  roomId: string;
  ownerId: string;
};

type Response = {
  data: {
    _id: string;
  };
};

export const useInviteRoomOwner = () => {
  return useMutation({
    mutationFn: ({ roomId, ownerId }: Request) => {
      return httpV2.post<Response>(`/rooms/${roomId}/owners/${ownerId}/invite`);
    },
    onSuccess: (_, { roomId }) => {
      queryClient.invalidateQueries(["building/room", "detail", roomId]);
    },
  });
};
