import { useQuery } from "@tanstack/react-query";
import { http } from "../../lib/http";

type Request = {
  category: string;
};

type Response = {
  data: {
    _id: string;
    images: string[];
    category: string;
    question: string;
    answer: string;
    updatedAt: Date;
  }[];
};

export const useGetResourceList = ({ category }: Request) => {
  return useQuery({
    queryKey: ["faq/list", category],
    queryFn: () => {
      return http.get<Response, Request>("/building/faq/list", {
        category,
      });
    },
  });
};
