import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../../../App";
import styled from "@emotion/styled";
import { ContourWrapper, DetailTitleBox, WrapperGround } from "../../../../components/styled/webApp";
import * as D from "../../../complaints/[complaintId]/_view/style";
import { useQuery } from "@tanstack/react-query";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { room_list_or_detail } from "../../../../lib/api/queries/keys";
import { IBuilding, IRoom, ITenant, IUser } from "../../../../lib/types/Imodels";
import { fetchClient } from "../../../../lib/api/axios";
import { room_list } from "../../../../lib/api/queries/urls";
import moment from "moment";
import { format_room } from "../../../../lib/utils/dataFormat";
import OwnerAddModal from "../../../company/settings/units/ownerAddModal";
import DeleteRoomModal from "../../../company/settings/units/DeleteRoomModal";
import { useDisclosure } from "@chakra-ui/react";
import OwnerInfoItem from "../../../company/settings/units/ownerInfoItem";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import IconBlackPlus from "../../../../components/icons/IconBlackPlus";
import IconGrayPlus from "../../../../components/icons/IconGrayPlus";
import { toast } from "../../../../lib/utils/toast";
import FullPageSpinner from "../../../../components/Spinner";
import GetAuthorized from "../../../../components/Authrized";
import { useOverlay } from "@toss/use-overlay";
import RoomAddOwnerModal from "../../../../views/room/modal/RoomAddOwnerModal";

const DeleteButton = styled.button<{ enabled: boolean }>`
  width: 128px;
  height: 48px;
  border-radius: 8px;
  background: #f36151;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-top: 24px;
  opacity: ${(props) => (props.enabled ? 0.4 : 1)};
`;

const LineDivison = styled.div<{ marginBottom: string }>`
  width: 100%;
  height: 1px;
  border-top: 1px solid #d1d5db;
  margin-bottom: ${(props) => props.marginBottom};
`;

const ChildrenContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const SubTitle = styled.div`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
`;

const OwnerItems = styled.div`
  width: 100%;
  height: 66px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  margin-bottom: 20px;
`;

const TenantItems = styled.div<{ height: string }>`
  width: 100%;
  height: ${(props) => props.height};
  border-radius: 12px;
  background: #f4f4f5;
  padding: 20px 16px;
`;

// 입주 상태
const OccupancyStatusP = styled.p`
  color: #3cb979;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  margin-bottom: 12px;
`;

const TenantNameSpan = styled.span`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-right: 8px;
`;

const TenantCodeSpan = styled.span`
  color: #6b7280;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

const VacancyText = styled.span`
  color: #f36151;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  vertical-align: middle;
`;

const RoomInfoTitle = styled.span`
  color: #6b7280;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 0px;
`;

const RoomInfoContents = styled.span`
  color: #6b7280;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0px;
`;

const OwnerNameSpan = styled.span<{ account: boolean }>`
  color: ${(props) => (props.account ? "#111827" : "#9CA3AF")};
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-right: 8px;
`;

const OwnerPhoneSpan = styled.span`
  color: #9ca3af;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

const StatusContainer = styled.div`
  height: 24px;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;

  > span {
    color: #6b7280;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    height: 18px;
  }

  > li {
    width: 6px;
    height: 26px;
  }

  > img {
    height: 24px;
  }
`;

const NoneInfoSpan = styled.span`
  color: #e5e7eb;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

interface owners {
  name: string;
  phone_number: string;
}

export default function RoomModify() {
  const { room_id } = useParams();
  const { buildInfo } = useContext(GlobalContext);
  const { isOpen: isOwnerAdd, onOpen: onOwnerAdd, onClose: offOwnerAdd } = useDisclosure();
  const { isOpen: isDeleteRoom, onOpen: onDeleteRoom, onClose: offDeleteRoom } = useDisclosure();
  const [owners, setOwners] = useState<owners[]>([]);

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";
  const overlay = useOverlay();

  const { data, isLoading } = useQuery([room_list_or_detail, "detail", room_id], () =>
    fetchClient
      .post<BaseResponse<IRoom>>(room_list, {
        room_id: room_id,
      })
      .then((res) => {
        if (res.data.data.owners !== undefined) {
          if (res.data.data.owners.length > 0) {
            setOwners(res.data.data.owners);
          }
        }
        return res.data.data;
      }),
  );

  const addOwnerClick = () => {
    overlay.open(({ isOpen, close }) => (
      <RoomAddOwnerModal isOpen={isOpen} onClose={close} owners={data?.owners} room_id={room_id || ""} />
    ));
  };

  const deleteClick = () => {
    if (auth !== "edit") return toast.unAuthUser();
    onDeleteRoom();
  };

  if (isLoading) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div>
      <WrapperGround>
        <ContourWrapper location={"only"}>
          <D.LocationWithHeader>
            <D.RequestDetailTitle>
              <DetailTitleBox>
                {data?.dong}동 {data?.floor}층 {data?.ho}호
              </DetailTitleBox>
            </D.RequestDetailTitle>
          </D.LocationWithHeader>
          <LineDivison marginBottom={"0px"} />
          <div style={{ display: "grid", marginBottom: "44px", gridTemplateColumns: "140px auto" }}>
            <RoomInfoTitle>호실용도</RoomInfoTitle>
            <RoomInfoContents>{data?.room_type && format_room(data?.room_type)}</RoomInfoContents>
            <RoomInfoTitle>전용면적</RoomInfoTitle>
            <RoomInfoContents>{data?.area ? data?.area : <NoneInfoSpan>정보없음</NoneInfoSpan>}</RoomInfoContents>
            <RoomInfoTitle>업데이트 일시</RoomInfoTitle>
            <RoomInfoContents>{moment.utc(data?.updatedAt).format("YYYY-MM-DD HH:mm")}</RoomInfoContents>
          </div>
          <ChildrenContainer>
            {buildInfo?.building_type === "knowledge_industry_center" && (
              <div style={{ width: "100%", minWidth: "430px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <SubTitle>소유자 정보</SubTitle>
                  {isEdit && (
                    <div onClick={addOwnerClick} style={{ cursor: "pointer" }}>
                      <IconBlackPlus />
                    </div>
                  )}
                </div>
                <LineDivison marginBottom={"20px"} />
                {data?.owners && data.owners.length > 0 ? (
                  data.owners.map((item, index) => {
                    return (
                      <OwnerInfoItem
                        key={item._id}
                        data={item}
                        roomInfo={{
                          dong: data?.dong,
                          ho: data?.ho,
                        }}
                        representative={index === 0}
                        setOwners={setOwners}
                      />
                    );
                  })
                ) : (
                  <OwnerItems>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                      <OwnerNameSpan account={false}>소유자를 등록해주세요.</OwnerNameSpan>
                      {isEdit && (
                        <div onClick={addOwnerClick} style={{ cursor: "pointer" }}>
                          <IconGrayPlus />
                        </div>
                      )}
                    </div>
                  </OwnerItems>
                )}
              </div>
            )}
            <div style={{ width: "100%" }}>
              <SubTitle>입주사 정보</SubTitle>
              <LineDivison marginBottom={"20px"} />
              {/* <div> */}
              {data?.tenant_id ? (
                <TenantItems height="93px">
                  <div>
                    <OccupancyStatusP>입주 중</OccupancyStatusP>
                    <div>
                      <TenantNameSpan>{data.tenant_id.name}</TenantNameSpan>
                    </div>
                  </div>
                </TenantItems>
              ) : (
                <TenantItems height="64px">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <VacancyText>공실</VacancyText>
                    {/* <img alt = {'icon'} /> */} {/* 추후 추가 예정*/}
                  </div>
                </TenantItems>
              )}
              {/* </div> */}
            </div>
          </ChildrenContainer>
          {isEdit && (
            <DeleteButton
              disabled={data?.tenant_id !== undefined}
              enabled={data?.tenant_id !== undefined}
              onClick={deleteClick}
            >
              호실 삭제
            </DeleteButton>
          )}
        </ContourWrapper>
      </WrapperGround>
      {isOwnerAdd && <OwnerAddModal ownerList={owners} setOwnerList={setOwners} isOpen={true} onClose={offOwnerAdd} />}
      {isDeleteRoom && <DeleteRoomModal isOpen={true} onClose={offDeleteRoom} />}
    </div>
  );
}
