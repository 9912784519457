import { useQuery } from "@tanstack/react-query";
import { httpV2 } from "../lib/httpV2";
import { useParams } from "react-router-dom";

type CompaniesQuery = {
  buildingId: string;
  searchText?: string;
  tenantType?: "office" | "store" | "residential" | "etc";
  dong?: string;
  floor?: string;
  page?: number;
  limit?: number;
};

type Response = {
  totalCount: number;
  companies: Array<{
    id: string;
    name: string;
    roomInfos: Array<{
      _id: string;
      dong: string;
      ho: string;
      floor: string;
    }>;
    members: Array<{
      id: string;
      permission: 1 | 2 | 3;
      userId: string;
      name: string;
      phoneNumber: string;
    }>;
    tenantType: "office" | "store" | "residential" | "etc";
    salesOrLeaseContractFiles: {
      id: string;
      name: string;
      url: string;
    }[];
    businessRegistrationFiles: {
      id: string;
      name: string;
      url: string;
    }[];
  }>;
};

// 층 데이터를 받지 않지만 화면 테스트를 위해 floor 추가
// floor filter 기능 동작하지 않음
export const useCompaniesQuery = (
  { buildingId, searchText, tenantType, dong, floor, page = 1, limit = 10 }: CompaniesQuery,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["/companies", buildingId, searchText, tenantType, dong, floor, page, limit],
    queryFn: () =>
      httpV2.get<Response>("/companies", {
        buildingId,
        searchText,
        tenantType,
        dong,
        floor,
        page,
        limit,
      }),
    enabled,
  });
};

type CompanyDetailResponse = {
  buildingId: string;
  tenantType: "office" | "store" | "residential" | "etc";
  roomInfo: Array<{
    _id: string;
    dong: string;
    ho: string;
    floor: string;
  }>;
  name: string;
  phoneNumber: string;
  members: Array<{
    id: string;
    name: string;
    phoneNumber: string;
    permission: 1 | 2 | 3;
    userId: string;
    lastInviteDate: Date;
  }>;
  ceoName: string;
  email: string;
  fax: string;
  tenantRegistrationNumber: string;
  rentStartDate: Date;
  rentEndDate: Date;
  moveInType: "own_house" | "monthly_rent" | "etc";
  salesOrLeaseContractFiles: {
    id: string;
    name: string;
    directory: string;
  }[];
  businessRegistrationFiles: {
    id: string;
    name: string;
    directory: string;
  }[];
};

export const useCompanyDetailQuery = (companyId: string) => {
  return useQuery({
    queryKey: [`/companies/${companyId}`],
    queryFn: () => httpV2.get<CompanyDetailResponse>(`/companies/${companyId}`),
  });
};
