import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../../lib/httpV2";
import { queryClient } from "../../..";

type Request = {
  certifications: Array<{
    certificationId: string;
    status: "approved" | "rejected";
  }>;
};

type Response = {
  certificationId: string;
  status: number;
  message: string;
}[];

export const useUpdateCertificationRequest = () => {
  return useMutation({
    mutationFn: (certifications: Request) => {
      return httpV2.patch<Request, Response>("/certifications-request", certifications);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["/certifications-request"]);
    },
  });
};
