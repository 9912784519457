import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  bank_id: string;
  account_number: string;
  account_name: string;
};

export const useCreateBankAccount = () => {
  return useMutation({
    mutationFn: ({
      bankId,
      accountNumber,
      accountName,
    }: {
      bankId: string;
      accountNumber: string;
      accountName: string;
    }) => {
      return http.post<Request>("/building/account/create", {
        bank_id: bankId,
        account_number: accountNumber,
        account_name: accountName,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["building/account/list"]);
    },
  });
};
