import { memo, useState } from "react";
import { twMerge } from "tailwind-merge";

type RentRoomType = {
  tenant_id: string;
  tenant_name: string;
  price?: number;
  point?: number;
  dong_ho: {
    _id: string;
    dong: string;
    ho: string;
  }[];
};

interface PropsType {
  el: RentRoomType;
  idx: number;
  length: number | undefined;
  onChange: (el: RentRoomType, value: string) => void;
}

export function RentalItem(props: PropsType) {
  const [price, setPrice] = useState<number>();

  return (
    <div className="table border-b border-gray-300">
      <div
        className={twMerge(
          "table-cell w-[4%] break-normal border-r border-r-gray-500 bg-white px-1 py-3 text-center align-middle",
          (props.length || 0) > 999 && "w-[5%]",
        )}
      >
        {props.idx + 1}
      </div>
      <div className="table-cell w-1/5 break-normal border-r border-gray-500 bg-white p-3 align-middle">
        {props.el.tenant_name}
      </div>
      <div className="table-cell w-1/4 break-normal border-r border-gray-500 bg-white p-3 align-middle">
        {props.el.dong_ho.map((ele, idx) => (
          <span key={String(idx)} className="mr-1">
            {ele.dong}동 {ele.ho}호
          </span>
        ))}
      </div>

      <div className="table-cell w-1/5 break-normal border-r border-gray-500 bg-[#eaf3ff] p-1.5 align-middle align-middle">
        <input
          value={price || Number(props.el.price || 0)}
          onChange={(e) => {
            if (isNaN(Number(e.target.value)) || e.target.value.length > 15) return;
            setPrice(Number(e.target.value));
            props.onChange(props.el, e.target.value);
          }}
          className="h-auto w-full border border-gray-300 bg-white p-1 text-right transition-all read-only:cursor-default focus-visible:border-blue-600 read-only:focus-visible:border-gray-300"
        />
      </div>
      <div className="table-cell w-[30%] break-normal bg-white align-middle" />
    </div>
  );
}

export const MemoizedItem = memo(RentalItem);
