import { useQuery } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { deleteToken, getToken, onMessage } from "firebase/messaging";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IHeader } from "..";
import { queryClient } from "../../..";
import { GlobalContext } from "../../../App";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { building_key, dashboard_group_key } from "../../../lib/api/queries/keys";
import { building } from "../../../lib/api/queries/urls";
import { auth, messaging } from "../../../lib/firebase";
import { IBuilding } from "../../../lib/types/Imodels";
import { notification_action, onActionInNotification } from "./func";
import LayoutHeaderUI from "./presenter";
import { toast } from "../../../lib/utils/toast";
import { useDeleteFcmToken } from "../../../requests/fcm/useDeleteFcmToken";

export default function LayoutHeader(props: IHeader) {
  const { ReactNativeWebView } = window;

  const navigate = useNavigate();
  const location = useLocation();
  const { user: data, setBuildInfo, setUser, AppToken } = useContext(GlobalContext);

  const { mutateAsync: deleteFCMToken } = useDeleteFcmToken();

  const onClickLogout = async () => {
    if (AppToken) {
      //토큰 있을때(앱)
      if (ReactNativeWebView) {
        //로그아웃 메세지 전달
        ReactNativeWebView.postMessage(JSON.stringify({ message: "signout" }));
      }
    } else {
      if (messaging !== null) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const token = await getToken(messaging, { vapidKey: `${process.env.REACT_APP_FIREBASE_VAPIDKEY}` });
          deleteFCMToken({ fcmToken: token });
        }
      }

      await signOut(auth);

      props.setIsMenu(false);
      messaging !== null && deleteToken(messaging);
      setUser && setUser(undefined);
      setBuildInfo && setBuildInfo(undefined);
      toast.warning({
        title: "알림",
        description: "로그아웃되었습니다. 로그인 페이지로 이동합니다.",
      });
      navigate("/");
    }
  };

  //FCM Web notification
  useEffect(() => {
    if (messaging === null) return;

    // message event
    onMessage(messaging, (payload) => {
      if (payload.notification !== undefined) {
        queryClient.invalidateQueries(["/building/notification/list"]);

        //데이터가져오기
        if (location.pathname === "/dashboard") queryClient.invalidateQueries([dashboard_group_key]);
        notification_action(payload.data?.notification_type);

        const notificationTitle = payload.notification.title || "";
        const notificationOptions = {
          body: payload.notification.body,
        };

        const notificationhData = JSON.parse(payload?.data?.web ?? "");

        const push_alert = new Notification(notificationTitle, notificationOptions);

        push_alert.addEventListener("click", () => {
          navigate(notificationhData.href);

          push_alert.close();
        });

        return push_alert;
      } else {
        // 아래 로직이 있는 이유를 모르겠음
        if (payload.data?.notification_type === "seat_reservation") {
          queryClient.invalidateQueries(["nfc_seats"]);
        }
      }
    });
  }, [messaging]);

  const { data: build } = useQuery(
    [building_key, "context_building"],
    () =>
      fetchClient.post<BaseResponse<IBuilding>>(building).then((res) => {
        setBuildInfo && setBuildInfo(res.data.data);
        return res.data.data;
      }),
    { enabled: data?.company_type === "building", staleTime: Infinity },
  );

  return (
    <LayoutHeaderUI
      data={data}
      onClickLogout={onClickLogout}
      build={build}
      AppToken={AppToken ? AppToken : null}
      {...props}
    />
  );
}
