import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../..";
import { http } from "../../lib/http";

type Request = {
  tenant_id: string;
  amount: number;
};

export const useUpdatePointMonthlyByCompany = () => {
  return useMutation({
    mutationFn: ({ tenant_id, amount }: Request) => {
      return http.post("/building/point/set/manual_recharge", {
        tenant_id,
        amount,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["point/by_tenant"]);
    },
  });
};
