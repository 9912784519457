import moment from "moment";
import { format_day } from "../../lib/utils/dataFormat";
import { useContext } from "react";
import { GlobalContext } from "../../App";
import { createSearchParams, useNavigate } from "react-router-dom";
import DashboardCard from "./_view/DashboardCard";
import { useBuildingInfoQuery } from "../../requests/building";
import WascoRequestModal from "../../views/banner/modal/WascoRequestModal";
import { useOverlay } from "@toss/use-overlay";
import { useGetDashboard } from "../../requests/dashboard/useGetDashboard";

export default function DashBoardPage() {
  const navigate = useNavigate();
  const { data: building } = useBuildingInfoQuery();
  const { buildInfo } = useContext(GlobalContext);

  const overlay = useOverlay();

  const { data } = useGetDashboard(!!buildInfo);

  return (
    <div>
      <p className="p-6 text-xl font-bold leading-6 text-gray-900">
        {moment.utc().format("YYYY년 MM월 DD일")} ({format_day(moment().day())})
      </p>

      <div className="flex flex-col gap-5 px-6 py-5">
        <div className="flex gap-5">
          {building?.data.services.MOVEIN_CARD && (
            <DashboardCard
              title={"입주 카드\n등록 대기"}
              number={data?.data.pending_resident_card}
              onClick={() => {
                navigate({
                  pathname: "/companies/cards",
                  search: `?${createSearchParams({ page: String(1), status: ["pending"] })}`,
                });
              }}
              disabled={!data?.data.pending_resident_card}
              hoverText={"입주 카드 확인 후 입주사 등록을 완료해주세요."}
            />
          )}

          {building?.data.services.MOVEIN_CARD && (
            <DashboardCard
              title={"입주 승인 대기"}
              number={data?.data.certification.owner}
              customDiv={
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-700">소유자</p>
                    <p className="text-sm font-semibold text-blue-600">
                      {String(data?.data.certification.owner).padStart(2, "0")}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-700">입주자(최고관리자)</p>
                    <p className="text-sm font-semibold text-blue-600">
                      {String(data?.data.certification.master).padStart(2, "0")}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-700">입주자</p>
                    <p className="text-sm font-semibold text-blue-600">
                      {String(data?.data.certification.member).padStart(2, "0")}
                    </p>
                  </div>
                </div>
              }
              onClick={() => {
                navigate({
                  pathname: "/members/requests",
                  search: `?${createSearchParams({ page: String(1) })}`,
                });
              }}
              disabled={!data?.data.pending_resident_card}
              hoverText={"신청정보를 확인하고 권한을 승인해주세요."}
            />
          )}
        </div>

        <div className="flex gap-5">
          {building?.data.services.COMPLAINT && (
            <DashboardCard
              title={"처리 중인 민원"}
              number={data?.data.all_complaint_to_do}
              onClick={() => navigate("/complaints")}
              disabled={!data?.data.all_complaint_to_do}
              hoverText={"관리자의 민원 처리를 기다리고 있어요."}
            />
          )}

          {building?.data.services.TEMPERATURE_FEEDBACK && (
            <DashboardCard
              title={"온도 조절 요청"}
              number={data?.data.new_temperature}
              onClick={() => navigate("/temperature")}
              disabled={!data?.data.new_temperature}
              hoverText={"실내 온도에 대한 피드백을 확인해주세요."}
            />
          )}
        </div>

        <div className="flex gap-5">
          {building?.data.services.REGULAR_PARKING && (
            <DashboardCard
              title={"정기주차\n신규 신청"}
              number={data?.data.car_request}
              onClick={() => {
                navigate({
                  pathname: "/parking",
                  search: `?${createSearchParams({ progress: ["approval_requested"] })}`,
                });
              }}
              disabled={!data?.data.car_request}
              hoverText={"정기 주차 신청서를 확인해주세요"}
            />
          )}

          {building?.data.services.MEETING_ROOM && (
            <div className="flex">
              <DashboardCard
                title={"회의실\n예약 신청"}
                number={data?.data.room_public_reservation?.filter((item) => item.room_public_type === "회의실").length}
                onClick={() =>
                  navigate({
                    pathname: "/meeting-rooms",
                    search: `?${createSearchParams({
                      page: String(1),
                      status: "pending",
                      start_date: moment().format("YYYY-MM-DD"),
                      end_date: moment().add(1, "month").format("YYYY-MM-DD"),
                    })}`,
                  })
                }
                disabled={
                  !data?.data.room_public_reservation?.filter((item) => item.room_public_type === "회의실").length
                }
                hoverText={"관리자의 승인을 기다리고 있어요."}
              />
            </div>
          )}
        </div>
      </div>

      <div className="fixed bottom-12 right-12 h-[280px] w-[400px] rounded-[20px] shadow-lg shadow-gray-300">
        <img src="/images/wasco-banner.png" alt="wasco_banner" className="absolute z-10 object-contain" />
        <button
          className="absolute bottom-[33px] left-7 z-20 flex h-[46px] w-[155px] items-center justify-center rounded-xl bg-white"
          onClick={() => {
            overlay.open(({ isOpen, close }) => (
              <WascoRequestModal
                isOpen={isOpen}
                onClose={close}
                name={building?.data.name || ""}
                userName={buildInfo?.building_master_id?.name || ""}
                email={buildInfo?.building_master_id?.email || ""}
              />
            ));
          }}
        >
          <span className="text-xl font-bold leading-6 text-blue-500">무료 컨설팅 받기</span>
        </button>
      </div>
    </div>
  );
}
