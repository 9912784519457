import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewNotificationUI from "./units/presenter";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { fetchClient } from "../../../lib/api/axios";
import { BaseMutation } from "../../../lib/api/queries/commons/types";
import { INotice, INoticeFile } from "../../../lib/types/Imodels";
import { useMutation } from "@tanstack/react-query";
import { ValidateNotice } from "./types/type";
import { useRoomType } from "../../../lib/utils";
import { notice_create, notice_detail_update } from "../../../lib/api/queries/urls";
import moment from "moment";
import { success, unAuthUser, warning } from "../../../lib/theme/toast";
import { useFetchCategory } from "../queries";
import { Wrapper } from "../../../components/styled";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { AxiosError } from "axios";
import { GlobalContext } from "../../../App";

const warnMessage = {
  ...warning,
  title: "공지 등록",
  description: "게시물을 등록하기 위해 유형, 대상, 제목, 내용을 입력해주세요.",
} as const;

const scheduleMessage = {
  ...warning,
  title: "일정 등록",
  description: "일정이 지정되지 않았습니다. 확인 후 다시 등록해주세요.",
} as const;

export default function NewNotification(props: BaseMutation<INotice>) {
  const toast = useToast();
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const auth = useUserAuth("notice");
  const room_type = useRoomType();
  const onlyOffice = room_type.length <= 1;

  const { data: category } = useFetchCategory();
  const { buildInfo } = useContext(GlobalContext);

  const [startDate, setStartDate] = useState(
    props.data?.reservation_date ? new Date(moment(props.data.reservation_date).format("YYYY-MM-DD HH:mm")) : null, //지금사용안함. 게시일자 등록 없음. 추가 시 날짜/시간 확인 필요
  );
  const [isschedule, setSchedule] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [start, end] = dateRange;
  const [notiType, setNotiType] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [radio, setRadio] = useState(
    props.data?.reservation_date !== undefined ? "2" : "1", //지금사용안함. 게시일자 등록 없음
  );
  const [fileArr, setFileArr] = useState<INoticeFile[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const methods = useForm({ mode: "onChange" });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClickReserve = (value: string) => {
    //게시 예약 추후 개발
    // setRadio(value);
    // if (value === "1") {
    //   setStartDate(null);
    // }
  };

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      room_type.forEach((el) => allCheckedArr.push(el.label));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      if (newArr !== undefined) setCheckedItems(newArr);
    }
    if (checkedItems.length === room_type.length || 0) {
      return (checked = true);
    }
  };

  useEffect(() => {
    if (props.isEdit && props.data) {
      setCheckedItems(props.data?.notice_to);
      setNotiType(props.data?.notice_type);
      setFileArr(props.data.attached_files);
    }
    if (props.isEdit && props.data?.schedule_id !== undefined) {
      setDateRange([
        new Date(props.data?.schedule_id?.start_date || ""),
        new Date(props.data?.schedule_id?.end_date || ""),
      ]);
    }

    const newArr = [...isschedule];
    if (!newArr.includes("is_important") && props.data?.is_important) newArr.push("is_important");
    if (!newArr.includes("is_scheduled") && props.data?.is_scheduled) newArr.push("is_scheduled");

    setSchedule(newArr);
  }, [props.data]);

  const onMoveSchedule = () => {
    onClose();
    navigate("/notice");
  };

  const mutation = useMutation((data: FormData) =>
    fetchClient
      .post(props.isEdit ? notice_detail_update : notice_create, data)
      .then(() => {
        toast({
          ...success,
          title: `공지 게시물 ${props.isEdit ? "수정" : "등록"} 완료`,
          description: `공지 게시물 ${props.isEdit ? "수정" : "등록"}이 완료되었습니다.`,
        });
        return navigate("/notice");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) toast({ ...unAuthUser });
        if (err.response?.status === 505)
          toast({
            ...warning,
            title: "알림",
            description: "일정을 등록하시려면 날짜를 먼저 선택해주세요.",
          });
      }),
  );

  const onSubmit = (data: FieldValues) => {
    if (mutation.isLoading) return;
    if (!onlyOffice && !checkedItems.length) return toast({ ...warnMessage });
    if (!data.body || !data.title || !notiType) return toast({ ...warnMessage });
    if (data.is_scheduled && dateRange.some((el) => el === null)) return toast({ ...scheduleMessage });

    const formData = new FormData();
    formData.append("notice_type", notiType);
    onlyOffice
      ? formData.append("notice_to[]", "office")
      : checkedItems.map((el) => formData.append("notice_to[]", el));
    formData.append("is_important", data.is_important || false);
    formData.append("is_scheduled", data.is_scheduled || false);
    if (data.is_scheduled) {
      start && formData.append("start_date", String(moment(start).format("YYYY-MM-DD")));
      end && formData.append("end_date", String(moment(end).format("YYYY-MM-DD")));
    }
    formData.append("title", data.title);
    formData.append("body", data.body ? data.body : "");
    files.length === 0 && formData.append("file_original_names", "[]");
    if (files.length !== 0) {
      formData.append("file_original_names", JSON.stringify(files.map((el) => el.name)));
      Array.from(files).map((el: File) => formData.append("attached_files", el));
    }

    mutation.mutate(formData);
  };

  const onUpdate = (data: FieldValues) => {
    if (mutation.isLoading) return;
    // console.log(dateRange, data.is_scheduled);
    const updateData: ValidateNotice = data;
    if (data.title === undefined) updateData.title = props.data?.title;
    if (data.is_important === undefined) updateData.is_important = props.data?.is_important;
    if (data.is_scheduled === undefined) updateData.is_scheduled = props.data?.is_scheduled;
    if (data.body === undefined) updateData.body = props.data?.body;
    if (notiType === "") updateData.notice_type = props.data?.notice_type;

    if (data.is_scheduled && dateRange.some((el) => el === null)) return toast({ ...scheduleMessage });

    const formData = new FormData();
    formData.append("notice_id", String(noticeId));
    formData.append("notice_type", String(updateData.notice_type || notiType));
    onlyOffice ? formData.append("notice_to", "office") : checkedItems.map((el) => formData.append("notice_to", el));
    formData.append("is_important", String(updateData.is_important));
    formData.append("is_scheduled", String(updateData.is_scheduled));
    if (updateData.is_scheduled) {
      start && formData.append("start_date", moment(start).format("YYYY-MM-DD"));
      end && formData.append("end_date", moment(end).format("YYYY-MM-DD"));
    }
    formData.append("title", String(updateData.title));
    formData.append("body", data.body || "");
    formData.append("original_attached_files", JSON.stringify(fileArr));
    files.length === 0
      ? formData.append("attached_files", "[]")
      : Array.from(files).map((el) => formData.append("attached_files", el));
    formData.append("file_original_names", JSON.stringify(files.map((el) => el.name)));

    mutation.mutate(formData);
  };

  if (auth === undefined) return <FullPageSpinner />;
  if (auth !== "edit") return <GetAuthorized />;
  return (
    <Wrapper>
      <FormProvider {...methods}>
        <NewNotificationUI
          isschedule={isschedule}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setSchedule={setSchedule}
          checkedItems={checkedItems}
          onCheckedAll={onCheckedAll}
          onChangeChecked={onChangeChecked}
          notiType={notiType}
          setNotiType={setNotiType}
          data={props.data}
          files={files}
          setFiles={setFiles}
          fileArr={fileArr}
          setFileArr={setFileArr}
          category={category}
          onSubmit={onSubmit}
          onUpdate={onUpdate}
          isEdit={props.isEdit}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          onMoveSchedule={onMoveSchedule}
          radio={radio}
          onClickReserve={onClickReserve}
          startDate={startDate}
          setStartDate={setStartDate}
          buildInfo={buildInfo}
        />
      </FormProvider>
    </Wrapper>
  );
}
