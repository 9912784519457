import { useState, useContext, ChangeEvent, useEffect } from "react";
import SubframePart from "../../../components/Main/subframePart";
import { MainActionBox, Required, ShadowBox } from "../../../components/styled/webApp";
import GrayDropdown from "../../../components/select/gray";
import GrayRadiusInput from "../../../components/Input/responsive";
import styled from "@emotion/styled";
import { useRoomType } from "../../../lib/utils";
import { GlobalContext } from "../../../App";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { toast as oldToast } from "../../../lib/utils/toast";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import Button from "../../../components/button/Button";
import { useOverlay } from "@toss/use-overlay";
import RoomAddOwnerModal from "../../../views/room/modal/RoomAddOwnerModal";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { useCreateRoom } from "../../../requests/room/useCreateRoom";
import { useInviteRoomOwner } from "../../../requests/room/useInviteRoomOwner";

const SubmitButton = styled.button<{ validation: boolean }>`
  background-color: ${(props) => (props.validation ? "#2563EB" : "#9CB7F5")};
  color: #ffffff;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const AddOwnerContainer = styled.div`
  padding-top: 8px;
`;

const Line = styled.div`
  border-top: 1px solid #f4f4f5;
  margin-bottom: 8px;
`;

const SubTitle = styled.p`
  color: #111827;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
`;

const OwnerContents = styled.div<{ lastChild: boolean }>`
  width: 100%;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #fff;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.lastChild ? "0px" : "12px")};
`;

const OwnerName = styled.div`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const OwnerPhoneNumber = styled.div`
  color: #9ca3af;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

interface owners {
  name: string;
  phone_number: string;
  isInvite: boolean;
}

// 호실 생성
export default function RoomCreate() {
  const room_type_arr = useRoomType();
  const { buildInfo: data } = useContext(GlobalContext);
  const navigate = useNavigate();

  // 소유자가 필요없는 데 있기 때문에 삭제한 배열
  // useRoomType은 사용하는 곳이 많아 함부로 삭제하지 않음
  const roomTypeArr = room_type_arr.filter((v) => v.label !== "owner");

  const [types, setTypes] = useState<string>("");
  const [dong, setDong] = useState<string>("");
  const [floor, setFloor] = useState<string>("");
  const [ho, setHo] = useState<string>("");
  const [area, setArea] = useState<string>("");
  const [submitValidation, setSubmitValidation] = useState<boolean>(false);
  const [owners, setOwners] = useState<owners[]>([]);

  const overlay = useOverlay();

  const { mutateAsync: createRoom } = useCreateRoom();
  const { mutateAsync: inviteRoomOwner } = useInviteRoomOwner();

  // 유효성 검사
  const submitValidationCheck = () => {
    if (types.length < 1) return false;
    if (dong.length < 1) return false;
    if (floor.length < 1) return false;
    if (ho.length < 1) return false;
    if (area.length < 1) return false;

    return true;
  };

  useEffect(() => {
    setSubmitValidation(submitValidationCheck());
  }, [types, dong, floor, ho, area]);

  // 호실 추가 api
  const createRoomClick = () => {
    const room_type = room_type_arr.length <= 1 ? "office" : types;

    toast.promise(
      createRoom({
        room_type,
        dong,
        ho,
        floor,
        area: Number.parseFloat(area),
        owners: owners,
      }),
      {
        loading: "Loading...",
        success: (res) => {
          owners
            .filter((owner) => owner.isInvite)
            .map((owner) => {
              const inviteMember = res.data.owners.find((v) => v.phone_number === owner.phone_number);

              toast.promise(
                inviteRoomOwner({
                  roomId: res.data._id,
                  ownerId: inviteMember?._id || "",
                }),
                {
                  loading: "Loading...",
                  success: (
                    <CustomToast
                      iconType="check"
                      title={`'${inviteMember?.name}'님께 가입 초대 알림톡이 전송되었습니다.`}
                    />
                  ),
                  error: (
                    <CustomToast
                      iconType="error"
                      title="가입 초대 알림톡 전송 실패"
                      description="잠시 후 다시 시도해주세요."
                    />
                  ),
                },
              );
            });

          navigate(-1);

          return (
            <CustomToast
              iconType="check"
              title="신규 호실 등록 완료"
              description={`${res.data.dong}동 ${res.data.ho}호 호실 등록이 완료되었습니다.`}
            />
          );
        },
        error: (err: AxiosError) => {
          if (err.response?.status === 401) {
            oldToast.unAuthUser();
            return (
              <CustomToast
                iconType="error"
                title="관리자 권한 오류"
                description="해당 작업에 대한 권한이 없습니다. 관리자 권한 변경이 필요하다면 최고관리자에게 문의해주세요."
              />
            );
          }
          return <CustomToast iconType="error" title="호실 등록 오류" description="기존에 존재하는 호실 정보입니다." />;
        },
      },
    );
  };

  const ownerCancelClick = (index: number) => {
    const tempOwners = [...owners];
    tempOwners.splice(index, 1);
    setOwners(tempOwners);
  };

  const dongChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDong(e.target.value);
    setFloor("");
  };

  const floorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFloor(e.target.value);
  };

  const hoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHo(e.target.value);
  };

  // 호실용도 수정 이벤트
  const typeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTypes(e.target.value);
  };

  // 면적 수정 이벤트
  const areaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // 숫자 + . 만 가능한 유효성 검사 로직 추가하기
    setArea(e.target.value);
  };

  return (
    <SubframePart name={"신규 호실 추가"}>
      <MainActionBox>
        <Required>* 필수입력</Required>
        <ShadowBox>
          <div>
            <h4>
              호실의 정보를 입력해주세요. (동/층/호) <span>*</span>
            </h4>
            <div style={{ display: "flex", gap: "8px", marginBottom: "12px" }}>
              <GrayDropdown w="100%" value={dong} _onChange={dongChange}>
                <option value="" disabled>
                  동
                </option>
                {data?.dong_floor.map((el) => (
                  <option key={el._id} value={el.dong}>
                    {el.dong}
                  </option>
                ))}
              </GrayDropdown>
              <GrayDropdown w="100%" value={floor} _onChange={floorChange}>
                <option value="" disabled>
                  층
                </option>
                {data?.dong_floor
                  .filter((el) => el.dong === dong)?.[0]
                  ?.floor.map((el) => (
                    <option key={el} value={el}>
                      {el}층
                    </option>
                  ))}
              </GrayDropdown>
              <GrayRadiusInput
                _style={{ width: "8rem" }}
                // disabled={true}
                value={ho}
                placeholder="호"
                _onChange={hoChange}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <h4>
                호실용도를 선택해주세요. <span>*</span>
              </h4>
              <GrayDropdown
                w="100%"
                value={types}
                // disabled={data.auth === "마스터"}
                _onChange={typeChange}
              >
                <option value="" disabled>
                  호실용도
                </option>
                {roomTypeArr.map((el) => (
                  <option value={el.label} key={el.id}>
                    {el.value}
                  </option>
                ))}
              </GrayDropdown>
            </div>
            <h4>
              전용 면적을 입력해주세요. (단위 ㎡) <span>*</span>
            </h4>
            <GrayRadiusInput
              _style={{ width: "100%", marginBottom: "4px" }}
              // disabled={true}
              value={area}
              _onChange={areaChange}
            />
          </div>
        </ShadowBox>
        {data?.building_type === "knowledge_industry_center" && (
          <ShadowBox>
            <SubTitle>소유자</SubTitle>
            <div style={{ marginBottom: "16px" }}>
              <p className="text-sm text-gray-500">∙오피스너 가입 초대를 위해서 소유자 정보를 함께 입력해주세요.</p>
              <p className="text-sm text-gray-500">
                ∙<span className="text-red-600">등록된 소유자 휴대폰 번호로 가입 초대 알림톡이 전송됩니다.</span>
              </p>
            </div>
            {owners && owners.length > 0 && (
              <div style={{ marginBottom: "16px" }}>
                {owners.map((item, index) => {
                  return (
                    <OwnerContents key={index} lastChild={index === owners.length - 1}>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <OwnerName>{item.name}</OwnerName>
                        <OwnerPhoneNumber>{item.phone_number}</OwnerPhoneNumber>
                      </div>
                      <div>
                        <img
                          src={"/cancel_icon.svg"}
                          alt={"cancel"}
                          onClick={() => ownerCancelClick(index)}
                          style={{ height: "22px", cursor: "pointer" }}
                        />
                      </div>
                    </OwnerContents>
                  );
                })}
              </div>
            )}
            <AddOwnerContainer>
              <Line />
              <Button
                designSchema="ghostBlue"
                size="md"
                iconElement={<FiPlus className="h-5 w-5" />}
                buttonText="소유자 등록"
                onClick={() => {
                  overlay.open(({ isOpen, close }) => (
                    <RoomAddOwnerModal isOpen={isOpen} onClose={close} owners={owners} setOwners={setOwners} />
                  ));
                }}
                className="float-right font-semibold"
              />
            </AddOwnerContainer>
          </ShadowBox>
        )}
        <SubmitButton onClick={createRoomClick} validation={submitValidation} disabled={!submitValidation}>
          호실 추가하기
        </SubmitButton>
      </MainActionBox>
    </SubframePart>
  );
}
