import { useQuery } from "@tanstack/react-query";
import { http } from "../../lib/http";

type Response = {
  data: {
    _id: string;
    title: string;
    logo: string;
    sub_category?: {
      title: string;
      logo: string;
      main_mapping: string;
      sub_mapping: string;
    }[];
  }[];
};

export const useGetComplaintCategoryList = () => {
  return useQuery({
    queryKey: ["category/list", "complaint_type"],
    queryFn: () =>
      http.get<Response>("/building/category/list", {
        category: "complaint_type",
      }),
    staleTime: Infinity,
  });
};
