import { Popover, PopoverPanel } from "@headlessui/react";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  position?: "top" | "right" | "bottom" | "left";
  message: string;
  children: React.ReactNode;
  disabled?: boolean;
};

export default function CustomTooltip({ message, position = "bottom", disabled, children }: Props) {
  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const [isOpen, setIsOpen] = useState(false);

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isScrollingRef = useRef(false);

  if (disabled) {
    return <>{children}</>;
  }

  useEffect(() => {
    const handleScroll = () => {
      isScrollingRef.current = true;
      setIsOpen(false); // 스크롤 시 툴팁을 숨김
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showTooltip = () => {
    if (isScrollingRef.current) return;

    const rect = containerRef.current?.getBoundingClientRect();
    const tooltipWidth = (tooltipRef.current as HTMLElement)?.offsetWidth;

    const positionStyles = {
      top: { top: 8, left: 0 },
      right: { top: -36, left: tooltipWidth + 16 },
      bottom: { top: 16, left: 2 },
      left: { top: 2, left: 16 },
    }[position];

    rect &&
      setCoords({
        top: rect.bottom + positionStyles.top,
        left: rect.left + (rect.width / 2 - tooltipWidth / 2) + positionStyles.left,
      });

    setIsOpen(true);
  };

  const hideTooltip = () => {
    setIsOpen(false);
    isScrollingRef.current = false; // 스크롤이 멈췄을 때 다시 툴팁을 활성화 가능하게 함
  };

  return (
    <div ref={containerRef} onMouseOver={showTooltip} onMouseLeave={hideTooltip} className="group flex flex-1">
      {children}

      <div
        ref={tooltipRef}
        className={twMerge(
          "invisible fixed z-30 transform items-center justify-center whitespace-nowrap rounded-lg bg-[#111827] px-2.5 py-2 text-sm font-semibold text-white",
          // positionClasses[position],
          isOpen && "visible",
        )}
        style={{ top: coords.top ?? 0, left: coords.left ?? 0 }}
      >
        {message}
      </div>
    </div>
  );
}
